import { render, staticRenderFns } from "./Snackbar.vue?vue&type=template&id=1bfce185&scoped=true&"
import script from "./Snackbar.vue?vue&type=script&lang=js&"
export * from "./Snackbar.vue?vue&type=script&lang=js&"
import style0 from "./Snackbar.css?vue&type=style&index=0&id=1bfce185&prod&module=true&scoped=true&lang=css&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "1bfce185",
  null
  
)

export default component.exports