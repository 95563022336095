<template>
    <div
        :class="{
            [$style.tag]: true,
            [$style.grey]: color === 'grey',
            [$style.green]: color === 'green',
            [$style.yellow]: color === 'yellow',
            [$style.red]: color === 'red',
            [$style.blue]: color === 'blue',
            [$style.purple]: color === 'purple',
            [$style.disabled]: disabled || color === 'disabled',
        }"
    >
        <div :class="$style.body">
            <div v-if="$slots.icon" :class="$style.icon">
                <slot name="icon"></slot>
            </div>
            <slot name="text"
                ><span>{{ text }}</span></slot
            >
        </div>
    </div>
</template>

<script>
/* If making changes to this component be aware it was copied from Marmot. 
It wasn't imported because Marmot doesn't have a Vue2 version of this component.
Make sure to check the Vue3 version for changes before changing this component too. */

export default {
    name: 'Tag',
    props: {
        text: {
            type: String,
            default: '',
        },
        color: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
}
</script>

<style module src="./Tag.css" />
