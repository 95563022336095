<template>
    <div :class="$style.quoteWrapper">
        <img :src="quoteImage" :class="$style.quoteImage" :alt="quote.title" />
        <div :class="$style.quoteBlock">
            <div :class="$style.quoteTitle">{{ quote.title }}</div>
            <div :class="$style.quoteText">{{ quote.text }}</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Quote',
    props: {
        quote: {
            type: Object,
        },
    },
    computed: {
        quoteImage() {
            // eslint-disable-next-line
            return require(`@/assets/${this.quote.image}`)
        },
    },
}
</script>

<style module src="./Quote.css" />
