import Vue from 'vue'

const pending = (state, data) => {
    Vue.set(state.pending, data.type, data.status)
}

const error = (state, data) => {
    Vue.set(state.error, data.type, data.status)
}

const resetErrorsPending = (state, action) => {
    Vue.delete(state.error, action)
    Vue.delete(state.pending, action)
}

const loadFeatures = (state, data) => {
    Vue.set(state, 'features', data.slice(1, data.length - 1).split(','))
}

const addFeatures = (state, features) => {
    Vue.set(state, 'features', (state.features || []).concat(features))
}

const storeSearchResults = (state, data) => {
    Vue.set(state, 'searchResults', data)
}

const storeNPS = (state, data) => {
    Vue.set(state, 'feedbackId', data)
}

const storeGoogleAuthURL = (state, data) => {
    Vue.set(state, 'googleAuthURL', data.data)
}

const storeLogoUrl = (state, data) => {
    Vue.set(state, 'orgLogoURL', data)
}

const storeRedirect = (state, data) => {
    Vue.set(state, 'redirectUrl', data)
}

const toggleModalVisibility = (state, data) => {
    const visibility = state.modalVisibility[data.modalName] || false
    Vue.set(state.modalVisibility, data.modalName, !visibility)
}

export default {
    pending,
    error,
    resetErrorsPending,
    loadFeatures,
    addFeatures,
    storeSearchResults,
    storeNPS,
    storeGoogleAuthURL,
    storeLogoUrl,
    storeRedirect,
    toggleModalVisibility,
}
