import axios from '../axios'
import store from '../store'

const isUserLoggedIn = () => {
    if (typeof store.state.isUserLoggedIn === 'undefined') {
        return axios
            .get('/api/logged-in')
            .then((response) => {
                store.commit('userLoggedIn', true)

                if (response.data.orgDeactivated) {
                    // deals with redirect on the component
                    return Promise.resolve({
                        orgDeactivated: true,
                    })
                }

                return Promise.resolve({ loggedIn: true })
            })
            .catch(() => {
                store.commit('userLoggedIn', false)
                // eslint-disable-next-line prefer-promise-reject-errors
                return Promise.reject({ loggedIn: false })
            })
    }

    return Promise.resolve(store.state.isUserLoggedIn)
}

const isUserLoggedInBool = () => store.state.isUserLoggedIn

export default {
    isUserLoggedIn,
    isUserLoggedInBool,
}
