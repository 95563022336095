import axios from '../../axios'

const fetchFolders = async (context) => {
    const type = 'fetchFolders'
    context.commit('pending', { type, status: true })
    context.commit('error', { type, status: false })

    try {
        const response = await axios.get('/api/question-library/list')
        context.commit('storeFolders', response.data)
    } catch (err) {
        context.commit('error', { type, status: true })
    } finally {
        context.commit('pending', { type, status: false })
    }
}

const createFolder = async (context, folderName) => {
    const type = 'createFolder'
    context.commit('pending', { type, status: true })
    context.commit('error', { type, status: false })

    try {
        await axios.post('/api/question-library/new', { name: folderName })
        context.dispatch('fetchFolders')
    } catch (err) {
        context.commit('error', { type, status: true })
    } finally {
        context.commit('pending', { type, status: false })
    }
}

const removeFolder = async (context, folderId) => {
    const type = 'removeFolder'
    context.commit('pending', { type, status: true })
    context.commit('error', { type, status: false })

    try {
        await axios.delete(`/api/question-library/${folderId}`)
        context.dispatch('fetchFolders')
    } catch (err) {
        context.commit('error', { type, status: true })
    } finally {
        context.commit('pending', { type, status: false })
    }
}

const addQuestionsToFolder = async (context, { folderId, questionIds }) => {
    const type = 'addQuestionToFolder'
    context.commit('pending', { type, status: true })
    context.commit('error', { type, status: false })

    try {
        await axios.post(`/api/question-library/${folderId}/questions/add`, {
            questionIds,
        })
        context.dispatch('fetchFolders')
    } catch (err) {
        context.commit('error', { type, status: true })
    } finally {
        context.commit('pending', { type, status: false })
    }
}

const removeQuestionsFromFolder = async (context, { folderId, questionIds }) => {
    const type = 'addQuestionToFolder'
    context.commit('pending', { type, status: true })
    context.commit('error', { type, status: false })

    try {
        await axios.post(`/api/question-library/${folderId}/questions/remove`, {
            questionIds,
        })
        context.dispatch('fetchFolders')
    } catch (err) {
        context.commit('error', { type, status: true })
    } finally {
        context.commit('pending', { type, status: false })
    }
}

export default {
    fetchFolders,
    createFolder,
    removeFolder,
    addQuestionsToFolder,
    removeQuestionsFromFolder,
}
