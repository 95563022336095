<template>
    <div :class="$style.wrapper">
        <div
            :class="$style.createRoleInner"
            v-if="!roleTitleInputVisible && !jobviteSectionVisible"
        >
            <div :class="$style.sectionTitle">
                <FontAwesomeIcon :icon="faPlusCircle" :class="$style.faIcon" />Create a new job
            </div>
            <div :class="$style.buttonRow">
                <Button @clicked="toggleVisibility" @keyup.enter="toggleVisibility" size="form">
                    <span slot="buttonText">Create job from scratch</span>
                    <span slot="icon">
                        <FontAwesomeIcon :icon="arrowRightIcon" />
                    </span>
                </Button>
                <Button
                    @clicked="toggleJobviteVisibility"
                    @keyup.enter="toggleJobviteVisibility"
                    color="secondaryGrey"
                    size="form"
                >
                    <span slot="buttonText">Import job from jobvite</span>
                    <span slot="icon">
                        <FontAwesomeIcon :icon="arrowRightIcon" />
                    </span>
                </Button>
            </div>
        </div>
        <div :class="$style.roleTitleInner" v-if="roleTitleInputVisible">
            <div :class="$style.inputRow">
                <label :class="$style.inputTitle" for="roleTitle">
                    Job Title
                    <div :class="$style.inputFieldWrapper">
                        <input
                            aria-labelledby="#roleTitle"
                            id="roleTitle"
                            name="roleTitle"
                            type="text"
                            maxlength="256"
                            placeholder="eg. Chief cake baker"
                            v-model="roleTitle"
                            :class="$style.inputField"
                        />
                    </div>
                </label>
                <div :class="$style.inputComment">
                    Try to keep it short and avoid internal jargon
                </div>
            </div>
            <div>
                <Button @clicked="buildRole">
                    <span slot="buttonText">Create role</span>
                    <span slot="icon">
                        <FontAwesomeIcon :icon="arrowRightIcon" />
                    </span>
                </Button>
            </div>
        </div>
        <div :class="$style.roleTitleInner" v-if="jobviteSectionVisible">
            <div :class="$style.inputRow">
                <label :class="$style.inputTitle" for="jobviteId">
                    Jobvite Req ID
                    <div :class="$style.inputFieldWrapper">
                        <input
                            aria-labelledby="#jobviteId"
                            id="jobviteId"
                            name="jobviteId"
                            type="text"
                            maxlength="256"
                            placeholder="eg. 522"
                            v-model="jobviteReqId"
                            :class="$style.inputField"
                        />
                    </div>
                </label>
                <div :class="$style.inputComment">Import a jobvite role from Requisition Id</div>
            </div>
            <div>
                <Button @clicked="importJobviteRole">
                    <span slot="buttonText">Create job</span>
                    <span slot="icon">
                        <FontAwesomeIcon :icon="arrowRightIcon" />
                    </span>
                </Button>
            </div>
        </div>
    </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faPlusCircle, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { Button } from '@applied/marmot'
import mapIcons from '@/utils/mapIcons'

export default {
    name: 'JobviteCreateRoleButton',
    data() {
        return {
            roleTitleInputVisible: false,
            jobviteSectionVisible: false,
            roleTitle: '',
            jobviteReqId: null,
        }
    },
    components: {
        FontAwesomeIcon,
        Button,
    },
    methods: {
        toggleJobviteVisibility() {
            this.jobviteSectionVisible = !this.jobviteSectionVisible
        },
        importJobviteRole() {
            this.$store
                .dispatch('importRoleFromJobvite', {
                    id: this.jobviteReqId,
                })
                .then((data) => {
                    window.location.replace(data.newRole)
                })
        },
        toggleVisibility() {
            this.roleTitleInputVisible = !this.roleTitleInputVisible
        },
        buildRole() {
            this.$store.dispatch('createRole', { title: this.roleTitle }).then((data) => {
                window.location.href = `/builder/${data}/new/high-volume`
            })
        },
    },
    computed: {
        ...mapIcons({ faPlusCircle }),
        arrowRightIcon() {
            return faArrowRight
        },
    },
}
</script>

<style module src="./JobviteCreateRoleButton.css" />
