import axios from '@/axios'

const fetchUser = (context) => {
    context.commit('pending', { type: 'fetchUser', status: true })
    return new Promise((resolve, reject) => {
        axios.get('/api/user/current', {}).then(
            (response) => {
                context.commit('pending', { type: 'fetchUser', status: false })
                context.commit('storeUser', response.data)
                resolve(response)
            },
            (error) => {
                context.commit('pending', { type: 'fetchUser', status: false })
                reject(error)
            },
        )
    })
}

const updateUser = (context, data) => {
    context.commit('error', { type: 'updateUser', status: false })
    context.commit('pending', { type: 'updateUser', status: true })
    const url = `/api/user/${data.id}`
    return axios
        .post(url, data)
        .then((response) => {
            context.commit('storeUser', response.data)
            context.commit('pending', { type: 'updateUser', status: false })
        })
        .catch(() => {
            context.commit('pending', { type: 'updateUser', status: false })
            context.commit('error', { type: 'updateUser', status: true })
        })
}

const passwordUpdate = (context, resetData) => {
    context.commit('error', { type: 'passwordUpdate', status: false })
    context.commit('pending', { type: 'passwordUpdate', status: true })
    return axios
        .post('/auth/password-update', resetData)
        .then(() => {
            context.commit('pending', { type: 'passwordUpdate', status: false })
        })
        .catch(() => {
            context.commit('pending', { type: 'passwordUpdate', status: false })
            context.commit('error', { type: 'passwordUpdate', status: true })
        })
}

const updateUserImage = (context, data) => {
    context.commit('error', { type: 'updateUserImage', status: false })
    context.commit('pending', { type: 'updateUserImage', status: true })
    const url = `api/rest/user/${data.id}`
    return axios
        .put(url, data)
        .then(() => {
            context.commit('pending', {
                type: 'updateUserImage',
                status: false,
            })
            context.dispatch('fetchUser')
        })
        .catch(() => {
            context.commit('pending', {
                type: 'updateUserImage',
                status: false,
            })
            context.commit('error', { type: 'updateUserImage', status: true })
        })
}

const sendPasswordResetEmail = (context, data) => {
    context.commit('error', { type: 'sendPasswordResetEmail', status: false })
    context.commit('pending', { type: 'sendPasswordResetEmail', status: true })
    const url = `/auth/reset-link-new`
    return axios
        .post(url, data)
        .then(() => {
            context.commit('pending', {
                type: 'sendPasswordResetEmail',
                status: false,
            })
        })
        .catch(() => {
            context.commit('pending', {
                type: 'sendPasswordResetEmail',
                status: false,
            })
            context.commit('error', {
                type: 'sendPasswordResetEmail',
                status: true,
            })
        })
}

const sendWelcomeEmail = (context, data) => {
    context.commit('error', { type: 'sendWelcomeEmail', status: false })
    context.commit('pending', { type: 'sendWelcomeEmail', status: true })
    const url = '/account/welcome-email'
    return axios
        .post(url, data)
        .then(() => {
            context.commit('pending', {
                type: 'sendWelcomeEmail',
                status: false,
            })
        })
        .catch(() => {
            context.commit('pending', {
                type: 'sendWelcomeEmail',
                status: false,
            })
            context.commit('error', {
                type: 'sendWelcomeEmail',
                status: true,
            })
        })
}

const login = (context, data) => {
    context.commit('error', { type: 'login', status: false })
    context.commit('pending', { type: 'login', status: true })

    const url = `/login-api`
    return axios
        .post(url, data)
        .then((response) => {
            context.commit('pending', {
                type: 'login',
                status: false,
            })
            if (
                response.data &&
                (response.data.message === 'totp' || response.data.message === 'needs totp')
            ) {
                return Promise.resolve({
                    message: response.data.message,
                    svg: response.data.svg,
                    uri: response.data.uri,
                    userSecret: response.data.userSecret,
                })
            }

            context.commit('userLoggedIn', true)
            context.commit('orgHasExpired', response.orgDeactivated)
            if (response.data && response.data.status === 'orgExpired') {
                return window.location.replace('/expired')
            }

            return Promise.resolve(response.data.redirect)
        })
        .catch((err) => {
            context.commit('pending', {
                type: 'login',
                status: false,
            })
            context.commit('error', { type: 'login', status: true })
            return Promise.reject(err)
        })
}

const getPasswordResetData = (context, data) => {
    context.commit('error', { type: 'getPasswordResetData', status: false })
    context.commit('pending', { type: 'getPasswordResetData', status: true })
    const url = `/auth/reset-password-data?token=${data.token}`

    return axios
        .get(url, {})
        .then((tokenData) => {
            context.commit('pending', {
                type: 'getPasswordResetData',
                status: false,
            })
            return Promise.resolve(tokenData)
        })
        .catch(() => {
            context.commit('pending', {
                type: 'getPasswordResetData',
                status: false,
            })
            context.commit('error', {
                type: 'getPasswordResetData',
                status: true,
            })
            return Promise.reject()
        })
}

const resetPassword = (context, data) => {
    context.commit('error', { type: 'resetPassword', status: false })
    context.commit('pending', { type: 'resetPassword', status: true })
    return axios
        .post('/auth/reset', data)
        .then(() => {
            context.commit('pending', {
                type: 'resetPassword',
                status: false,
            })
            context.commit('userLoggedIn', true)
            return Promise.resolve()
        })
        .catch(() => {
            context.commit('pending', {
                type: 'resetPassword',
                status: false,
            })
            context.commit('error', { type: 'resetPassword', status: true })
            return Promise.reject()
        })
}

const getUserDashboardDetails = (context) => {
    context.commit('error', { type: 'getUserDashboardDetails', status: false })
    context.commit('pending', { type: 'getUserDashboardDetails', status: true })
    return axios
        .get('/user-dashboard/task-data')
        .then((response) => {
            context.commit('storeUserDashboardDetails', response.data)
        })
        .catch(() => {
            context.commit('error', {
                type: 'getUserDashboardDetails',
                status: true,
            })
        })
        .finally(() => {
            context.commit('pending', {
                type: 'getUserDashboardDetails',
                status: false,
            })
        })
}

const setupTOTP = (context, data) => {
    context.commit('error', { type: 'setupTOTP', status: false })
    context.commit('pending', { type: 'setupTOTP', status: true })
    return axios
        .post('/setup-totp', {
            totpToken: data.totpToken,
            emailAddress: data.emailAddress,
            password: data.password,
            userSecret: data.userSecret,
        })
        .then(() => {
            context.commit('pending', {
                type: 'setupTOTP',
                status: false,
            })
            context.dispatch('fetchBasicData')
            context.commit('userLoggedIn', true)
        })
        .then(() => Promise.resolve())
        .catch(() => {
            context.commit('error', {
                type: 'setupTOTP',
                status: true,
            })
            context.commit('pending', {
                type: 'setupTOTP',
                status: false,
            })
            return Promise.reject()
        })
}

export default {
    fetchUser,
    updateUser,
    passwordUpdate,
    updateUserImage,
    sendPasswordResetEmail,
    login,
    getPasswordResetData,
    resetPassword,
    sendWelcomeEmail,
    getUserDashboardDetails,
    setupTOTP,
}
