import { toFont } from 'chart.js/helpers'

export const createTooltip = (tooltipId) => {
    let tooltipEl = document.getElementById(tooltipId)

    if (!tooltipEl) {
        tooltipEl = document.createElement('div')
        tooltipEl.id = tooltipId
        tooltipEl.className = 'chartTooltipContainer'
        document.body.appendChild(tooltipEl)
    }
}

const caretSize = 20
const tooltipYOffset = 35
const minTooltipYClampOffset = 5

export const generateRightPositioner = (tooltipHeight) => (elements) => {
    if (!elements?.length) {
        return {}
    }

    const x = elements[elements.length - 1].element.x + caretSize / 2
    const y = elements[elements.length - 1].element.y + tooltipYOffset - tooltipHeight / 2
    const clampedY = Math.min(
        y,
        elements[0].element.base - tooltipHeight / 2 - minTooltipYClampOffset,
    )

    return {
        x,
        y: clampedY,
    }
}

const defaultTooltipUpdateOptions = {
    percentages: false,
}

export const updateTooltip = (tooltipContext, tooltipId, options) => {
    const tooltipModel = tooltipContext.tooltip
    const { percentages, displayAllLabels } = { ...defaultTooltipUpdateOptions, ...options }
    const tooltipEl = document.getElementById(tooltipId)

    if (tooltipModel.opacity === 0 && tooltipEl) {
        tooltipEl.style.opacity = 0
        return
    }

    // Set caret Position
    tooltipEl.classList.remove('above', 'below', 'no-transform')
    if (tooltipModel.yAlign) {
        tooltipEl.classList.add(tooltipModel.yAlign)
    } else {
        tooltipEl.classList.add('no-transform')
    }

    let innerHtml = ''

    // Loop through the context's datasets when displaying multiple items for a stacked chart
    if (displayAllLabels) {
        const { datasets } = tooltipContext.chart.data

        datasets.forEach((dataset) => {
            const value = Math.round(Number(dataset.data[tooltipModel.dataPoints[0].dataIndex]))
            const label = dataset.label || ''
            const line = `${label}: ${value}${percentages ? '%' : ''}`.replace(':', ',')
            const { backgroundColor } = dataset
            const style = `background:${backgroundColor};`
            const span = `<span class="chartTooltipColorBlock" style="${style}"></span>`
            innerHtml += `<div class="chartTooltipLine">${span}${line}</div>`
        })
    } else if (tooltipModel.body) {
        // Use the tooltipModel's data when displaying a single item
        const datapoint = tooltipModel.body[0].lines[0]

        const splitIndex = datapoint.lastIndexOf(' ')
        const label = datapoint.substring(0, splitIndex + 1)
        const value = Math.round(Number(datapoint.substring(splitIndex)))
        const colors = tooltipModel.labelColors[0]
        const style = `background:${colors.backgroundColor}; border-color:${colors.borderColor};`
        const span = `<span class="chartTooltipColorBlock" style="${style}"></span>`
        const line = `${label + value}${percentages ? '%' : ''}`.replace(':', ',')
        innerHtml += `<div class="chartTooltipLine">${span}${line}</div>`
    }

    tooltipEl.innerHTML = innerHtml

    const position = tooltipContext.chart.canvas.getBoundingClientRect()
    const bodyFont = toFont(tooltipModel.options.bodyFont)

    tooltipEl.style.opacity = 1
    tooltipEl.style.position = 'absolute'
    tooltipEl.style.left = `${position.left + window.pageXOffset + tooltipModel.caretX}px`
    tooltipEl.style.top = `${position.top + window.pageYOffset + tooltipModel.caretY}px`
    tooltipEl.style.font = bodyFont.string
    tooltipEl.style.pointerEvents = 'none'
}
