<template>
    <div :class="$style.wrapper">
        <Button data-test-id="createRoleButton" @clicked="toggleModal" color="primary">
            <span slot="buttonText">Get started hiring</span>
            <span slot="icon">
                <FontAwesomeIcon :icon="arrowRightIcon" />
            </span>
        </Button>
        <CreateRoleModal v-if="modalOpen" />
    </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { Button } from '@applied/marmot'
import CreateRoleModal from './CreateRoleModal/CreateRoleModal'

export default {
    name: 'CreateRole',
    components: {
        FontAwesomeIcon,
        Button,
        CreateRoleModal,
    },
    methods: {
        toggleModal() {
            this.$store.commit('toggleModalVisibility', {
                modalName: 'createRoleModal',
            })
        },
    },
    computed: {
        modalOpen() {
            return this.$store.state.modalVisibility.createRoleModal
        },
        arrowRightIcon() {
            return faAngleRight
        },
    },
}
</script>

<style module src="./CreateRole.css" />
