var render = function render(){var _vm=this,_c=_vm._self._c;return _c('li',{class:_vm.$style.wrapper},[_c(_vm.elementType,{tag:"component",class:{
            [_vm.$style.link]: true,
            [_vm.$style.disabled]: _vm.disabled === true,
            [_vm.$style.linkhover]: !_vm.complete,
            [_vm.$style.noClick]: _vm.complete,
        },attrs:{"href":_vm.link,"target":_vm.target,"tabindex":"0"},on:{"click":_vm.handleClickEvent,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.handleClickEvent.apply(null, arguments)}}},[_c('span',[_c('div',{class:_vm.$style.checkBox},[(_vm.complete)?_c('div',{class:_vm.$style.checkBoxComplete},[_c('FontAwesomeIcon',{attrs:{"icon":_vm.checkIcon}})],1):_vm._e()]),_c('span',{class:{
                    [_vm.$style.listText]: true,
                    [_vm.$style.strikeThrough]: _vm.complete,
                }},[_vm._v(_vm._s(_vm.text))])]),_c('FontAwesomeIcon',{class:_vm.$style.faIcon,attrs:{"icon":_vm.chevronIcon}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }