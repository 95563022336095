var render = function render(){var _vm=this,_c=_vm._self._c;return _c('VDataTable',{class:_vm.$style.heatmapTable,attrs:{"headers":_vm.paddedHeaders,"items":_vm.tableData,"disable-pagination":"","hide-default-footer":true},scopedSlots:_vm._u([{key:"body",fn:function(props){return _vm._l((props.items),function(item,itemIndex){return _c('tr',{key:itemIndex},_vm._l((props.headers),function(header,headerIndex){return _c('td',{directives:[{name:"show",rawName:"v-show",value:(
                    !(_vm.isBucketColumn(header.value) && item.notEnoughData && headerIndex > 2)
                ),expression:"\n                    !(isBucketColumn(header.value) && item.notEnoughData && headerIndex > 2)\n                "}],key:headerIndex,class:{ [_vm.$style.bucket]: _vm.isBucketColumn(header.value) },style:(_vm.isBucketColumn(header.value) && _vm.bucketStyle(item[header.value])),attrs:{"colspan":_vm.isBucketColumn(header.value) && item.notEnoughData && headerIndex === 2
                        ? props.headers.length - 2
                        : 1}},[(header.value === 'name' && _vm.isUrl(item[header.value]))?_c('a',{class:_vm.$style.sourceLink,attrs:{"target":"_blank","href":_vm.formatUrl(item[header.value])}},[_vm._v(_vm._s(item[header.value]))]):(
                        item.notEnoughData && _vm.isBucketColumn(header.value) && headerIndex === 2
                    )?_c('span',[_vm._v(" You need 5+ candidates to see data ")]):(!item.notEnoughData || !_vm.isBucketColumn(header.value))?_c('span',[_vm._v(_vm._s(item[header.value] || 0)),(
                            !item.notEnoughData &&
                            _vm.category !== 'performance' &&
                            _vm.isBucketColumn(header.value)
                        )?_c('span',[_vm._v("%")]):_vm._e()]):_vm._e()])}),0)})}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }