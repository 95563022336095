exports.default = {
    all: {
        title: 'All Candidates',
        statKeys: ['all'],
    },
    incomplete: {
        title: 'Shown Interest',
        statKeys: ['shownInterest'],
    },
    awaitingReview: {
        title: 'Submitted',
        statKeys: ['awaitingReview'],
        stageSelectable: true,
    },
    inReviewOrAwaitingInterview: {
        title: 'In Review',
        statKeys: ['inReview', 'awaitingInterview'],
        stageSelectable: true,
    },
    inInterviewOrAwaitingDecision: {
        title: 'Interviews & Tasks',
        statKeys: ['inInterview', 'awaitingDecision'],
        stageSelectable: true,
    },
    hired: {
        title: 'Hired',
        statKeys: ['hired'],
    },
    rejectedAfterSubmission: {
        title: 'Rejected',
        statKeys: ['rejectedAfterSubmission'],
    },
}
