<template>
    <div :class="$style.candidateNote">
        <div :class="$style.header" @click="toggleOpen" @keyDown="toggleOpen">
            Are you trying to complete a job application?
            <FontAwesomeIcon :class="$style.toggle" :icon="icon" />
        </div>

        <p :class="$style.note" v-if="isOpen">
            You won’t be able to log in if you are a candidate trying to complete an application for
            a job via Applied. Instead, you should finish your application by going to the unique
            candidate link that we sent to your email when you started applying for the job.
        </p>
    </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faChevronUp, faChevronDown } from '@fortawesome/fontawesome-free-solid'

export default {
    name: 'CandidateNote',
    components: { FontAwesomeIcon },
    data() {
        return {
            isOpen: false,
        }
    },
    methods: {
        toggleOpen() {
            this.isOpen = !this.isOpen
        },
    },
    computed: {
        icon() {
            return this.isOpen ? faChevronUp : faChevronDown
        },
    },
}
</script>

<style module src="./CandidateNote.css" />
