<template>
    <div :class="$style.card">
        <div :class="$style.header">
            <FontAwesomeIcon :icon="faBellRing"/>
            Missing company information
        </div>
        <p :class="$style.alertText">
            Go to org settings to fill in your company's missing 
            information to keep your account up to date.
        </p>
        <Button
            :class="$style.completeInfoButton"
            color="text"
            @clicked="goToOrgSettings()"    
        >
            <span slot="buttonText">
                Complete my info
                <FontAwesomeIcon :icon="faChevronRight"/>
            </span>
        </Button>
    </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faChevronRight } from '@fortawesome/pro-light-svg-icons'
import { faBellRing } from '@fortawesome/pro-solid-svg-icons'
import { Button } from '@applied/marmot'
import mapIcons from '@/utils/mapIcons'

export default {
    name: 'MissingOrgInfoAlert',
    components: {
        FontAwesomeIcon,
        Button,
    },
    computed: {
        ...mapIcons({ faChevronRight, faBellRing })
    },
    methods: {
        goToOrgSettings() {
            this.$router.push({ path: '/settings/account' })
        }
    }
}
</script>
<style module src="./MissingOrgInfoAlert.css" />