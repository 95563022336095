<template>
    <div>
        <div v-if="feedbackCount > 0">
            <div :class="$style.highlightSection" v-if="mainScore">
                <div :class="$style.highlightInner">
                    <span :class="$style.bigStatWrap">
                        <p :class="$style.bigStat" v-if="mainScore">
                            {{ mainScore.toFixed(1) }}/10
                        </p>
                        <Tooltip placement="right" v-if="tooltipText">
                            <template slot="default">
                                <div :class="$style.link">
                                    <FontAwesomeIcon :icon="questionIcon" /></div
                            ></template>
                            <template slot="inner">
                                <p>
                                    <slot name="tooltip">{{ tooltipText }}</slot>
                                </p>
                            </template>
                        </Tooltip>
                    </span>
                    <div :class="$style.starRow">
                        <StarRating :total="10" :value="mainScore" />
                        <span>({{ feedbackCount }})</span>
                    </div>
                    <div :class="$style.blobRow" v-if="detailedScores">
                        <StatBlob
                            v-for="(score, index) in detailedScores"
                            :name="score.name"
                            :value="score.score.toFixed(1)"
                            :key="index"
                            color="purple"
                        />
                    </div>
                </div>
            </div>
            <div :class="$style.feedbackBody">
                <div :class="$style.column">
                    <p :class="$style.sectionTitle">What do {{ users }} say?</p>
                    <div :class="[$style.feedbackCol, $style.fixHeight]">
                        <div v-if="textOnlyFeedback.length > 0">
                            <FeedbackSection
                                :key="index"
                                v-for="(feedback, index) in textOnlyFeedback"
                                :text="feedback.text"
                                :score="feedback.rating"
                                :createdAt="feedback.createdAt"
                            />
                        </div>
                        <div v-else>
                            <div :class="$style.noFeedbackSection">
                                <FontAwesomeIcon :icon="exclamationIcon" :class="$style.icon" />
                                <p>There currently aren't any feedback comments</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div :class="$style.column">
                    <p :class="$style.sectionTitle">How many people gave good or bad ratings?</p>
                    <div :class="$style.feedbackCol">
                        <div :class="$style.adviceCallout">
                            <p>
                                This graph shows the distribution of ratings for likelihood to
                                recommend, fairness and enjoyability.
                            </p>
                            <p>
                                A concentration of higher bars on the right side of the chart
                                indicates that your candidate experience is excellent.
                            </p>
                        </div>
                        <div :class="$style.statsRow" v-if="averages">
                            <StatBlob
                                name="Average"
                                v-if="averages.ratingMean"
                                :value="averages.ratingMean.toFixed(1)"
                            />
                            <StatBlob
                                name="Median"
                                v-if="averages.ratingMedian"
                                :value="averages.ratingMedian.toFixed(1)"
                            />
                        </div>
                        <div v-if="showBarChart">
                            <BarChart :chartId="chartName" :chartData="chartData" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div :class="$style.emptyState" v-else>
            <TipCard>
                <div :class="$style.emptyStateMessage" slot="headerMessage">
                    There is no feedback to show for this time period
                </div>
                <div slot="message"></div>
            </TipCard>
        </div>
    </div>
</template>

<script>
import { Tooltip } from '@applied/marmot'
import { faInfoCircle, faExclamationCircle } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { sumFlatArray } from '@/utils/array'
import TipCard from '@/components/TipCard/TipCard'
import StarRating from '../StarRating/StarRating'
import BarChart from '../BarChart/BarChart'
import FeedbackSection from '../FeedbackSection/FeedbackSection'
import StatBlob from '../StatBlob/StatBlob'

export default {
    name: 'FeedbackTab',
    props: {
        detailedScores: Array,
        feedbacks: {
            type: Array,
            default: () => [],
        },
        users: {
            type: String,
            default: 'candidates',
        },
        averages: Object,
        mainScore: Number,
        chartData: Array,
        chartName: String,
        tooltipText: String,
    },
    components: {
        StatBlob,
        FeedbackSection,
        BarChart,
        StarRating,
        FontAwesomeIcon,
        Tooltip,
        TipCard,
    },
    computed: {
        exclamationIcon() {
            return faExclamationCircle
        },
        questionIcon() {
            return faInfoCircle
        },
        feedbackCount() {
            return this.chartData && sumFlatArray(this.chartData[0].data)
        },
        showBarChart() {
            return this.chartData && this.feedbackCount > 0
        },
        textOnlyFeedback() {
            return this.feedbacks.filter((feedback) => !!(feedback.text && feedback.text.trim()))
        },
    },
}
</script>

<style module src="./FeedbackTab.css" />
