var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{ [_vm.$style.wrapper]: true, [_vm.$style.small]: _vm.small }},[(_vm.pending)?_c('Spinner',{attrs:{"color":"#6d778a","size":"32px"}}):_c('div',{class:_vm.$style.layerWrap},[(_vm.showslot('tooltip'))?_c('div',{class:[_vm.$style.tooltip, _vm.$style.layer1]},[_c('Tooltip',{attrs:{"placement":"right"}},[_c('template',{slot:"default"},[_c('div',[_c('FontAwesomeIcon',{attrs:{"icon":_vm.questionIcon}})],1)]),_c('template',{slot:"inner"},[_c('p',[_vm._t("tooltip")],2)])],2)],1):_vm._e(),_c('div',{class:_vm.$style.layer2},_vm._l((_vm.sections),function(section){return _c('div',{key:_vm.sections.indexOf(section),class:[
                    _vm.$style.textWrapper,
                    section === 'title' ? _vm.$style.title : '',
                    section === 'metric' ? _vm.$style.metric : '',
                    section === 'description' ? _vm.$style.description : '',
                    section === 'link' ? _vm.$style.link : '',
                ]},[(_vm.showslot(section))?_vm._t(section):_vm._e()],2)}),0)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }