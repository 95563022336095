<template>
    <Modal @close="$emit('close')">
        <span slot="header">Close and archive role?</span>
        <div slot="message" :class="$style.body">
            <p>
                Archiving a role will close it immediately and candidates will no longer be able to
                submit applications.
            </p>

            <p>
                Once archived, you'll find the role under the 'archived' section and you can always
                go there and reopen it in case you change your mind.
            </p>
        </div>
        <div slot="content" :class="$style.buttonWrap">
            <Button color="secondary" @clicked="$emit('close')">
                <span slot="buttonText"> Cancel </span>
            </Button>
            <Button color="primary" @clicked="archiveRole">
                <span slot="buttonText"> Archive </span>
            </Button>
        </div>
    </Modal>
</template>

<script>
import { Button, Modal } from '@applied/marmot'

export default {
    name: 'ArchiveConfirmationModal',
    props: {
        JobId: Number,
    },
    components: {
        Modal,
        Button,
    },
    methods: {
        archiveRole() {
            this.$store.dispatch('closeAndArchiveRole', this.JobId)
        },
    },
}
</script>

<style module src="./ArchiveConfirmationModal.css" />
