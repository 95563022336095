module.exports = {
     preferredCurrencyCodes:
         ['GBP', 'AUD', 'USD', 'CAD', 'EUR', 'CHF', 'INR', 'SGD', 'NZD'],
     all: {
          AED: {},
          AFN: {},
          ALL: {},
          AMD: {},
          ANG: {},
          AOA: {},
          ARS: {},
          AUD: {},
          AWG: {},
          AZN: {},
          BAM: {},
          BBD: {},
          BDT: {},
          BGN: {},
          BHD: {},
          BIF: {},
          BMD: {},
          BND: {},
          BOB: {},
          BRL: {},
          BSD: {},
          BTN: {},
          BWP: {},
          BYN: {},
          BZD: {},
          CAD: {},
          CDF: {},
          CHF: {},
          CLP: {},
          CNY: {},
          COP: {},
          CRC: {},
          CUP: {},
          CVE: {},
          CZK: {},
          DJF: {},
          DKK: {},
          DOP: {},
          DZD: {},
          EGP: {},
          ERN: {},
          ETB: {},
          EUR: {},
          FKP: {},
          GBP: {},
          GEL: {},
          GHS: {},
          GMD: {},
          GNF: {},
          GTQ: {},
          GYD: {},
          HKD: {},
          HNL: {},
          HRK: {},
          HTG: {},
          HUF: {},
          IDR: {},
          ILS: {},
          INR: {},
          IQD: {},
          IRR: {},
          ISK: {},
          JMD: {},
          JOD: {},
          JPY: {},
          KES: {},
          KGS: {},
          KHR: {},
          KMF: {},
          KPW: {},
          KRW: {},
          KWD: {},
          KYD: {},
          KZT: {},
          LAK: {},
          LBP: {},
          LKR: {},
          LRD: {},
          LSL: {},
          LYD: {},
          MAD: {},
          MDL: {},
          MGA: {},
          MKD: {},
          MMK: {},
          MNT: {},
          MOP: {},
          MRU: {},
          MUR: {},
          MVR: {},
          MWK: {},
          MXN: {},
          MYR: {},
          MZN: {},
          NAD: {},
          NGN: {},
          NIO: {},
          NOK: {},
          NPR: {},
          NZD: {},
          OMR: {},
          PAB: {},
          PEN: {},
          PHP: {},
          PKR: {},
          PLN: {},
          PYG: {},
          QAR: {},
          RON: {},
          RSD: {},
          RUB: {},
          RWF: {},
          SAR: {},
          SCR: {},
          SDG: {},
          SEK: {},
          SGD: {},
          SLL: {},
          SOS: {},
          SRD: {},
          SSP: {},
          STN: {},
          SYP: {},
          SZL: {},
          THB: {},
          TJS: {},
          TMT: {},
          TND: {},
          TRY: {},
          TTD: {},
          TWD: {},
          TZS: {},
          UAH: {},
          UGX: {},
          USD: {},
          UYU: {},
          UZS: {},
          VED: {},
          VND: {},
          XAF: {},
          XCD: {},
          XOF: {},
          YER: {},
          ZAR: {},
     },
}
