import { sumFlatArray, averageArrayOfCounts } from '@/utils/array'

const calculateBigScore = (objectOfArrayTallys) => {
    if (Object.values(objectOfArrayTallys).length === 0) {
        return undefined
    }
    const averageArray = Object.values(objectOfArrayTallys)
        .map((scoreArray) => averageArrayOfCounts(scoreArray))
        .filter(Boolean)
    return sumFlatArray(averageArray) / averageArray.length
}

export default calculateBigScore
