<template>
    <div :class="$style.summaryCardsContainer">
        <KeyNumbersCard :pending="isLoading" :small="true">
            <span slot="title">Total Candidates</span>
            <span slot="metric">{{ counts.candidates }}</span>
            <span slot="tooltip">
                The total number of applications submitted to roles at your organisation in this
                period.
            </span>
            <span slot="description">applications</span>
        </KeyNumbersCard>
        <KeyNumbersCard :pending="isLoading" :small="true">
            <span slot="title">Open Roles</span>
            <span slot="metric">{{ counts.jobs }}</span>
            <span slot="tooltip">
                The number of roles that received applications during this period
            </span>
            <span slot="description">jobs</span>
        </KeyNumbersCard>
        <KeyNumbersCard :pending="isLoading" :small="true">
            <span slot="title">Hired</span>
            <span slot="metric">{{ counts.hired }}</span>
            <span slot="tooltip"> The number of candidates marked as 'hired' in this period. </span>
            <span slot="description">candidates</span>
        </KeyNumbersCard>
        <KeyNumbersCard :pending="isLoading" :small="true">
            <span slot="title">Feedback</span>
            <span slot="metric">{{ counts.rejected }}</span>
            <span slot="tooltip">
                The number of candidates you rejected in this period - equal to the number of
                candidates who received feedback through the platform.
            </span>
            <span slot="description">reviews given</span>
        </KeyNumbersCard>
        <KeyNumbersCard :pending="isLoading" :small="true">
            <span slot="title">Time to Hire</span>
            <span slot="metric">{{ Number(timeToHire).toFixed(1) }}</span>
            <span slot="tooltip">
                The average number of days between an application being submitted and a candidate
                being marked as hired (for applications ending in hires only).
            </span>
            <span slot="description">days</span>
        </KeyNumbersCard>
    </div>
</template>

<script>
import KeyNumbersCard from '@/components/KeyNumbersCard/KeyNumbersCard'

export default {
    name: 'KeyNumbersSection',
    components: {
        KeyNumbersCard,
    },
    props: {
        isLoading: { type: Boolean, default: false },
        keyNumbers: { type: Object, default: () => {} },
    },
    computed: {
        counts() {
            return this.keyNumbers.counts
        },
        timeToHire() {
            return this.keyNumbers.timeToHire
        },
    },
}
</script>

<style module src="./KeyNumbersSection.css" />
