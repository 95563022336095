<template>
    <div :class="$style.footerWrapper">
        <div :class="$style.footer">
            <div :class="$style.footerRow">
                <div :class="$style.column">
                    <h4 :class="$style.footerH4">Community</h4>
                    <a
                        href="https://www.facebook.com/beapplied/"
                        target="_blank"
                        :class="$style.socialRow"
                    >
                        <FontAwesomeIcon :icon="facebookIcon" :class="$style.faIcon" />
                        <div :class="$style.socialMediaTitle">Facebook</div>
                    </a>
                    <a
                        href="https://twitter.com/beapplied"
                        target="_blank"
                        :class="$style.socialRow"
                    >
                        <FontAwesomeIcon :icon="twitterIcon" :class="$style.faIcon" />
                        <div :class="$style.socialMediaTitle">Twitter</div>
                    </a>
                    <a
                        href="https://www.linkedin.com/company/beapplied/"
                        target="_blank"
                        :class="$style.socialRow"
                    >
                        <FontAwesomeIcon :icon="linkedinIcon" :class="$style.faIcon" />
                        <div :class="$style.socialMediaTitle">LinkedIn</div>
                    </a>
                </div>
                <div :class="$style.column">
                    <h4 :class="$style.footerH4">Contact us</h4>
                    <p :class="$style.address">
                        Be Applied Ltd, <br />Public Hall,<br />1 Horse Guards Ave, Westminster,<br />London,<br />SW1A
                        2HU
                    </p>
                    <a
                        href="mailto:hello@beapplied.com?subject=Hey%20Applied!"
                        :class="$style.mailLink"
                        >hello@beapplied.com</a
                    >
                </div>
                <div :class="$style.column">
                    <h4 :class="$style.footerH4">Read more from Applied</h4>
                    <a href="/job-boards" target="_blank" :class="$style.blogLink">
                        <div :class="$style.socialMediaTitle">Our job site leaderboard</div>
                    </a>
                    <a
                        href="https://www.beapplied.com/post/what-is-blind-hiring"
                        target="_blank"
                        :class="$style.blogLink"
                    >
                        <div :class="$style.socialMediaTitle">What is blind hiring?</div>
                    </a>
                    <a
                        href="https://www.beapplied.com/post/is-my-recruitment-process-biased"
                        target="_blank"
                        :class="$style.blogLink"
                    >
                        <div :class="$style.socialMediaTitle">
                            Is my recruitment process biased?
                        </div>
                    </a>
                    <a
                        href="https://www.beapplied.com/post/how-do-i-remove-bias-from-my-recruitment-process"
                        target="_blank"
                        :class="$style.blogLink"
                    >
                        <div :class="$style.socialMediaTitle">
                            How do I remove bias from my recruitment process?
                        </div>
                    </a>
                    <a
                        href="https://www.beapplied.com/post/9-reasons-your-job-ads-dont-work"
                        target="_blank"
                        :class="$style.blogLink"
                    >
                        <div :class="$style.socialMediaTitle">
                            9 reasons your job ads don't work
                        </div>
                    </a>
                </div>
            </div>
            <div :class="[$style.footerRow, $style.border]">
                <div :class="$style.footerBlock">
                    <a href="https://www.beapplied.com/" :class="$style.footerLink">
                        <img
                            alt="Applied logo: redirects to Applied home page"
                            :class="$style.appliedLogo"
                            src="@/assets/applied_logo_master_white.svg"
                    /></a>
                    <a href="https://www.beapplied.com/about" :class="$style.footerLink">About</a>
                    <a href="https://www.beapplied.com/privacy" :class="$style.footerLink"
                        >Privacy
                    </a>
                    <a href="https://www.beapplied.com/terms-of-use" :class="$style.footerLink"
                        >Terms
                    </a>
                    <a href="https://www.beapplied.com/security" :class="$style.footerLink"
                        >Security</a
                    >
                    <a href="/job-board/" :class="$style.footerLink">Job Board</a>
                </div>
                <div :class="$style.footerBlock">
                    <div :class="$style.footerCopyright">Copyright ©2021 Be Applied Ltd.</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faFacebookF, faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons'

export default {
    name: 'Footer',
    components: {
        FontAwesomeIcon,
    },
    computed: {
        facebookIcon() {
            return faFacebookF
        },
        twitterIcon() {
            return faTwitter
        },
        linkedinIcon() {
            return faLinkedin
        },
    },
}
</script>

<style module src="./Footer.css" />
