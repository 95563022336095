<template>
    <transition name="fade" mode="out-in">
        <div :class="$style.wrapper">
            <h3 :class="[$style.text, $style.title]">
                Congratulations, your roles have all been updated!
            </h3>
            <p :class="$style.text">
                You'll now have more accurate data to inform your diversity reports, and you'll get
                a better idea of your average time to hire.
            </p>
            <div :class="$style.buttonRow">
                <Button
                    :class="$style.thanksButton"
                    color="primary"
                    @keyup.enter="dismissThanks"
                    @clicked="dismissThanks"
                >
                    <span slot="buttonText">OK, got it</span>
                </Button>
            </div>
        </div>
    </transition>
</template>

<script>
import { Button } from '@applied/marmot'

export default {
    name: 'ThanksCard',
    components: {
        Button,
    },
    methods: {
        dismissThanks() {
            this.$emit('dismissed')
        },
    },
}
</script>

<style module src="./ThanksCard.css" />

<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.9s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}
</style>
