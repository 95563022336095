import Vue from 'vue'

const storeSampleRoleId = (state, data) => {
    Vue.set(state, 'sampleRoleId', data.id)
}

const storeJobs = (state, data) => {
    Vue.set(state, 'orgJobs', data)
}

const storeJobFilters = (state, data) => {
    Vue.set(state, 'jobFilters', data)
}

const storeJobsAwaitingHire = (state, data) => {
    Vue.set(state, 'jobsAwaitingHire', data)
}

const storeJobCounts = (state, data) => {
    Vue.set(state, 'jobCounts', data)
}

const storeJobPagination = (state, data) => {
    Vue.set(state, 'orgJobsPagination', data)
}

const updateJobStatus = (state, data) => {
    const jobIndex = state.jobsAwaitingHire.findIndex((job) => job.id === data.id)
    Vue.set(state.jobsAwaitingHire[jobIndex], 'status', data.status)
}

const addJob = (state, data) => {
    state.orgJobs.unshift(data)
}

const removeJob = (state, data) => {
    const newJobsThing = state.orgJobs.filter((job) => job.id !== data)
    Vue.set(state, 'orgJobs', newJobsThing)
}

const saveReviewToken = (state, data) => {
    Vue.set(state, 'reviewToken', data.token.hash)
}

const saveReviewTokenDetails = (state, data) => {
    Vue.set(state, 'reviewTokenDetails', data.tokenDetails)
    Vue.set(state, 'firstTimeReviewer', data.firstTimeReviewer)
}

const saveReviews = (state, data) => {
    Vue.set(state, 'reviews', data.reviews)
    const incompleteReviews = data.reviews.map((Question) =>
        Question.Answers.filter((answer) => !answer.Reviews[0].score),
    )

    Vue.set(state, 'incompleteReviews', incompleteReviews)
}

export default {
    storeJobs,
    storeJobFilters,
    storeJobPagination,
    addJob,
    removeJob,
    storeJobCounts,
    storeJobsAwaitingHire,
    updateJobStatus,
    saveReviewToken,
    saveReviewTokenDetails,
    saveReviews,
    storeSampleRoleId,
}
