<template>
    <div>
        <h4 :class="[$style.cardTitle, $style.smallMarginBottom]">Downloads</h4>
        <p :class="$style.smallMarginBottom">
            Prefer to wrangle with this data yourself? You can download .csv files below to analyse
            as you wish
            <span role="img" aria-label="celebration emoji"> 🎉 </span>
            .
        </p>
        <div :class="$style.downloadCardsContainer">
            <Card
                :class="$style.downloadCard"
                v-for="downloadCard in downloadCards"
                v-bind:key="downloadCard.title"
            >
                <div :class="$style.descriptionContainer">
                    <h5 :class="$style.downloadCardTitle">
                        {{ downloadCard.title }}
                    </h5>
                    <p>
                        {{ downloadCard.description }}
                    </p>
                </div>
                <Button :class="$style.downloadButton" @clicked="downloadCard.downloadFunction">
                    <span slot="buttonText">
                        Download
                        <span :class="$style.fileDownloadIcon">
                            <FontAwesomeIcon :icon="faFileAlt" />
                        </span>
                    </span>
                </Button>
            </Card>
        </div>
    </div>
</template>

<script>
import { Button, Card } from '@applied/marmot'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faFileAlt } from '@fortawesome/free-solid-svg-icons'
import mapIcons from '@/utils/mapIcons'

export default {
    name: 'DownloadsSection',
    components: {
        Button,
        FontAwesomeIcon,
        Card,
    },
    props: {
        dateRange: Object,
        filters: Object,
    },
    data() {
        return {
            downloadCards: [
                {
                    title: 'All demographic data (.csv file)',
                    description:
                        'This .csv file contains demographic data of the candidates who submitted applications during this period.',
                    downloadFunction: this.downloadDemographicCSV,
                },
                {
                    title: 'List of all candidates (.csv file)',
                    description:
                        'This .csv file contains all candidates who submitted applications during this period.',
                    downloadFunction: this.downloadSubmittedCSV,
                },
                {
                    title: 'List of all hired candidates (.csv file)',
                    description:
                        'This .csv file contains all candidates who were hired from applications submitted during this period.',
                    downloadFunction: this.downloadHiredCSV,
                },
            ],
        }
    },
    computed: {
        ...mapIcons({ faFileAlt }),
        teamsString() {
            const teams = this.filters?.team?.map((x) => x.value).join(',')
            return teams ? `&teams=${teams}` : ``
        },
        gradesString() {
            const grades = this.filters?.grade?.map((x) => x.value).join('||')
            return grades ? `&grades=${grades}` : ``
        },
    },
    methods: {
        downloadDemographicCSV() {
            window.open(
                `/api/org/org-drop-off/csv?from=${this.dateRange.from}&until=${this.dateRange.until}${this.teamsString}${this.gradesString}`,
            )
        },
        downloadSubmittedCSV() {
            window.open(
                // eslint-disable-next-line max-len
                `/api/applicant/list/csv?start=${this.dateRange.from}&end=${this.dateRange.until}${this.teamsString}${this.gradesString}&rangeType=submitted`,
            )
        },
        downloadHiredCSV() {
            window.open(
                // eslint-disable-next-line max-len
                `/api/applicant/list/csv?start=${this.dateRange.from}&end=${this.dateRange.until}${this.teamsString}${this.gradesString}&rangeType=hiredAt`,
            )
        },
    },
}
</script>

<style module src="./DownloadsSection.css" />
