<template>
    <div :class="$style.cardWrapper">
        <SkeletonBlock width="300px" style="margin-bottom: 24px" />
        <SkeletonBlock width="900px" />
        <SkeletonBlock width="900px" />
        <SkeletonBlock width="600px" />
        <div :class="$style.spaceBetweenRow">
            <SkeletonBlock width="300px" />
            <SkeletonBlock width="40px" />
        </div>
        <div :class="$style.spaceBetweenRow">
            <SkeletonBlock width="300px" />
            <SkeletonBlock width="40px" />
        </div>
        <div :class="$style.spaceBetweenRow">
            <SkeletonBlock width="300px" />
            <SkeletonBlock width="40px" />
        </div>
        <div :class="$style.spaceBetweenRow">
            <SkeletonBlock width="300px" />
            <SkeletonBlock width="40px" />
        </div>
        <div :class="$style.spaceBetweenRow" style="margin-top: 100px">
            <div>
                <SkeletonBlock width="300px" height="300px" />
                <SkeletonBlock width="250px" style="margin-top: 12px" />
            </div>
            <div>
                <SkeletonBlock width="300px" height="300px" />
                <SkeletonBlock width="250px" style="margin-top: 12px" />
            </div>
            <div>
                <SkeletonBlock width="300px" height="300px" />
                <SkeletonBlock width="250px" style="margin-top: 12px" />
            </div>
        </div>
        <SkeletonBlock width="300px" style="margin-bottom: 24px; margin-top: 48px" />
        <SkeletonBlock width="900px" />
        <SkeletonBlock width="900px" />
        <SkeletonBlock width="600px" />
        <div :class="$style.spaceBetweenRow">
            <SkeletonBlock width="300px" />
            <SkeletonBlock width="40px" />
        </div>
        <div :class="$style.spaceBetweenRow">
            <SkeletonBlock width="300px" />
            <SkeletonBlock width="40px" />
        </div>
        <div :class="$style.spaceBetweenRow">
            <SkeletonBlock width="300px" />
            <SkeletonBlock width="40px" />
        </div>
        <div :class="$style.spaceBetweenRow">
            <SkeletonBlock width="300px" />
            <SkeletonBlock width="40px" />
        </div>
    </div>
</template>

<script>
import SkeletonBlock from '@/components/SkeletonBlock/SkeletonBlock'

export default {
    name: 'SkeletonLoader',
    components: {
        SkeletonBlock,
    },
}
</script>

<style module src="./SkeletonLoader.css" />
