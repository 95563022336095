<template>
    <div>
        <Modal :class="$style.noAccessModal" :blurBackground="true">
            <div slot="message">
                <img
                    :class="$style.personWithReports"
                    src="@/assets/woman-with-reports.svg"
                    alt="person standing in front of reports"
                />
                <h1 :class="$style.modalContentHeader">
                    Would you like to access Global Reporting?
                </h1>
                <p>
                    Global Reporting is a suite of tools built so you can make sense of your
                    organisation's overall hiring health.
                </p>
                <p>
                    It combines the reporting features you find on each role with powerful filters
                    and aggregated insights to help you understand what you're doing well and how
                    you could be hiring more fairly and effectively.
                </p>
                <p>
                    <a
                        href="https://appliedhelp.zendesk.com/hc/en-us/articles/5441278474129"
                        target="_blank"
                    >
                        Find out more
                    </a>
                </p>
            </div>
            <div slot="content" :class="$style.buttonRow">
                <Button color="secondary" @clicked="goToDashboard">
                    <div slot="buttonText">Back to Dashboard</div>
                </Button>
                <Button color="primary" :class="$style.nextButton" @clicked="goNext">
                    <div slot="buttonText">Next</div>
                </Button>
            </div>
        </Modal>
    </div>
</template>

<script>
import { Button, Modal } from '@applied/marmot'

export default {
    name: 'NoAccessModal',
    components: {
        Button,
        Modal,
    },
    methods: {
        goToDashboard() {
            this.$router.push({ path: 'dashboard' })
        },
        goNext() {
            this.$emit('close')
        },
    },
}
</script>

<style module src="./NoAccessModal.css" />
