<template>
    <VDataTable
        :headers="paddedHeaders"
        :items="tableData"
        :class="$style.heatmapTable"
        disable-pagination
        :hide-default-footer="true"
    >
        <template v-slot:body="props">
            <tr v-for="(item, itemIndex) in props.items" :key="itemIndex">
                <td
                    v-for="(header, headerIndex) in props.headers"
                    :key="headerIndex"
                    :class="{ [$style.bucket]: isBucketColumn(header.value) }"
                    :style="isBucketColumn(header.value) && bucketStyle(item[header.value])"
                    v-show="
                        !(isBucketColumn(header.value) && item.notEnoughData && headerIndex > 2)
                    "
                    :colspan="
                        isBucketColumn(header.value) && item.notEnoughData && headerIndex === 2
                            ? props.headers.length - 2
                            : 1
                    "
                >
                    <a
                        :class="$style.sourceLink"
                        v-if="header.value === 'name' && isUrl(item[header.value])"
                        target="_blank"
                        :href="formatUrl(item[header.value])"
                        >{{ item[header.value] }}</a
                    >
                    <span
                        v-else-if="
                            item.notEnoughData && isBucketColumn(header.value) && headerIndex === 2
                        "
                    >
                        You need 5+ candidates to see data
                    </span>
                    <span v-else-if="!item.notEnoughData || !isBucketColumn(header.value)"
                        >{{ item[header.value] || 0
                        }}<span
                            v-if="
                                !item.notEnoughData &&
                                category !== 'performance' &&
                                isBucketColumn(header.value)
                            "
                            >%</span
                        >
                    </span>
                </td>
            </tr>
        </template>
    </VDataTable>
</template>

<script>
import { VDataTable } from 'vuetify/lib'

const cellWhiteTextThresholdPercent = 0.6

export default {
    name: 'HeatmapTable',
    components: {
        VDataTable,
    },
    props: {
        tableHeaders: {
            type: Array,
        },
        tableData: {
            type: Array,
        },
        category: {
            type: String,
        },
        staticHeaderCount: {
            type: Number,
            default: 2,
        },
        // The maximum number that a value from a cell can be.
        numericValueCeiling: {
            type: Number,
            default: 100,
        },
    },
    computed: {
        paddedHeaders() {
            if (this.tableHeaders.length > this.staticHeaderCount) {
                return this.tableHeaders
            }

            return [
                ...this.tableHeaders,
                {
                    text: '',
                    value: '',
                },
            ]
        },
    },
    methods: {
        bucketStyle(cellValue) {
            const opacity = cellValue / (this.numericValueCeiling || 100)
            const style = {
                background: `rgba(33, 103, 196, ${opacity})`,
            }

            if (cellValue >= cellWhiteTextThresholdPercent * (this.numericValueCeiling || 100)) {
                style.color = `#FFFFFF`
            }

            return style
        },
        isBucketColumn(text) {
            return !['name', 'total'].includes(text)
        },
        isUrl(text) {
            const regex = /^((?:https?:\/\/)?[^./]+(?:\.[^./]+)+(?:\/.*)?)$/
            return new RegExp(regex, 'i').test(text)
        },
        formatUrl(url) {
            return url.indexOf('//') === -1 ? `//${url}` : url
        },
    },
}
</script>

<style module src="./HeatmapTable.css" />
