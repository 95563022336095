<template>
    <div
        :class="{
            [$style.menuSection]: true,
            [$style.primary]: color === 'primary',
            [$style.secondary]: color === 'secondary',
        }"
    >
        <div :class="$style.dot"></div>
        <div :class="$style.dot"></div>
        <div :class="$style.dot"></div>
    </div>
</template>

<script>
export default {
    name: 'DotMenu',
    props: ['color'],
}
</script>

<style module src="./DotMenu.css" />
