<template>
    <Modal @close="$emit('close')">
        <span slot="header">Reset your password</span>
        <div slot="message">
            <div :class="$style.form">
                <p :class="$style.inputTitle">Email address</p>
                <div :class="$style.inputFieldWrapper">
                    <TextInput
                        v-model="emailAddress"
                        ariaLabel="Email address"
                        autocomplete="email"
                    />
                </div>
                <p :class="$style.inputComment">
                    You need to use the same email you registered with
                </p>
            </div>
            <div v-if="showMessage">
                <Alert
                    v-if="passwordResetError"
                    message="Whoops something went wrong - make sure you're using the same email address you registered with."
                    color="danger"
                />
                <Alert
                    v-else
                    message="Awesome - check your email for a link where you can reset your password!"
                    color="success"
                />
            </div>
            <div :class="$style.buttonRow">
                <Button @clicked="$emit('close')" color="secondary"
                    ><span slot="buttonText">Cancel</span></Button
                ><Button @clicked="handleSubmit" :pending="isResettingPassword"
                    ><span slot="buttonText">Send reset link</span></Button
                >
            </div>
        </div>
    </Modal>
</template>

<script>
import { Alert, Button, Modal, TextInput } from '@applied/marmot'

export default {
    name: 'PasswordResetModal',
    components: {
        Modal,
        Button,
        Alert,
        TextInput,
    },
    data() {
        return {
            emailAddress: '',
            showMessage: false,
        }
    },
    methods: {
        handleSubmit() {
            this.$store
                .dispatch('sendPasswordResetEmail', {
                    resetemail: this.emailAddress,
                })
                .then(() => {
                    this.showMessage = true
                })
        },
    },
    computed: {
        isResettingPassword() {
            return this.$store.state.pending.sendPasswordResetEmail
        },
        passwordResetError() {
            return this.$store.state.error.sendPasswordResetEmail
        },
    },
}
</script>

<style module src="./style.css" />
