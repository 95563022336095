import axios from '@/axios'

const fetchOrgEmailTemplates = (context) => {
    const url = `/api/org/all-email-templates`

    return axios
        .get(url, {})
        .then((response) => {
            context.commit('storeOrgEmailTemplates', response.data)
            return Promise.resolve()
        })
        .catch((err) => {
            context.commit('storeOrgEmailTemplates', [])
            return Promise.reject(err)
        })
}

const saveEmailTemplate = (context, data) => {
    context.commit('pending', { type: 'saveEmailTemplate', status: true })
    context.commit('error', { type: 'saveEmailTemplate', status: false })

    const emailTemplate = { ...data, orgId: context.state.user.OrgId }

    return axios
        .post('/api/email-templates/create', emailTemplate)
        .then(() => {
            context.dispatch('fetchOrgEmailTemplates')
            context.commit('pending', {
                type: 'saveEmailTemplate',
                status: false,
            })
            return Promise.resolve()
        })
        .catch((err) => {
            context.commit('pending', {
                type: 'saveEmailTemplate',
                status: false,
            })
            context.commit('error', {
                type: 'saveEmailTemplate',
                status: true,
            })
            // eslint-disable-next-line prefer-promise-reject-errors
            return Promise.reject({ message: err.response.data.message })
        })
}

const updateEmailTemplate = (context, data) => {
    context.commit('pending', { type: 'updateEmailTemplate', status: true })
    context.commit('error', { type: 'updateEmailTemplate', status: false })

    const emailTemplate = { ...data.request, orgId: context.state.user.OrgId }

    return axios
        .post(`/api/email-templates/${data.templateId}/update`, emailTemplate)
        .then(() => {
            context.dispatch('fetchOrgEmailTemplates')
            context.commit('pending', {
                type: 'updateEmailTemplate',
                status: false,
            })
            return Promise.resolve()
        })
        .catch((err) => {
            context.commit('pending', {
                type: 'updateEmailTemplate',
                status: false,
            })
            context.commit('error', {
                type: 'updateEmailTemplate',
                status: true,
            })
            // eslint-disable-next-line prefer-promise-reject-errors
            return Promise.reject({ message: err.response.data.message })
        })
}

const deleteCustomTemplate = (context, data) => {
    context.commit('pending', { type: 'deleteCustomTemplate', status: true })
    context.commit('error', { type: 'deleteCustomTemplate', status: false })

    const { user } = context.state
    const orgId = context.state.user.OrgId
    const { template } = data

    return axios
        .post(
            `/api/email-templates/${template.id}/delete`,
            {
                orgId,
                name: template.name,
                isDefault: template.isDefault,
            },
            user,
        )
        .then(() => {
            context.dispatch('fetchOrgEmailTemplates')
            context.commit('pending', {
                type: 'deleteCustomTemplate',
                status: false,
            })
            context.commit('error', {
                type: 'deleteCustomTemplate',
                status: false,
            })
            return Promise.resolve()
        })
        .catch(() => {
            context.commit('pending', {
                type: 'deleteCustomTemplate',
                status: false,
            })
            context.commit('error', { type: 'saveEmailTemplate', status: true })
            return Promise.reject()
        })
}

export default {
    fetchOrgEmailTemplates,
    saveEmailTemplate,
    updateEmailTemplate,
    deleteCustomTemplate,
}
