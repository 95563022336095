<template>
    <transition name="slide-fade">
        <!-- eslint-disable vue-a11y/label-has-for -->
        <div :class="$style.container">
            <h3 :class="$style.headerText">Would you recommend Applied to a friend?</h3>
            <div :class="$style.ratingWrapper">
                <h6 :class="$style.subText">Definitely Not</h6>
                <span :class="$style.starRating">
                    <input
                        id="rating10"
                        type="radio"
                        value="10"
                        @click="emitRating"
                        v-on:keyup.enter="emitRating"
                    />
                    <!-- eslint-disable vuejs-accessibility/label-has-for -->
                    <label for="rating10">10</label>
                    <input
                        id="rating9"
                        type="radio"
                        value="9"
                        @click="emitRating"
                        v-on:keyup.enter="emitRating"
                    /><label for="rating9">9</label>
                    <input
                        id="rating8"
                        type="radio"
                        value="8"
                        @click="emitRating"
                        v-on:keyup.enter="emitRating"
                    /><label for="rating8">8</label>
                    <input
                        id="rating7"
                        type="radio"
                        value="7"
                        @click="emitRating"
                        v-on:keyup.enter="emitRating"
                    /><label for="rating7">7</label>
                    <input
                        id="rating6"
                        type="radio"
                        value="6"
                        @click="emitRating"
                        v-on:keyup.enter="emitRating"
                    /><label for="rating6">6</label>
                    <input
                        id="rating5"
                        type="radio"
                        value="5"
                        @click="emitRating"
                        v-on:keyup.enter="emitRating"
                    /><label for="rating5">5</label>
                    <input
                        id="rating4"
                        type="radio"
                        value="4"
                        @click="emitRating"
                        v-on:keyup.enter="emitRating"
                    /><label for="rating4">4</label>
                    <input
                        id="rating3"
                        type="radio"
                        value="3"
                        @click="emitRating"
                        v-on:keyup.enter="emitRating"
                    /><label for="rating3">3</label>
                    <input
                        id="rating2"
                        type="radio"
                        value="2"
                        @click="emitRating"
                        v-on:keyup.enter="emitRating"
                    /><label for="rating2">2</label>
                    <input
                        id="rating1"
                        type="radio"
                        value="1"
                        @click="emitRating"
                        v-on:keyup.enter="emitRating"
                    /><label for="rating1">1</label>
                </span>
                <h6 :class="$style.subText">Definitely</h6>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'StarRatingSelect',
    methods: {
        emitRating(event) {
            this.$emit('clicked', event.target.value)
        },
    },
}
</script>

<style scoped>
.slide-fade-enter-active {
    transition: all 0.5s ease;
}

.slide-fade-leave-active {
    transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
    transform: translateY(25px);
    opacity: 0;
}
</style>

<style module src="./StarRatingSelect.css" />
