const loginQuotes = [
    {
        image: 'Wheel-66.png',
        title: '66% reduction in hiring time',
        text: 'We make sure the hiring process is as fair and efficient as possible. Some of our customers have reported cutting the time they spend on recruiting by two thirds! That means you get to spend more time on what matters...like tea and cats.',
    },
    {
        image: 'Star-rating-login.png',
        title: '9/10 average candidate experience rating',
        text: 'Across over 500,000 applications, we’ve had a lot of feedback. We’re proud to say that employers and candidates alike love us.',
    },
    {
        image: '3-step-improvements.png',
        title: '3x improvement in interview to offer rate',
        text: 'By assessing candidates on what matters up front, you’re more likely to have quality candidates at the end. Hiring teams using Applied find that they have three times as many suitable candidates as before.',
    },
    {
        image: 'Stats.png',
        title: 'Up to 4X attraction and selection of ethnically diverse candidates',
        text: 'Our anonymised hiring process and tools such as work samples and structured interviews remove unconscious bias from decision making.',
    },
    {
        image: 'Success-in-turnover.png',
        title: 'First year turnover 3X better than average',
        text: 'By helping predict the best candidates for the job, successful hires are less likely to leave within the first year.',
    },
]

const orgSignupQuotes = [
    {
        image: 'loginPage.png',
        title: '3x improvement in interview to offer rate',
        text: 'By assessing candidates on what matters up front, you’re more likely to have quality canidates at the end. Hiring teams using Applied find that they have 3x as many suitable candidates than before.',
    },
    {
        image: 'loginPage1.png',
        title: '66% reduction in time spent hiring',
        text: 'We make sure the hiring process is as fair and efficient as possible. Some of our customers have reported cutting the time they spend on recruiting by two thirds! That means you get to spend more time on what matters... like tea and cats.',
    },
    {
        image: 'loginPage2.png',
        title: '9/10 average candidate experience rating',
        text: 'Across over 500,000 applications, we’ve had a lot of feedback. We’re proud to say that employers and candidates alike love us.',
    },
    // the below image needs adding
    {
        image: 'loginPage2.png',
        title: '2-4x attraction and selection of ethnically diverse candidates',
        text: 'By removing the opportunity for biases to creep in, you’re free to consider candidates on merit alone. What’s more, when candidates realise that your process is fair, they’re more likely to through their hat into the ring. Win win!',
    },
]

export { loginQuotes, orgSignupQuotes }
