<template>
    <div :class="$style.feedbackWrapper">
        <p :class="$style.bodyText">"{{ text.trim() }}"</p>
        <p :class="$style.alignRight">{{ formattedTime }}</p>
        <p :class="$style.alignRight">Overall: {{ score }}/10</p>
    </div>
</template>

<script>
import { formatDistanceToNow, parseISO } from 'date-fns'

export default {
    name: 'FeedbackSection',
    props: {
        text: String,
        score: Number,
        createdAt: String,
    },
    computed: {
        formattedTime() {
            return formatDistanceToNow(parseISO(this.createdAt), {
                addSuffix: true,
            })
        },
    },
}
</script>

<style module src="./FeedbackSection.css" />
