/* eslint-disable vue-a11y/form-has-label */
<template>
    <transition name="slide-fade">
        <div :class="$style.container">
            <h3 :class="$style.headerText">Anything else you want to add?</h3>
            <div :class="$style.inputContainer">
                <!-- eslint-disable-next-line vue-a11y/form-has-label,vuejs-accessibility/form-control-has-label -->
                <textarea v-model="comment" :class="$style.inputField" required></textarea>
                <button
                    :class="$style.submitButton"
                    @click="emitComment"
                    v-on:keyup.enter="emitComment"
                    type="submit"
                >
                    Submit
                </button>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'CommentField',
    data() {
        return {
            comment: '',
        }
    },
    methods: {
        emitComment() {
            this.$emit('clicked', this.comment)
        },
    },
}
</script>

<style scoped>
.slide-fade-enter-active {
    transition: all 0.8s ease;
    transition-delay: 0.3s;
}
.slide-fade-enter {
    transform: translateY(25px);
    opacity: 0;
}
.slide-fade-leave-active {
    transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-leave-to {
    transform: translateY(25px);
    opacity: 0;
}
</style>

<style module src="./CommentField.css" />
