<template>
    <div :class="$style.wrapper">
        <div :class="$style.titleContainer">
            <h4 :class="$style.title">Candidate and hiring team experience</h4>
            <Tooltip :class="$style.tooltip" placement="right">
                <template slot="default">
                    <FontAwesomeIcon :class="$style.tooltipContent" :icon="questionIcon" />
                </template>
                <template slot="inner">
                    <p>
                        Gain insights into candidates and hiring team feedback provided throughout
                        the hiring process.
                    </p>
                </template>
            </Tooltip>
        </div>
        <div :class="$style.tableWrap" v-if="showSection">
            <VTabs id="demographicTabsContainer" v-model="tab">
                <VTab
                    v-for="category in tabNames"
                    :key="category.id"
                    :class="$style.tab"
                    :active-class="$style.tabActive"
                >
                    {{ category.label }}
                </VTab>
            </VTabs>
            <VTabsItems v-model="tab">
                <VTabItem
                    :transition="false"
                    :reverse-transition="false"
                    key="post-apply"
                    :class="$style.demographicContainer"
                >
                    <FeedbackTab
                        :detailedScores="fullPostApplyDetails.detailedScores"
                        :feedbacks="fullPostApplyDetails.data"
                        :averages="fullPostApplyDetails.stats.averages"
                        :mainScore="fullPostApplyDetails.bigScore"
                        :chartData="fullPostApplyDetails.chartData"
                        :tooltipText="tooltipText.postApply"
                        chartName="postApply"
                    />
                </VTabItem>
                <VTabItem
                    :transition="false"
                    :reverse-transition="false"
                    key="post-reject"
                    :class="$style.demographicContainer"
                >
                    <FeedbackTab
                        :feedbacks="fullPostFeedbackDetails.data"
                        :averages="fullPostFeedbackDetails.stats.averages"
                        :mainScore="fullPostFeedbackDetails.bigScore"
                        :chartData="fullPostFeedbackDetails.chartData"
                        :tooltipText="tooltipText.postReject"
                        chartName="postReject"
                    />
                </VTabItem>
                <VTabItem
                    :transition="false"
                    :reverse-transition="false"
                    key="post-review"
                    :class="$style.demographicContainer"
                >
                    <FeedbackTab
                        :feedbacks="fullPostReviewDetails.data"
                        :averages="fullPostReviewDetails.stats.averages"
                        :mainScore="fullPostReviewDetails.bigScore"
                        :chartData="fullPostReviewDetails.chartData"
                        chartName="postReview"
                        :tooltipText="tooltipText.postReview"
                        users="reviewers"
                    />
                </VTabItem>
            </VTabsItems>
        </div>
        <div v-else :class="$style.noData">
            <TipCard>
                <div :class="$style.emptyStateMessage" slot="headerMessage">
                    There is no feedback to show for this time period
                </div>
                <div slot="message"></div>
            </TipCard>
        </div>
    </div>
</template>

<script>
import { VTabs, VTab, VTabsItems, VTabItem } from 'vuetify/lib'
import { averageArrayOfCounts } from '@/utils/array'
import TipCard from '@/components/TipCard/TipCard'
import { faQuestionCircle } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { Tooltip } from '@applied/marmot'
import FeedbackTab from './FeedbackTab/FeedbackTab'
import calculateBigScore from './utils'

export default {
    name: 'ExperienceSection',
    components: {
        VTabs,
        VTab,
        VTabsItems,
        VTabItem,
        FeedbackTab,
        TipCard,
        FontAwesomeIcon,
        Tooltip,
    },
    props: {
        postApplyFeedback: { type: Object },
        postFeedbackFeedback: { type: Object },
        postReviewFeedback: { type: Object },
    },
    data() {
        return {
            tab: null,
            tabNames: [
                {
                    id: 'post-apply',
                    label: 'CANDIDATE - AFTER APPLYING',
                },
                {
                    id: 'post-reject',
                    label: 'CANDIDATE - AFTER REJECTION',
                },
                {
                    id: 'post-review',
                    label: 'HIRING TEAM - AFTER REVIEW',
                },
            ],
            tooltipText: {
                postApply:
                    'After candidates submit their applications, we ask them how fair was the process, how enjoyable was the process, and if they’re willing to recommend it to a collegue or friend. The number at the top is the overall average of the ratings given to these three questions.',
                postReject:
                    'Candidates will be able to share their experience after you’ve sent them the link with automated  feedback. At this stage, we ask them whether they’d recommend the process to a colleague or friend.',
                postReview:
                    'After hiring teams have finished the anonymous reviews, we ask them whether they’d recommend the process to a colleague of friend.',
            },
        }
    },
    methods: {
        calculateTabDetails(feedbackObject) {
            const dummiedFeedbackObject = feedbackObject || {
                stats: {
                    scores: {},
                    averages: {},
                },
            }
            return {
                bigScore: calculateBigScore(dummiedFeedbackObject.stats.scores),
                ...dummiedFeedbackObject,
                chartData: [
                    {
                        label: 'recommend',
                        data: dummiedFeedbackObject.stats.scores.rating,
                    },
                    {
                        label: 'fair',
                        data: dummiedFeedbackObject.stats.scores.fairness,
                    },
                    {
                        label: 'enjoyable',
                        data: dummiedFeedbackObject.stats.scores.satisfaction,
                    },
                ],
                detailedScores: [
                    {
                        name: 'Fair',
                        score: averageArrayOfCounts(
                            dummiedFeedbackObject.stats.scores.fairness || [],
                        ),
                    },
                    {
                        name: 'Enjoyable',
                        score: averageArrayOfCounts(
                            dummiedFeedbackObject.stats.scores.rating || [],
                        ),
                    },
                    {
                        name: 'Recommend',
                        score: averageArrayOfCounts(
                            dummiedFeedbackObject.stats.scores.satisfaction || [],
                        ),
                    },
                ],
            }
        },
    },
    computed: {
        questionIcon() {
            return faQuestionCircle
        },
        fullPostApplyDetails() {
            return this.calculateTabDetails(this.postApplyFeedback)
        },
        fullPostFeedbackDetails() {
            return this.calculateTabDetails(this.postFeedbackFeedback)
        },
        fullPostReviewDetails() {
            return this.calculateTabDetails(this.postReviewFeedback)
        },
        showSection() {
            return (
                (this.postApplyFeedback && this.postApplyFeedback.data.length > 0) ||
                (this.postFeedbackFeedback && this.postFeedbackFeedback.data.length > 0) ||
                (this.postReviewFeedback && this.postReviewFeedback.data.length > 0)
            )
        },
    },
}
</script>

<style module src="./ExperienceSection.css" />
