<template>
    <div>
        <div
            :class="{
                [$style.cardWrapper]: true,
                [$style.disabled]: submitted,
            }"
        >
            <a :href="getJobPath(job.id)" target="blank">
                <h3 :class="$style.title">
                    {{ job.title }}
                </h3>
            </a>
            <div :class="$style.iconRow">
                <div :class="$style.iconWrapper">
                    <div
                        tabindex="0"
                        :class="[$style.circleWrap, $style.hiredWrap]"
                        @keypress.enter="markHired(job.id, 'hired')"
                        @click="markHired(job.id, 'hired')"
                    >
                        <FontAwesomeIcon
                            :class="[$style.circleIcon, $style.hiredIcon]"
                            :icon="checkIcon"
                        />
                    </div>
                    <button
                        :class="[$style.cardTitle, $style.hiredButton]"
                        @keypress.enter="markHired(job.id, 'hired')"
                        @click="markHired(job.id, 'hired')"
                    >
                        Hired
                    </button>
                </div>
                <div :class="$style.iconWrapper">
                    <div
                        :class="[$style.circleWrap, $style.waitingWrap]"
                        tabindex="0"
                        @keypress.enter="updateRoleStatus(job.id, 'waiting')"
                        @click.enter="updateRoleStatus(job.id, 'waiting')"
                    >
                        <FontAwesomeIcon
                            :class="[$style.circleIcon, $style.waitingIcon]"
                            :icon="arrowIcon"
                        />
                    </div>
                    <button
                        :class="[$style.cardTitle, $style.waitingButton]"
                        @keypress.enter="updateRoleStatus(job.id, 'waiting')"
                        @click.enter="updateRoleStatus(job.id, 'waiting')"
                    >
                        In progress
                    </button>
                </div>
                <div :class="$style.iconWrapper">
                    <div
                        :class="[$style.circleWrap, $style.notHiredWrap]"
                        tabindex="0"
                        @keypress.enter="updateRoleStatus(job.id, 'notHired')"
                        @click.enter="updateRoleStatus(job.id, 'notHired')"
                    >
                        <FontAwesomeIcon
                            :class="[$style.circleIcon, $style.notHiredIcon]"
                            :icon="timesIcon"
                        />
                    </div>
                    <button
                        :class="[$style.cardTitle, $style.notHiredButton]"
                        @keypress.enter="updateRoleStatus(job.id, 'notHired')"
                        @click.enter="updateRoleStatus(job.id, 'notHired')"
                    >
                        Did not hire
                    </button>
                </div>
            </div>
        </div>
        <transition name="fade-up" mode="out-in">
            <div v-if="submitted" :class="[$style.toaster, $style.undoSection]">
                <div :class="$style.undoSectionInner">
                    <div :class="$style.undoMsg">The status of this role has been updated</div>
                    <button
                        :class="$style.undoButton"
                        @click="handleUndo(job.id)"
                        @keypress.enter="handleUndo(job.id)"
                    >
                        UNDO
                    </button>
                </div>
            </div>
        </transition>
        <transition name="fade-up" mode="out-in">
            <div v-if="errored" :class="[$style.toaster, $style.errorSection]">
                <div :class="$style.errorMsg">A problem occurred, please refresh the page</div>
            </div>
        </transition>
    </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faCheck, faArrowRight, faTimes } from '@fortawesome/pro-light-svg-icons'

export default {
    name: 'MarkHiredCard',
    props: {
        nextJob: { type: Function },
        job: { type: Object },
    },
    components: {
        FontAwesomeIcon,
    },
    data() {
        return {
            direction: 'fade-out',
            submitted: false,
            errored: false,
            timer: null,
        }
    },
    computed: {
        orgUser() {
            return this.$store.state.user.firstName
        },
        checkIcon() {
            return faCheck
        },
        arrowIcon() {
            return faArrowRight
        },
        timesIcon() {
            return faTimes
        },
    },
    methods: {
        updateRoleStatus(id, status) {
            this.submitted = true
            this.timer = window.setTimeout(() => {
                const data = {
                    id,
                    status,
                }
                this.$store
                    .dispatch('saveJobStatus', data)
                    .then(() => {
                        this.nextJob()
                        console.log('saved')
                    })
                    .catch((err) => {
                        this.errored = true
                        console.error('error', err)
                    })
            }, 2100)
        },
        markHired(id, status) {
            const data = {
                id,
                status,
            }
            this.$store
                .dispatch('saveJobStatus', data)
                .then(() => {
                    window.open(this.getJobPath(data.id), '_blank')
                    this.nextJob()
                })
                .catch((err) => {
                    this.errored = true
                    console.error('error', err)
                })
        },
        handleUndo() {
            window.clearTimeout(this.timer)
            this.submitted = false
        },
        getJobPath(id) {
            return `/manage/${id}?markHired`
        },
    },
}
</script>

<style module src="./MarkHiredCard.css" />

<style scoped>
.fade-up-enter-active {
    transition: all 0.4s ease;
}

.fade-up-leave-active {
    transition: all 0.4s;
}

.fade-up-enter {
    transform: translateY(30px);
    opacity: 0;
}

.fade-up-leave-to {
    transform: translateY(-30px);
    opacity: 0;
}
</style>
