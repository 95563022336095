<template>
    <div v-if="notificationHtml" :class="barStyle">
        <div :class="$style.barInner" v-html="notificationHtml"></div>
    </div>
</template>

<script>
import { differenceInDays, subDays } from 'date-fns'

export default {
    name: 'NotificationBar',
    props: {
        notification: Object,
    },
    computed: {
        notificationHtml() {
            switch (String(this.notification.type)) {
                case 'account-expiring':
                    return this.accountExpiringHtml()
                case 'approaching-role-cap':
                    return this.approachingRoleCapHtml()
                case 'auto-renewing-soon':
                    return this.autoRenewingSoonHtml()
                default:
                    return undefined
            }
        },
        barStyle() {
            return this.notification.type !== 'auto-renewing-soon'
                ? this.$style.yellowBarWrapper
                : this.$style.redBarWrapper
        },
        contactUsPrompt() {
            return (
                'Please contact the Customer Success Team via ' +
                '<a href="mailto:hello@beapplied.com">hello@beapplied.com</a>'
            )
        },
    },
    methods: {
        accountExpiringHtml() {
            const today = subDays(new Date(), 1)
            const expiresAt = new Date(this.notification.expiresAt)
            const daysRemaining = differenceInDays(expiresAt, today)
            const formattedDaysRemaining = Math.max(0, daysRemaining)

            const dayString = formattedDaysRemaining === 1 ? 'day' : 'days'

            return `You have ${formattedDaysRemaining} ${dayString} remaining on the Applied platform. ${this.contactUsPrompt}`
        },
        approachingRoleCapHtml() {
            return `You are at or approaching your role allowance for your current plan. ${this.contactUsPrompt}`
        },
        autoRenewingSoonHtml() {
            return `Your current plan is about to expire and will auto-renew. ${this.contactUsPrompt}`
        },
    },
}
</script>

<style module src="./NotificationBar.css" />
