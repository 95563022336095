module.exports = {
        gender: 'Gender',
        age: 'Age Range',
        ageRange: 'Age Range',
        ethnicity: 'Ethnicity',
        sexuality: 'Sexuality',
        disability: 'Disability',
        familyEducation: `Parents' Education`,
        familyWealth: 'Access to Free School Meals',
        religion: 'Religion',
        caste: 'Caste',
        sex: 'Sex',
        genderIdentityComparedToGenderAssignedAtBirth: 'Gender Identity',
        caringResponsibilities: 'Caring Responsibilities',
        married: 'Married',
        communityBackground: 'Community Background',
        veteranStatus: 'Veteran Status',
}