const sumFlatArray = (array) => {
    if (!array) {
        return undefined
    }
    return array.reduce((totalValue, currentValue) => totalValue + currentValue, 0)
}

const averageArrayOfCounts = (array) => {
    const countElements = sumFlatArray(array)
    const valueTotal = sumFlatArray(array.map((count, index) => count * (index + 1)))

    return countElements ? valueTotal / countElements : 0
}

export { sumFlatArray, averageArrayOfCounts }
