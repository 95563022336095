import { render, staticRenderFns } from "./ThanksMsg.vue?vue&type=template&id=f8724500&scoped=true&"
import script from "./ThanksMsg.vue?vue&type=script&lang=js&"
export * from "./ThanksMsg.vue?vue&type=script&lang=js&"
import style0 from "./ThanksMsg.vue?vue&type=style&index=0&id=f8724500&prod&scoped=true&lang=css&"
import style1 from "./ThanksMsg.css?vue&type=style&index=1&prod&module=true&lang=css&"




function injectStyles (context) {
  
  this["$style"] = (style1.locals || style1)

}

/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "f8724500",
  null
  
)

export default component.exports