var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
        [_vm.$style.pageWrap]: true,
        [_vm.$style.wideLayout]: _vm.wideLayout,
        [_vm.$style.noPaddingTop]: !_vm.hasPaddingTop,
        [_vm.$style.noPaddingBottom]: !_vm.hasPaddingBottom,
        [_vm.$style.flexColumn]: _vm.isFlexDirectionColumn,
    }},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }