<template>
    <transition name="slide">
        <div v-if="module != 'closeModule'" :class="$style.stickyContainer">
            <div :class="$style.closeContainer">
                <FontAwesomeIcon :icon="closeIcon" :class="$style.closeIcon" @click="closeModule" />
            </div>
            <StarRatingSelect v-if="module === 'StarRatingSelect'" @clicked="emitRating" />
            <CommentField v-if="module === 'CommentField'" @clicked="emitComment" />
            <ThanksMsg v-if="module === 'ThanksMsg'" />
        </div>
    </transition>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import StarRatingSelect from './StarRatingSelect/StarRatingSelect'
import CommentField from './CommentField/CommentField'
import ThanksMsg from './ThanksMsg/ThanksMsg'

export default {
    name: 'NPSModal',
    components: {
        FontAwesomeIcon,
        StarRatingSelect,
        CommentField,
        ThanksMsg,
    },
    data() {
        return {
            module: 'StarRatingSelect',
        }
    },
    computed: {
        closeIcon() {
            return faTimes
        },
    },
    methods: {
        emitRating(rating) {
            this.$store.dispatch('saveNPS', { rating })
            this.module = 'CommentField'
            this.$emit('submitted')
        },
        emitComment(text) {
            this.$store.dispatch('saveNPS', { text })
            this.module = 'ThanksMsg'
            this.$emit('submitted')
            setTimeout(this.closeModule, 4500)
        },
        closeModule() {
            this.$emit('closed')
            this.module = 'closeModule'
        },
    },
}
</script>

<style scoped>
.slide-leave-active {
    transition: height 0.6s ease-out;
}
.slide-leave-to {
    height: 0;
}
</style>
<style module src="./NPSModal.css" />
