import { forEachObjIndexed } from 'ramda'

const getAvailableActiveFilters = (availableFilters, activeFilters) => {
    const filtersToApply = {}

    const filterCategories = Object.fromEntries(
        availableFilters.map((filterCategory) => [filterCategory.id, filterCategory.items]),
    )

    const buildUniqueFilterItemsForFilterCategory = (filterCategoryItems, filterCategoryKey) => {
        const validFilters = activeFilters[filterCategoryKey]
            ? activeFilters[filterCategoryKey].filter((activeFilter) =>
                  filterCategoryItems.some(
                      (filterCategoryItem) => filterCategoryItem.value === activeFilter.value,
                  ),
              )
            : []

        if (validFilters.length) {
            filtersToApply[filterCategoryKey] = validFilters
        }
    }

    forEachObjIndexed(buildUniqueFilterItemsForFilterCategory, filterCategories)

    return filtersToApply
}

// eslint-disable-next-line import/prefer-default-export
export { getAvailableActiveFilters }
