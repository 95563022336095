var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
        [_vm.$style.tag]: true,
        [_vm.$style.grey]: _vm.color === 'grey',
        [_vm.$style.green]: _vm.color === 'green',
        [_vm.$style.yellow]: _vm.color === 'yellow',
        [_vm.$style.red]: _vm.color === 'red',
        [_vm.$style.blue]: _vm.color === 'blue',
        [_vm.$style.purple]: _vm.color === 'purple',
        [_vm.$style.disabled]: _vm.disabled || _vm.color === 'disabled',
    }},[_c('div',{class:_vm.$style.body},[(_vm.$slots.icon)?_c('div',{class:_vm.$style.icon},[_vm._t("icon")],2):_vm._e(),_vm._t("text",function(){return [_c('span',[_vm._v(_vm._s(_vm.text))])]})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }