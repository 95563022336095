<template>
    <div :class="$style.wrapper" :style="{ width: width, height: height }"></div>
</template>

<script>
export default {
    name: 'SkeletonJobCard',
    props: {
        width: String,
        height: {
            type: String,
            default: '20px',
        },
    },
}
</script>

<style module src="./SkeletonBlock.css" />
