<template>
    <transition name="slide-fade">
        <div :class="$style.container">
            <h3 :class="$style.headerText">Thanks for the feedback! :)</h3>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'ThanksMsg',
}
</script>

<style scoped>
.slide-fade-enter-active {
    transition: all 0.6s ease;
    transition-delay: 0.7s;
}
.slide-fade-enter {
    transform: translateY(25px);
    opacity: 0;
}
.slide-fade-leave {
    transition: all 0.3s ease-out;
    opacity: 0;
}
</style>

<style module src="./ThanksMsg.css" />
