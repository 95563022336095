<template>
    <div :class="$style.container">
        <TipCard>
            <div :class="$style.message" slot="headerMessage">
                {{ this.message }}
            </div>
            <div slot="message"></div>
        </TipCard>
    </div>
</template>

<script>
import TipCard from '@/components/TipCard/TipCard'

export default {
    name: 'EmptyStateCard',
    components: {
        TipCard,
    },
    props: {
        message: {
            default: 'You need 5+ applications to see data',
            type: String,
        },
    },
}
</script>

<style module src="./EmptyStateCard.css" />
