<template>
    <div
        :class="{
            [$style.container]: true,
            [$style.danger]: color === 'danger',
            [$style.warning]: color === 'warning',
            [$style.success]: color === 'success',
        }"
    >
        <p :class="$style.message">
            {{ message }}
        </p>
        <button v-if="canDismiss" @click="close" @keypress="close" :class="$style.buttonWrap">
            <FontAwesomeIcon aria-label="close" :icon="timesCircleIcon" :class="$style.closeIcon" />
        </button>
    </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

export default {
    name: 'Snackbar',
    components: {
        FontAwesomeIcon,
    },
    props: {
        color: {
            type: String,
            default: 'warning',
        },
        message: {
            type: String,
        },
        canDismiss: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        timesCircleIcon() {
            return faTimes
        },
    },
    methods: {
        close() {
            this.$emit('closeSnackbar')
        },
    },
}
</script>

<style module scoped src="./Snackbar.css" />
