const handleRedirect = (redirectDestination, router) => {
    if (redirectDestination.charAt(0) === '/') {
        window.location.href = redirectDestination
    } else {
        router.push({ path: '/dashboard' })
    }
}

export default {
    handleRedirect,
}
