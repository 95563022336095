module.exports = {
    'application-invite': {
        description: 'Sent to a candidate when invited through Applied',
        allowMultipleTemplates: false,
        prettyTitle: 'Application invite',
        subject: 'Link to your application to *|ORGNAME|*',
        emailText: `<p>Hi *|TOFIRSTNAME|*,</p>
        <p>Here's the link to the application page for the *|JOBTITLE|* at *|ORGNAME|*. There's a test, which you can complete in your own time:</p>
        <p>*|URL|*</p>
        <p>If you have any questions about your application, you can respond to this email directly!</p>
        <p>Best of luck!</p>
        <p>*|FROMFIRSTNAME|* *|FROMLASTNAME|*</p>
        <p>*|ORGNAME|*</p>
        `,
        trigger: 'manual',
        availableMergeTags: [
            {
                name: 'CandidateFirstName',
                tag: 'TOFIRSTNAME',
                required: false,
            },
            {
                name: 'JobTitle',
                tag: 'JOBTITLE',
                required: false,
            },
            {
                name: 'OrganisationName',
                tag: 'ORGNAME',
                required: false,
            },
            {
                name: 'ApplicationLink',
                tag: 'URL',
                required: true,
            },
            {
                name: 'AdminFirstName',
                tag: 'FROMFIRSTNAME',
                required: false,
            },
            {
                name: 'AdminLastName',
                tag: 'FROMLASTNAME',
                required: false,
            },
        ],
    },
    'application-start': {
        description: 'Sent to a candidate when they start their application',
        prettyTitle: 'Application start',
        allowMultipleTemplates: true,
        subject: 'Link to your application - *|ORGNAME|*',
        emailText: `<p>Hi there,</p>
        <p>Here's the link to your application for the *|JOBTITLE|* at *|ORGNAME|*, don't worry you don't have to finish it all at once:</p>
        <p>*|URL|*</p>
        <p>Please do not share this link with anyone as they will be able to access your application.</p>
        <p>If you have any questions about your application, you can respond to this email directly!</p>
        <p>What is Applied?</p>
        <p>Applied is a platform fixing hiring by giving you the opportunity to showcase your skills with the confidence that you won't be judged on things that don't matter like where you went to school, where you've worked, and if you look the part.</p>
        <p>*|ORGNAME|* will review the answers on your application to the *|JOBTITLE|* position without knowledge of who you are, where you're from or what you've done in the past. Each question you answer will be reviewed on its own without reference to any of the other questions you've answered.</p>
        `,
        trigger: 'automatic',
        allowRoleLevelOverride: true,
        availableMergeTags: [
            // {
            //     name: 'CandidateFirstName',
            //     tag: 'TOFIRSTNAME',
            //     required: false,
            // },
            {
                name: 'JobTitle',
                tag: 'JOBTITLE',
                required: false,
            },
            {
                name: 'OrganisationName',
                tag: 'ORGNAME',
                required: false,
            },
            {
                name: 'ApplicationLink',
                tag: 'URL',
                required: true,
            },
        ],
    },
    'application-confirm': {
        description: 'Sent to a candidate when they submit their application',
        prettyTitle: 'Application submit',
        subject: 'Thanks for applying to *|ORGNAME|*',
        allowMultipleTemplates: true,
        emailText: `<p>Hi *|TOFIRSTNAME|*,</p>
        <p>I just wanted to confirm that you've now completed your application for *|JOBTITLE|* at *|ORGNAME|*.</p>
        <p>Once all the applications are in, they'll be reviewed by the hiring team at *|ORGNAME|*, and then you'll be contacted to let you know whether you've been selected for the next phase.</p>
        <p>If you want to review your application at any point, the same link to your application will keep working.</p>
        <p>*|URL|*</p>
        <p>All the best,</p>
        <p>*|FROMFIRSTNAME|* *|FROMLASTNAME|*</p>
        <p>*|ORGNAME|*</p>
        `,
        trigger: 'automatic',
        allowRoleLevelOverride: true,
        availableMergeTags: [
            {
                name: 'CandidateFirstName',
                tag: 'TOFIRSTNAME',
                required: false,
            },
            {
                name: 'JobTitle',
                tag: 'JOBTITLE',
                required: false,
            },
            {
                name: 'OrganisationName',
                tag: 'ORGNAME',
                required: false,
            },
            {
                name: 'ApplicationLink',
                tag: 'URL',
                required: true,
            },
            {
                name: 'AdminFirstName',
                tag: 'FROMFIRSTNAME',
                required: false,
            },
            {
                name: 'AdminLastName',
                tag: 'FROMLASTNAME',
                required: false,
            },
        ],
    },
    'applicant-interview': {
        description: 'Sent when you invite a candidate for interview',
        prettyTitle: 'Invite candidates to interview',
        allowMultipleTemplates: true,
        subject: 'Your interview at *|ORGNAME|*',
        emailText: `<p>Dear *|TOFIRSTNAME|*</p>
        <p>We're very pleased to be able to invite you to interview for the *|JOBTITLE|* position at *|ORGNAME|*.</p>
        <p>Just click on the link below and register your preferred time. Interviews will be booked on a first come, first served basis.</p>
        <p>*|URL|*</p>
        <p>If you have a disability and have additional requirements with regards to the interview arrangements, please let me know as soon as possible.</p>
        <p>Thank you,</p>
        <p>*|FROMFIRSTNAME|* *|FROMLASTNAME|*</p>
        <p>*|ORGNAME|*</p>
        `,
        trigger: 'manual',
        allowRoleLevelOverride: true,
        availableMergeTags: [
            {
                name: 'CandidateFirstName',
                tag: 'TOFIRSTNAME',
                required: false,
            },
            {
                name: 'JobTitle',
                tag: 'JOBTITLE',
                required: false,
            },
            {
                name: 'OrganisationName',
                tag: 'ORGNAME',
                required: false,
            },
            {
                name: 'InterviewLink',
                tag: 'URL',
                required: true,
            },
            {
                name: 'AdminFirstName',
                tag: 'FROMFIRSTNAME',
                required: false,
            },
            {
                name: 'AdminLastName',
                tag: 'FROMLASTNAME',
                required: false,
            },
        ],
    },
    'applicant-rejection': {
        description: 'Sent when you reject a candidate',
        allowMultipleTemplates: true,
        prettyTitle: 'Rejection and feedback',
        subject: 'Your application to  *|ORGNAME|*',
        emailText: `<p>Dear *|TOFIRSTNAME|*</p>
        <p>Thank you for your application to the *|JOBTITLE|* position at *|ORGNAME|*.</p>
        <p>We appreciate your interest and application for this role. Unfortunately, your score was not high enough for you to proceed to the next stage, but we would like to thank you for your time and wish you every success in the future.</p>
        <p>To avoid any bias your application was blind-reviewed, piece by piece. The page below combines the scores from the team members who helped review each anonymous piece.</p>
        <p>*|URL|*</p>
        <p>Thank you,</p>
        <p>*|FROMFIRSTNAME|* *|FROMLASTNAME|*</p>
        <p>*|ORGNAME|*</p>
        `,
        trigger: 'manual',
        allowRoleLevelOverride: true,
        availableMergeTags: [
            {
                name: 'CandidateFirstName',
                tag: 'TOFIRSTNAME',
                required: false,
            },
            {
                name: 'JobTitle',
                tag: 'JOBTITLE',
                required: false,
            },
            {
                name: 'OrganisationName',
                tag: 'ORGNAME',
                required: false,
            },
            {
                name: 'FeedbackLink',
                tag: 'URL',
                required: false,
            },
            {
                name: 'AdminFirstName',
                tag: 'FROMFIRSTNAME',
                required: false,
            },
            {
                name: 'AdminLastName',
                tag: 'FROMLASTNAME',
                required: false,
            },
        ],
    },
    'past-application-invite': {
        description: 'Invite past candidates to apply to a role.',
        prettyTitle: 'Invite past candidates',
        allowMultipleTemplates: true,
        subject: 'Invitation from *|ORGNAME|* to apply for role: *|JOBTITLE|*',
        emailText: `<p>Hi *|TOFIRSTNAME|*,</p>
        <p>Re: *|JOBTITLE|*</p>
        <p>Your previous application to *|ORGNAME|* stood out and we've kept you in mind for future job openings.</p>
        <p>We are currently looking to hire a *|JOBTITLE|* and we believe your skills are a great match for this role.</p>
        <p>If you are interested in applying, please go to the following link to learn more and start your application:</p>
        <p>*|URL|*</p>
        <p>Look forward to hearing from you,</p>
        <p>*|FROMFIRSTNAME|* *|FROMLASTNAME|* at *|ORGNAME|*</p>
    `,
        trigger: 'manual',
        availableMergeTags: [
            {
                name: 'CandidateFirstName',
                tag: 'TOFIRSTNAME',
                required: false,
            },
            {
                name: 'JobTitle',
                tag: 'JOBTITLE',
                required: false,
            },
            {
                name: 'OrganisationName',
                tag: 'ORGNAME',
                required: false,
            },
            {
                name: 'ApplicationLink',
                tag: 'URL',
                required: true,
            },
            {
                name: 'JobAdminFirstName',
                tag: 'FROMFIRSTNAME',
                required: false,
            },
            {
                name: 'JobAdminLastName',
                tag: 'FROMLASTNAME',
                required: false,
            },
        ],
    },
    'quick-apply-invite': {
        description: 'Invite candidates to quick-apply to a role',
        prettyTitle: 'Invite candidates to quick-apply',
        allowMultipleTemplates: true,
        subject: 'Invitation from *|ORGNAME|* to apply for role: *|JOBTITLE|*',
        emailText: `<p>Hi *|TOFIRSTNAME|*,</p>
        <p>You have been invited to apply for the role of *|JOBTITLE|* at *|ORGNAME|* via our Quick-apply route.</p><br />
        <p>At *|ORGNAME|* we have partnered with Applied to de-bias our hiring. Ordinarily, candidates undergo an anonymous assessment as part of their application, but as a Quick-apply candidate, you qualify to by-pass this initial step.</p><br />
        <p>Follow the link below to complete your quick-apply application: </p>
        <p>*|URL|*</p><br />
        <p>Best wishes,</p>
        <p>*|FROMFIRSTNAME|*, *|ORGNAME|*</p><br />
        <p>This link was generated specifically for your application. Please do not share it with others.</p>
    `,
        trigger: 'manual',
        availableMergeTags: [
            {
                name: 'CandidateFirstName',
                tag: 'TOFIRSTNAME',
                required: true,
            },
            {
                name: 'JobTitle',
                tag: 'JOBTITLE',
                required: true,
            },
            {
                name: 'OrganisationName',
                tag: 'ORGNAME',
                required: true,
            },
            {
                name: 'ApplicationLink',
                tag: 'URL',
                required: true,
            },
            {
                name: 'JobAdminFirstName',
                tag: 'FROMFIRSTNAME',
                required: false,
            },
            {
                name: 'JobAdminLastName',
                tag: 'FROMLASTNAME',
                required: false,
            },
        ],
    },
    'assessment-stage-invite': {
        description: 'Invite candidates to the next assessment round',
        prettyTitle: 'Invite candidates to the next assessment round',
        allowMultipleTemplates: true,
        subject: 'Next stage of your application at *|ORGNAME|*',
        emailText: `<p>Dear *|TOFIRSTNAME|*,</p>
        <p>We're very pleased to invite you to the next stage of the hiring process for the *|JOBTITLE|* position at *|ORGNAME|*.</p><br />
        <p>For this stage, please use the link below to answer the written question(s). </p><br />
        <p>You must submit your answer(s) before *|DUEDATE|*. After this due date, you will still have access to your application link, but you won’t be able to submit any answers.</p>
        <p>*|URL|*</p><br />
        <p>After you submit your answers, our team will anonymously review them. This is to mitigate bias and ensure that the process is fair.</p><br />
        <p>If you have any questions or requirements about this task, please let us know.</p>
        <p>Best of luck,</p>
        <p>*|FROMFIRSTNAME|* *|FROMLASTNAME|*</p><br />
        <p>*|ORGNAME|*</p>
    `,
        trigger: 'manual',
        allowRoleLevelOverride: true,
        availableMergeTags: [
            {
                name: 'CandidateFirstName',
                tag: 'TOFIRSTNAME',
                required: true,
            },
            {
                name: 'JobTitle',
                tag: 'JOBTITLE',
                required: true,
            },
            {
                name: 'OrganisationName',
                tag: 'ORGNAME',
                required: true,
            },
            {
                name: 'ApplicationLink',
                tag: 'URL',
                required: true,
            },
            {
                name: 'DueDate',
                tag: 'DUEDATE',
                required: false,
            },
            {
                name: 'JobAdminFirstName',
                tag: 'FROMFIRSTNAME',
                required: false,
            },
            {
                name: 'JobAdminLastName',
                tag: 'FROMLASTNAME',
                required: false,
            },
        ],
    },
    other: {
        description:
            'General communication with candidates throughout the hiring process',
        allowMultipleTemplates: true,
        prettyTitle: 'Other',
        noDefaultTemplate: true,
        subject: '',
        emailText: '',
        trigger: 'manual',
        allowRoleLevelOverride: true,
        availableMergeTags: [
            {
                name: 'CandidateFirstName',
                tag: 'TOFIRSTNAME',
                required: false,
            },
            {
                name: 'JobTitle',
                tag: 'JOBTITLE',
                required: false,
            },
            {
                name: 'OrganisationName',
                tag: 'ORGNAME',
                required: false,
            },
            {
                name: 'AdminFirstName',
                tag: 'FROMFIRSTNAME',
                required: false,
            },
            {
                name: 'AdminLastName',
                tag: 'FROMLASTNAME',
                required: false,
            },
            {
                name: 'FeedbackLink',
                tag: 'FEEDBACKURL',
                required: false,
            },
            {
                name: 'ApplicationLink',
                tag: 'APPLICATIONURL',
                required: false,
            },
        ],
    },
}
