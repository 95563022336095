<template>
    <div :class="$style.vSpinner" v-show="loading">
        <div :class="$style.vClip" v-bind:style="spinnerStyle"></div>
    </div>
</template>

<script>
export default {
    name: 'Spinner',

    props: {
        loading: {
            type: Boolean,
            default: true,
        },
        color: {
            type: String,
            default: '#fff',
        },
        size: {
            type: String,
            default: '12px',
        },
        radius: {
            type: String,
            default: '100%',
        },
    },
    computed: {
        spinnerStyle() {
            return {
                height: this.size,
                width: this.size,
                borderWidth: '3px',
                borderStyle: 'solid',
                borderColor: `${this.color} ${this.color} transparent`,
                borderRadius: this.radius,
                background: 'transparent',
            }
        },
    },
}
</script>

<style module src="./Spinner.css" />
