import Vue from 'vue'
import Vue2TouchEvents from 'vue2-touch-events'
import 'normalize.css'
import App from './App'
import router from './router'
import store from './store'
import auth from './utils/auth'
import vuetify from './plugins/vuetify'

Vue.use(Vue2TouchEvents)

Vue.config.productionTip = false

/* eslint-disable no-new */
new Vue({
    vuetify,
    router,
    store,
    components: { App },
    render: (h) => h(App),
    created() {
        this.$store.dispatch('getGoogleAuthURL')

        auth.isUserLoggedIn()
            .then((response) => {
                if (response.orgDeactivated) {
                    return window.location.replace('/expired')
                }
                this.$store.commit('loadFeatures', this.$route.query.features || '[]')
                // fetch the user so isLoggedIn endpoint still works after login
                // eslint-disable-next-line consistent-return
                return (
                    this.fetchData()
                        // eslint-disable-next-line consistent-return
                        .then(() => {
                            this.addHeap()
                            this.appcuesIdentify(this.user)
                        })
                        .catch((error) => {
                            console.log(error)
                            return this.pushToLogin()
                        })
                )
            })
            .catch(() => {
                this.pushToLogin()
            })
    },
    watch: {
        /* eslint-disable vue/no-arrow-functions-in-watch */
        $route: () => {
            if (window.Appcues) {
                window.Appcues.page()
            }
        },
    },
    methods: {
        pushToLogin() {
            // route.path is returning '/' on component reload so temporarily using hash
            const { hash } = window.location

            if (
                !hash.match(/^#\/(login)\/?$/) &&
                !hash.match(/^#\/\w+\/reset-password\/?$/) &&
                !hash.match(/^#\/\w+\/create-password/) &&
                !hash === '#/text-analysis-signup' &&
                !hash === '#/org-signup' &&
                !hash === '#/org-signup-new' &&
                !hash === '#/org-signup/template-list' &&
                !hash.match(/^#\/\w+\/view-template/)
            ) {
                router.push('login')
            }
        },
        fetchData() {
            return this.$store.dispatch('fetchBasicData').catch(() => {
                window.location.href = '/'
            })
        },
        appcuesIdentify(user) {
            if (window.Appcues) {
                window.Appcues.identify(user.id, {
                    firstName: user.firstName,
                    lastName: user.lastName,
                    OrgId: user.OrgId,
                    createdAt: user.createdAt,
                })
            }
        },
        addHeap() {
            const { user } = this.$store.state

            if (user) {
                const userOrg = user.Org

                window.heap.identify(user.id)
                window.heap.addUserProperties({
                    orgId: user.OrgId,
                    orgIndustry: userOrg ? user.Org.industry : '',
                    orgName: userOrg ? user.Org.name : '',
                })

                if (userOrg.OrgTiers && userOrg.OrgTiers[0].Tier) {
                    window.heap.addUserProperties({
                        orgTier: userOrg.OrgTiers[0].Tier.code,
                    })
                }
            }
        },
    },
    computed: {
        user() {
            return this.$store.state.user
        },
    },
}).$mount('#app')

if (process.env.NODE_ENV !== 'production') {
    /* eslint-disable-next-line */
    const VueAxe = require('vue-axe')
    Vue.use(VueAxe, {
        clearConsoleOnUpdate: false,
    })
}
