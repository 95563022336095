<template>
    <li :class="$style.wrapper">
        <div :class="[$style.checkBox, $style.animate]"></div>
        <div :class="[$style.comment, $style.animate]"></div>
    </li>
</template>

<script>
export default {
    name: 'LoadingChecklistItem',
}
</script>

<style module src="./LoadingChecklistItem.css" />
