<template>
    <div :class="$style.starWrapper">
        <FontAwesomeIcon
            :icon="starIcon"
            :class="$style.faIcon"
            v-for="(item, index) in value"
            :key="`filled-${index}`"
            filled="true"
        />
        <FontAwesomeIcon
            :icon="emptyStarIcon"
            :class="$style.faIcon"
            v-for="(item, index) in emptyCount"
            :key="`empty-${index}`"
            filled="false"
        />
    </div>
</template>

<script>
import { faStar } from '@fortawesome/fontawesome-free-solid'
import { faStar as faStarOutline } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
    name: 'SimpleStarRating',
    props: {
        total: Number,
        value: Number,
    },
    components: {
        FontAwesomeIcon,
    },
    computed: {
        emptyStarIcon() {
            return faStarOutline
        },
        starIcon() {
            return faStar
        },
        emptyCount() {
            return this.total - this.value
        },
    },
}
</script>

<style module src="./SimpleStarRating.css" />
