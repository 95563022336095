<template>
    <div :class="$style.card">
        <div :class="$style.titleRow">
            <FontAwesomeIcon :icon="lightbulb" :class="$style.lightbulb" />
            <h2 v-if="title">{{ title }}</h2>
            <slot name="headerMessage"></slot>
        </div>
        <div :class="$style.cardContent">
            <slot name="message">Here's a tip</slot>
        </div>
    </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faLightbulbOn } from '@fortawesome/pro-light-svg-icons'

export default {
    name: 'TipCard',
    components: {
        FontAwesomeIcon,
    },
    props: {
        title: {
            type: String,
            default: null,
        },
    },
    computed: {
        lightbulb() {
            return faLightbulbOn
        },
    },
}
</script>

<style module src="./TipCard.css" />
