<template>
    <div :class="$style.starWrapper">
        <FontAwesomeIcon
            :icon="starIcon"
            :class="$style.faIcon"
            v-for="index in roundedValue"
            :key="`${index}_filled`"
            filled="true"
        />
        <FontAwesomeIcon
            :icon="emptyStarIcon"
            :class="[$style.faIcon, $style.faEmpty]"
            v-for="index in emptyCount"
            :key="`${index}_empty`"
            filled="false"
        />
    </div>
</template>

<script>
import { faStar } from '@fortawesome/fontawesome-free-solid'
import { faStar as faStarOutline } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
    name: 'StarRating',
    props: {
        total: {
            type: Number,
            default: 10,
        },
        value: Number,
    },
    components: {
        FontAwesomeIcon,
    },
    computed: {
        roundedValue() {
            return Math.round(this.value)
        },
        emptyStarIcon() {
            return faStarOutline
        },
        starIcon() {
            return faStar
        },
        emptyCount() {
            return this.total - this.roundedValue
        },
    },
}
</script>

<style module src="./StarRating.css" />
