<template>
    <div :class="$style.notificationBlob">
        {{ notificationCount }}
    </div>
</template>

<script>
export default {
    name: 'NotificationBlob',
    props: {
        notificationCount: {
            type: Number,
            required: true,
        },
    },
};
</script>

<style module src="./NotificationBlob.css" />