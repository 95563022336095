<template>
    <section :class="$style.filterSection">
        <template v-if="items.length > 0">
            <p :class="$style.dropdownSectionHeader">
                {{ title }}
            </p>
            <div v-for="item in items" :key="item.name" :class="$style.skillRow">
                <input
                    type="checkbox"
                    :id="checkboxId(item.value)"
                    :value="item.value"
                    v-model="checked[item.value]"
                    @change="checkboxChanged(item.value, $event.target.checked)"
                />
                <!-- eslint-disable-next-line vuejs-accessibility/label-has-for -->
                <label :class="$style.label" :for="checkboxId(item.value)">{{ item.name }}</label>
            </div>
        </template>
        <template v-else-if="emptyStateText">
            <p>{{ emptyStateText }}</p>
        </template>
    </section>
</template>

<script>
export default {
    name: 'FilterSection',
    props: {
        template: Object,
        state: Array,
        emptyStateText: String,
    },
    data() {
        return {
            checked: {},
        }
    },
    watch: {
        state: {
            immediate: true,
            handler(newState, oldState) {
                if (newState === undefined && oldState === undefined) {
                    return
                }

                const newChecked = {}

                if (newState === undefined) {
                    oldState.forEach((item) => {
                        newChecked[item.value] = false
                    })
                    this.checked = newChecked
                    return
                }

                newState.forEach((item) => {
                    newChecked[item.value] = true
                })
                this.checked = newChecked
            },
        },
    },
    methods: {
        checkboxChanged(filter, newState) {
            if (this.template.type === 'exclusive' && newState) {
                Object.keys(this.checked).forEach((key) => {
                    this.checked[key] = false
                })
                this.checked[filter] = newState
            }
            this.$emit('filter-changed', {
                id: this.template.id,
                active: this.filterValue,
            })
        },
        checkboxId(value) {
            return `filterCheckbox${value}`
        },
    },
    computed: {
        title() {
            return this.template.title
        },
        items() {
            return this.template.items
        },
        filterValue() {
            return this.template.items
                .filter((item) => this.checked[item.value])
                .map((item) => item)
        },
    },
}
</script>

<style module src="./FilterSection.css" />
