<template>
    <div :class="$style.container">
        {{ percentage }}%
        <div :class="$style.wrapper">
            <span :style="barStyle"></span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ProgressBar',
    props: {
        percentage: Number,
    },
    computed: {
        barStyle() {
            return `width: ${this.percentage}%;`
        },
    },
}
</script>

<style module src="./ProgressBar.css" />
