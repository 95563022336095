<template>
    <div>
        <div
            :class="[$style.modalMask, $style.desktopOnly]"
            v-if="headerMenuVisibility"
            @click="toggleHeaderMenu"
            @keyup.enter="toggleHeaderMenu"
        ></div>
        <div :class="[$style.headerWrapper, $style.topBar]">
            <div :class="$style.header">
                <div :class="$style.leftColumn">
                    <a href="/" :class="$style.logo">
                        <img
                            alt="Applied logo: redirects to dashboard"
                            :class="$style.appliedLogo"
                            src="@/assets/applied_logo_master_white.svg"
                    /></a>
                    <div :class="[$style.infoBox, $style.desktopOnly]" v-if="user.Org && false">
                        <p :class="$style.infoText">
                            <span :class="$style.bold">
                                {{ `${user.Org.OrgTiers[0].Tier.title} plan` }}
                            </span>
                        </p>
                        <p :class="$style.roleCallout">
                            You have
                            <span :class="[$style.highlight, $style.bold]">
                                {{ tierRoleCount }}
                            </span>
                            available active roles
                        </p>
                    </div>
                </div>
                <div
                    :class="[$style.middleColumn, $style.desktopOnly]"
                    v-if="
                        this.$route.path.startsWith('/dashboard') ||
                        this.$route.path.startsWith('/search')
                    "
                >
                    <div v-if="isAdmin" :class="$style.searchBox">
                        <FontAwesomeIcon
                            :icon="searchIcon"
                            :class="$style.searchIcon"
                            @click="search"
                        />
                        <TextInput
                            type="text"
                            v-model="searchQuery"
                            placeholder="Search candidates by name or email"
                            :class="$style.searchInput"
                            @entered="search"
                            id="searchBar"
                            ariaLabel="Search candidates by name or email"
                        />
                    </div>
                </div>
                <div :class="$style.rightColumn">
                    <transition name="slide-fade-up">
                        <div :class="$style.userRow" v-if="user && user.id">
                            <div
                                :class="[$style.userSection, $style.clicky, $style.desktopOnly]"
                                @click="toggleHeaderMenu"
                                @keyup.enter="toggleHeaderMenu"
                            >
                                <div
                                    :class="$style.userImage"
                                    v-if="user.firstName"
                                    heap-ignore="true"
                                >
                                    <UserImage
                                        v-bind:userInitials="
                                            user.firstName[0].toUpperCase() +
                                            user.lastName[0].toUpperCase()
                                        "
                                        size="small"
                                        v-bind:userPhoto="user.photo"
                                        v-bind:userId="user.id"
                                    />
                                </div>
                                <div :class="$style.userDetails" v-if="user.firstName">
                                    <p :class="$style.userName" heap-ignore="true">
                                        {{ user.firstName }} {{ user.lastName }}
                                    </p>
                                    <p :class="$style.orgName">
                                        {{ truncatedOrgName }}
                                    </p>
                                </div>
                                <DotMenu color="primary" :class="$style.menuSection" />
                                <div v-if="headerMenuVisibility">
                                    <HeaderMenu />
                                </div>
                            </div>
                            <div :class="$style.showMobile">
                                <div
                                    :class="$style.mobileMenuContainer"
                                    @click="toggleHeaderMenu"
                                    @keyup.enter="toggleHeaderMenu"
                                >
                                    <FontAwesomeIcon
                                        v-if="!headerMenuVisibility"
                                        aria-label="openMenu"
                                        :icon="barsIcon"
                                        :class="$style.menuIcons"
                                    />
                                    <FontAwesomeIcon
                                        v-else
                                        aria-label="close"
                                        :icon="closeIcon"
                                        :class="$style.menuIcons"
                                    />
                                </div>
                            </div>
                        </div>
                    </transition>
                </div>
            </div>
        </div>
        <transition name="slide">
            <div
                id="slidemenu"
                v-if="headerMenuVisibility"
                :class="[$style.headerWrapper, $style.showMobile]"
            >
                <div :class="$style.mobileMenuBar">
                    <div :class="$style.mobilelinks">
                        <div :class="$style.mobileMenuItemWrap">
                            <div
                                :class="[$style.menuItemWrap, $style.mobileMenuItem]"
                                @click="personalDashboardRedirect"
                                @keyup.enter="personalDashboardRedirect"
                            >
                                <div>My Tasks</div>
                            </div>
                        </div>
                        <div :class="$style.mobileMenuItemWrap">
                            <div
                                :class="[$style.menuItemWrap, $style.mobileMenuItem]"
                                @click="dashboardRedirect"
                                @keyup.enter="dashboardRedirect"
                            >
                                <div>Dashboard</div>
                            </div>
                        </div>
                        <div
                            :class="{
                                [$style.mobileMenuItemWrap]: true,
                                [$style.mobileMenuItemWrapActive]: this.$route.path === '/profile',
                            }"
                        >
                            <router-link to="/settings/profile" :class="$style.noUnderline">
                                <div
                                    :class="{
                                        [$style.mobileMenuItem]: true,
                                        [$style.menuItemWrap]: true,
                                        [$style.menuItemWrapActive]:
                                            this.$route.path === '/settings/profile',
                                    }"
                                >
                                    <div
                                        :class="{
                                            [$style.noUnderline]: true,
                                            [$style.mobileMenuItem]: true,
                                            [$style.menuItemActive]:
                                                this.$route.path === '/settings/profile',
                                        }"
                                    >
                                        Settings
                                    </div>
                                </div>
                            </router-link>
                        </div>
                        <div :class="$style.mobileMenuItemWrap">
                            <div
                                :class="[$style.menuItemWrap, $style.mobileMenuItem]"
                                @click="logout"
                                @keyup.enter="logout"
                            >
                                <div>Logout</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
        <div :class="$style.navbarHeader">
            <div :class="$style.navbar" id="navbar">
                <router-link
                    to="/dashboard"
                    :class="$style.navbarItem"
                    :active-class="$style.navbarItemActive"
                >
                    Dashboard
                </router-link>
                <router-link
                    :class="$style.navbarItem"
                    :active-class="$style.navbarItemActive"
                    to="/user-dashboard"
                    replace
                >
                    My Tasks
                </router-link>
                <router-link
                    :class="$style.navbarItem"
                    :active-class="$style.navbarItemActive"
                    to="/org-stats"
                    v-if="isAdmin"
                    >Global Reporting
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faSearch, faBars } from '@fortawesome/fontawesome-free-solid'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { TextInput } from '@applied/marmot'
import DotMenu from '../DotMenu/DotMenu'
import UserImage from '../UserImage/UserImage'
import HeaderMenu from './HeaderMenu/HeaderMenu'
import directives from '../../directives'
import config from '../../config/tier'

export default {
    name: 'Header',
    components: {
        DotMenu,
        UserImage,
        HeaderMenu,
        FontAwesomeIcon,
        TextInput,
    },
    data() {
        return {
            headerMenuVisibility: false,
            mobileMenuOpen: false,
            searchQuery: '',
        }
    },
    directives: {
        clickOutside: directives.clickOutside,
    },
    computed: {
        user() {
            return this.$store.state.user ? this.$store.state.user : {}
        },
        isAdmin() {
            return this.user.role === 'ADMIN'
        },
        tierRoleCount() {
            let count = null
            if (this.$store.state.user.Org) {
                count =
                    config.open_roles[
                        this.$store.state.user.Org.OrgTiers[0].Tier.title.toLowerCase()
                    ] === Infinity
                        ? 'unlimited'
                        : config.open_roles[
                              this.$store.state.user.Org.OrgTiers[0].Tier.title.toLowerCase()
                          ]
            }
            return count
        },
        searchIcon() {
            return faSearch
        },
        closeIcon() {
            return faTimes
        },
        barsIcon() {
            return faBars
        },
        truncatedOrgName() {
            return this.user.Org.name.split('').length > 30
                ? `${this.user.Org.name.trim().slice(0, 30)}...`
                : this.user.Org.name
        },
    },
    methods: {
        search() {
            if (this.searchQuery === '') return
            this.$store.dispatch('searchOrg', { searchQuery: this.searchQuery })
            this.$router.push('search')
        },
        toggleHeaderMenu() {
            this.headerMenuVisibility = !this.headerMenuVisibility
        },
        logout() {
            this.$store.dispatch('logout')
            window.heap.resetIdentity()
        },
        dashboardRedirect() {
            this.$router.push('/dashboard')
            this.toggleHeaderMenu()
        },
        personalDashboardRedirect() {
            this.$router.push('/user-dashboard')
            this.toggleHeaderMenu()
        },
    },
}
</script>

<style module src="./Header.css" />

<style scoped>
#slidemenu {
    position: absolute;
    width: 100%;
    transform-origin: top;
    transition: transform 0.3s ease-in-out;
    overflow: hidden;
}
.slide-enter,
.slide-leave-to {
    transform: scaleY(0);
}
.slide-fade-up-enter-active {
    transition: all 0.5s ease;
}
.slide-fade-up-enter {
    transform: translateY(10px);
    opacity: 0;
}
.slide-fade-up-leave-active {
    transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-up-leave-to {
    transform: translateY(10px);
    opacity: 0;
}
</style>
