<template>
    <div>
        <PasswordResetModal v-if="passwordResetModalVisibility" @close="togglePasswordResetModal" />
        <TOTPModal
            v-if="totpModalVisibility"
            @close="toggleTOTPModal"
            v-bind="{
                svg,
                uri,
                emailAddress,
                password,
                userSecret,
            }"
        />
        <div :class="$style.loginPageWrap">
            <div :class="$style.background">
                <div :class="$style.backgroundLogo1" />
                <div :class="$style.backgroundLogo2" />
            </div>
            <div :class="$style.pullRight">
                <a href="/" :class="$style.logo">
                    <img
                        alt="Applied logo: redirects to dashboard"
                        :class="$style.appliedLogo"
                        src="@/assets/applied_logo_small_reverse.png"
                /></a>
            </div>
            <div :class="$style.centerpiece">
                <div :class="$style.quoteContainer">
                    <Quote :quote="quote" :class="$style.quote" />
                </div>
                <div :class="$style.formContainer">
                    <form :class="$style.form">
                        <div :class="$style.flexColumn">
                            <p :class="$style.formTitle">Email address</p>
                            <div :class="$style.inputFieldWrapper">
                                <TextInput
                                    v-model="emailAddress"
                                    ariaLabel="Email address"
                                    type="email"
                                    autocomplete="email"
                                    id="email"
                                />
                            </div>
                        </div>
                        <div :class="$style.flexColumn">
                            <p :class="$style.formTitle">Password</p>
                            <div :class="[$style.inputFieldWrapper, $style.noBottom]">
                                <TextInput
                                    v-model="password"
                                    @entered="handleLogin"
                                    ariaLabel="Password"
                                    type="password"
                                    autocomplete="current-password"
                                    id="password"
                                />
                            </div>
                        </div>
                        <div :class="$style.flexColumn">
                            <Button
                                style="padding: 0"
                                color="primaryText"
                                @clicked="togglePasswordResetModal"
                                ><span slot="buttonText" :class="$style.forceUnderline"
                                    >Forgot password?</span
                                ></Button
                            >
                        </div>
                        <div :class="$style.buttonRow">
                            <div>
                                <a :href="googleAuthURL">
                                    <img
                                        alt="Login with Google"
                                        src="@/assets/btn_google_signin_dark_normal_web@2x.png"
                                        :class="$style.googleButton"
                                    />
                                </a>
                            </div>
                            <Button
                                color="primary"
                                @clicked="handleLogin"
                                :disabled="!isFormValid"
                                :pending="loggingIn"
                                ><span slot="buttonText">Log in</span></Button
                            >
                        </div>
                        <div v-if="loginErrorMessage" :class="$style.alertRow">
                            <Alert
                                :class="$style.alert"
                                :message="loginErrorMessage"
                                color="danger"
                            />
                        </div>
                        <div v-if="queryErrors === 'userError'" :class="$style.alertRow">
                            <Alert
                                message="Sorry, it looks like you don’t have an account with Applied,
                                please reach out to your account admin to get added to this account.
                                If you are a candidate there is no need to log-in, you can find the
                                link to your application in your email"
                                color="danger"
                            />
                        </div>
                        <div v-if="queryErrors === 'unknownError'" :class="$style.alertRow">
                            <Alert
                                message="An unknown error occurred.
                             Please try again and if this problem persists please contact us at hello@beapplied.com"
                                color="danger"
                            />
                        </div>
                        <div :class="$style.buttonRow">
                            <CandidateNote />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Button, Alert, TextInput } from '@applied/marmot'
import { loginQuotes } from '@/config/quotes'
import utils from './utils'
import PasswordResetModal from './PasswordResetModal/PasswordResetModal'
import TOTPModal from './TOTPModal/TOTPModal'
import Quote from './Quote/Quote'
import CandidateNote from './CandidateNote/CandidateNote'

export default {
    name: 'Login',
    components: {
        TextInput,
        Button,
        PasswordResetModal,
        TOTPModal,
        Alert,
        Quote,
        CandidateNote,
    },
    data() {
        return {
            emailAddress: '',
            password: '',
            passwordResetModalVisibility: false,
            totpModalVisibility: false,
            isFormValid: false,
            loginErrorMessage: '',
            svg: null,
            uri: null,
            userSecret: null,
        }
    },
    watch: {
        emailAddress() {
            this.resetForm()
        },
        password() {
            this.resetForm()
        },
    },
    computed: {
        loggingIn() {
            return this.$store.state.pending.login
        },
        quote() {
            const entryNumber = Math.floor(Math.random() * loginQuotes.length)
            return loginQuotes[entryNumber]
        },
        googleAuthURL() {
            return this.$store.state.googleAuthURL
        },
        queryErrors() {
            return this.$router.currentRoute.query.loginError
        },
        redirectDestination() {
            return this.$store.state.redirectUrl
        },
    },
    methods: {
        resetForm() {
            this.loginErrorMessage = ''
            this.isFormValid = this.validateForm()
        },
        isValidRedirect(url) {
            return url.charAt(0) === '/'
        },
        validateForm() {
            return this.emailAddress.length > 0 && this.password.length > 0
        },
        togglePasswordResetModal() {
            this.passwordResetModalVisibility = !this.passwordResetModalVisibility
        },
        toggleTOTPModal() {
            this.totpModalVisibility = !this.totpModalVisibility
        },
        handleLogin() {
            return this.$store
                .dispatch('login', {
                    email: this.emailAddress,
                    password: this.password,
                })
                .then((textRedirect) => {
                    if (
                        textRedirect &&
                        (textRedirect.message === 'totp' || textRedirect.message === 'needs totp')
                    ) {
                        this.svg = textRedirect.svg
                        this.uri = textRedirect.uri
                        this.userSecret = textRedirect.userSecret
                        this.toggleTOTPModal()
                        return
                    }
                    if (textRedirect) {
                        window.location.href = textRedirect
                        return
                    }

                    this.$store.dispatch('fetchBasicData')

                    if (this.redirectDestination) {
                        utils.handleRedirect(this.redirectDestination, this.$router)
                    } else {
                        this.$router.push({ path: '/dashboard' })
                    }
                })
                .catch((err) => {
                    const statusCode = err.response && err.response.status
                    this.loginErrorMessage =
                        statusCode === 403
                            ? 'Your account has been deactivated, please contact your account administrator'
                            : 'Invalid email address or password'
                })
        },
    },
}
</script>

<style module src="./Login.css" />
