var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"modal"}},[_c('div',[_c('div',{class:_vm.$style.popOver},[_c('div',{class:_vm.$style.popOverInner},[_c('router-link',{class:_vm.$style.noUnderline,attrs:{"to":"/dashboard"}},[_c('div',{class:{
                            [_vm.$style.menuItemWrap]: true,
                            [_vm.$style.menuItemWrapActive]: this.$route.path === '/dashboard',
                        }},[_c('div',{class:{
                                [_vm.$style.menuItem]: true,
                                [_vm.$style.menuItemActive]: this.$route.path === '/dashboard',
                            }},[_vm._v(" Dashboard ")])])]),_c('router-link',{class:_vm.$style.noUnderline,attrs:{"to":"/user-dashboard"}},[_c('div',{class:{
                            [_vm.$style.menuItemWrap]: true,
                            [_vm.$style.menuItemWrapActive]: this.$route.path === '/user-dashboard',
                        }},[_c('div',{class:{
                                [_vm.$style.menuItem]: true,
                                [_vm.$style.menuItemActive]: this.$route.path === '/user-dashboard',
                            }},[_vm._v(" My Tasks ")])])]),(_vm.isAdmin || _vm.isJobAdmin)?_c('router-link',{class:_vm.$style.noUnderline,attrs:{"to":"/email-templates"}},[_c('div',{class:{
                            [_vm.$style.menuItemWrap]: true,
                            [_vm.$style.menuItemWrapActive]:
                                this.$route.path === '/email-templates',
                        }},[_c('div',{class:{
                                [_vm.$style.menuItem]: true,
                                [_vm.$style.menuItemActive]:
                                    this.$route.path === '/email-templates',
                            }},[_vm._v(" Email templates ")])])]):_vm._e(),_c('router-link',{class:{
                        [_vm.$style.noUnderline]: true,
                        [_vm.$style.disabled]: !_vm.hasLibraryAccess,
                    },attrs:{"to":"/question-library","event":_vm.hasLibraryAccess ? 'click' : ''}},[_c('div',{class:{
                            [_vm.$style.menuItemWrap]: true,
                            [_vm.$style.menuItemWrapActive]:
                                this.$route.path === '/question-library',
                        }},[_c('div',{class:{
                                [_vm.$style.menuItem]: true,
                                [_vm.$style.menuItemActive]:
                                    this.$route.path === '/question-library',
                            }},[_vm._v(" Question library ")])])]),(_vm.isAdmin)?_c('div',{class:_vm.$style.menuItemWrap,on:{"click":_vm.orgStatsRedirect,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.orgStatsRedirect.apply(null, arguments)}}},[_c('div',{class:_vm.$style.menuItem},[_vm._v("Global reporting")])]):_vm._e(),_c('router-link',{class:_vm.$style.noUnderline,attrs:{"to":_vm.showSettingsNotification ? '/settings/account' : '/settings/profile'}},[_c('div',{class:{
                            [_vm.$style.menuItemWrap]: true,
                            [_vm.$style.menuItemWrapActive]:
                                this.$route.path === '/settings/profile',
                        }},[_c('div',{class:{
                                [_vm.$style.menuItem]: true,
                                [_vm.$style.settingsItem]: true,
                                [_vm.$style.menuItemActive]:
                                    this.$route.path === '/settings/profile',
                            }},[_vm._v(" Settings "),(_vm.showSettingsNotification)?_c('NotificationBlob',{attrs:{"notificationCount":1}}):_vm._e()],1)])]),(_vm.inImpersonatedAccount)?_c('div',{class:_vm.$style.menuItemWrap,on:{"click":_vm.unimpersonate,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.unimpersonate.apply(null, arguments)}}},[_c('div',{class:_vm.$style.menuItem},[_vm._v("Back to my account")])]):_vm._e(),_c('div',{class:[_vm.$style.menuItemWrap, _vm.$style.last],on:{"click":_vm.logout,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.logout.apply(null, arguments)}}},[_c('div',{class:_vm.$style.menuItem},[_vm._v("Logout")])])],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }