const constants = require('./constants')
const currencies = require('./currencies')
const hiringStages = require('./hiringStages')
const localeEqualOps = require('./localeEqualOps')
const equalOppsFriendlyNames = require('./equalOppsFriendlyNames')
const emailTemplates = require('./emailTemplates')
const careersPage = require('./careersPage')
const locationData = require('./locationData')
const remotePreferences = require('./remotePreferences')

exports.default = {
    constants,
    currencies,
    hiringStages,
    localeEqualOps,
    equalOppsFriendlyNames,
    emailTemplates,
    careersPage,
    locationData,
    remotePreferences,
}
