<template>
    <transition name="smooth" mode="out-in">
        <div :class="$style.sizeBodge">
            <div :class="[$style.card, $style.wrapper]">
                <div v-if="jobsLeftToReview">
                    <div :class="$style.header">
                        <div :class="$style.cardTitle">
                            Hi, {{ orgUser }}, have you hired anyone for this role?
                        </div>
                        <Tooltip placement="right">
                            <template slot="default"
                                ><FontAwesomeIcon :icon="questionIcon" :class="$style.helpIcon"
                            /></template>
                            <template slot="inner">
                                <p :class="$style.tooltipTitle">
                                    Keeping the status of roles updated helps you:
                                </p>
                                <ul>
                                    <p>- achieve a fuller picture for your diversity report</p>
                                    <p>
                                        - gives you more accurate data on your average time to hire
                                    </p>
                                    <p>- record employee retention better</p>
                                </ul></template
                            >
                        </Tooltip>
                    </div>
                    <div :class="$style.jobSection">
                        <transition :name="direction" mode="out-in">
                            <MarkHiredCard
                                v-for="job in jobsAwaitingHire"
                                :job="job"
                                :key="job.id"
                                :nextJob="updateCurrentJob"
                                :class="$style.job"
                            />
                        </transition>
                    </div>
                    <div :class="$style.bottomSection">
                        <FontAwesomeIcon
                            :class="[$style.chevrons, $style.hiredIcon]"
                            :icon="chevronLeft"
                            @keyup="previous"
                            @click="previous"
                        />
                        <div :class="$style.navigation">
                            {{ currentJob }} of {{ totalJobs }} roles
                        </div>
                        <FontAwesomeIcon
                            :class="[$style.chevrons, $style.hiredIcon]"
                            :icon="chevronRight"
                            @keyup="next"
                            @click="next"
                        />
                    </div>
                </div>
                <div v-else-if="!dismissedThanks">
                    <ThanksCard @dismissed="dismissThanks" />
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faChevronLeft, faChevronRight, faQuestionCircle } from '@fortawesome/pro-light-svg-icons'
import { Tooltip } from '@applied/marmot'
import MarkHiredCard from './MarkHiredCard/MarkHiredCard'
import ThanksCard from './ThanksCard/ThanksCard'

export default {
    name: 'MarkHiredCarousel',
    props: ['jobsAwaitingHire'],
    components: {
        FontAwesomeIcon,
        MarkHiredCard,
        ThanksCard,
        Tooltip,
    },
    data() {
        return {
            dismissedThanks: false,
            direction: 'slide-next',
            currentJob: 1,
        }
    },
    computed: {
        orgUser() {
            return this.$store.state.user.firstName || ''
        },
        questionIcon() {
            return faQuestionCircle
        },
        jobsLeftToReview() {
            return this.jobsAwaitingHire.length !== 0
        },
        chevronLeft() {
            return faChevronLeft
        },
        chevronRight() {
            return faChevronRight
        },
        totalJobs() {
            return this.jobsAwaitingHire.length
        },
        onLastJob() {
            return this.currentJob === this.totalJobs
        },
        onFirstJob() {
            return this.currentJob === 1
        },
    },
    methods: {
        dismissThanks() {
            this.dismissedThanks = true
        },
        updateCurrentJob() {
            if (this.onFirstJob || this.onLastJob) {
                return
            }
            this.currentJob -= 1
        },
        next() {
            if (this.onLastJob) {
                return
            }
            this.direction = 'slide-next'
            /* eslint-disable vue/no-mutating-props */
            const first = this.jobsAwaitingHire.shift()
            this.jobsAwaitingHire = this.jobsAwaitingHire.concat(first)
            this.currentJob += 1
        },
        previous() {
            if (this.onFirstJob) {
                return
            }
            this.direction = 'slide-back'
            const last = this.jobsAwaitingHire.pop()
            this.jobsAwaitingHire = [last].concat(this.jobsAwaitingHire)
            this.currentJob -= 1
        },
    },
}
</script>
<style module src="./MarkHiredCarousel.css" />

<style scoped>
.slide-next-enter-active {
    transition: all 0.3s ease;
}

.slide-next-leave-active {
    transition: all 0.4s;
}

.slide-next-enter {
    transform: translateX(30px);
    opacity: 0;
}

.slide-next-leave-to {
    transform: translateX(-30px);
    opacity: 0;
}

.slide-back-enter-active {
    transition: all 0.3s ease;
}

.slide-back-leave-active {
    transition: all 0.4s;
}

.slide-back-enter {
    transform: translateX(-30px);
    opacity: 0;
}

.slide-back-leave-to {
    transform: translateX(30px);
    opacity: 0;
}

.smooth-enter-active,
.smooth-leave-active {
    transition: height 1s ease-in-out;
    overflow: hidden;
}

.smooth-enter,
.smooth-leave-to {
    height: 200px;
}
</style>
