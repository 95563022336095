import jobActions from './job'
import utilActions from './utils'
import userActions from './user'
import orgActions from './org'
import emailTemplateActions from './emailTemplates'
import folderActions from './question-folder'

const actions = Object.assign(
    jobActions,
    utilActions,
    userActions,
    orgActions,
    emailTemplateActions,
    folderActions,
)

export default actions
