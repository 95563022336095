<template>
    <div
        :class="{
            [$style.blob]: true,
            [$style.purpleBlob]: color === 'purple',
        }"
    >
        <p>
            {{ name }}: <span>{{ value }}/10</span>
        </p>
    </div>
</template>

<script>
export default {
    name: 'StatBlob',
    props: {
        name: String,
        value: String,
        color: String,
    },
}
</script>

<style module src="./StatBlob.css" />
