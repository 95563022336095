import { render, staticRenderFns } from "./CreateRoleModal.vue?vue&type=template&id=45cd0b06&"
import script from "./CreateRoleModal.vue?vue&type=script&lang=js&"
export * from "./CreateRoleModal.vue?vue&type=script&lang=js&"
import style0 from "./CreateRoleModal.css?vue&type=style&index=0&prod&module=true&lang=css&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports