import Vue from 'vue'

const storeFreeTrial = (state) => {
    const { Org } = state.user

    if (Org && Org.OrgTiers.length > 0) {
        const freeTrial = Org.OrgTiers[0].Tier.code === 'free-trial'
        Vue.set(state, 'freeTrial', freeTrial)
    }
}

const storeUser = (state, data) => {
    Vue.set(state, 'user', data)
    storeFreeTrial(state, data)
}

const userLoggedIn = (state, data) => {
    Vue.set(state, 'isUserLoggedIn', data)
}

const orgHasExpired = (state, data) => {
    Vue.set(state, 'orgHasExpired', data)
}

const storeUserDashboardDetails = (state, data) => {
    Vue.set(state, 'userDashboardDetails', data)
}

export default {
    storeUser,
    userLoggedIn,
    storeFreeTrial,
    orgHasExpired,
    storeUserDashboardDetails,
}
