<template>
    <div :class="$style.pipelineStatsContainer">
        <div
            v-for="data in enhancedPipelineStats"
            v-bind:key="data.key"
            :class="$style.iconContainer"
        >
            <Tooltip
                :class="[$style.icon, data.count === 0 ? $style.iconEmpty : '']"
                placement="top"
                :offset="8"
            >
                <template>
                    <div>
                        <FontAwesomeIcon :icon="data.icon" />
                    </div>
                </template>
                <template v-slot:inner>
                    <p>{{ data.title }}</p>
                </template>
            </Tooltip>
            <p :class="$style.statNumber" :aria-label="data.ariaText">
                {{ data.count === 0 ? '-' : data.count }}
            </p>
        </div>
    </div>
</template>

<script>
import { Tooltip } from '@applied/marmot'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
    faUserClock,
    faEyeSlash,
    faCalendarDay,
    faUserTimes,
    faUserCheck,
} from '@fortawesome/pro-light-svg-icons'
import sharedConfig from 'sharedConfig'

const hiringStages = sharedConfig.default.hiringStages.default

export default {
    name: 'PipelineStatsSection',
    components: {
        FontAwesomeIcon,
        Tooltip,
    },
    props: {
        pipelineStats: Object,
    },
    computed: {
        enhancedPipelineStats() {
            let countApplications

            if (!this.pipelineStats) {
                countApplications = () => 0
            } else {
                countApplications = (stage) =>
                    hiringStages[stage].statKeys.reduce(
                        (count, key) => count + this.pipelineStats[key],
                        0,
                    )
            }

            return Object.entries(this.stageConfig).map(([stage, config]) => {
                const count = countApplications(stage)
                const { title } = hiringStages[stage]

                return {
                    key: stage,
                    ...config,
                    count,
                    title,
                    ariaText: this.getAriaText(title, count),
                }
            })
        },
        stageConfig() {
            return {
                awaitingReview: {
                    icon: faUserClock,
                    statKeys: ['awaitingReview'],
                },
                inReviewOrAwaitingInterview: {
                    icon: faEyeSlash,
                    statKeys: ['inReview', 'awaitingInterview'],
                },
                inInterviewOrAwaitingDecision: {
                    icon: faCalendarDay,
                    statKeys: ['inInterview', 'awaitingDecision'],
                },
                rejectedAfterSubmission: {
                    icon: faUserTimes,
                    statKeys: ['rejectedAfterSubmission'],
                },
                hired: {
                    icon: faUserCheck,
                    statKeys: ['hired'],
                },
            }
        },
    },
    methods: {
        getAriaText(title, count) {
            const isSingular = count === 1
            const verb = isSingular ? 'is' : 'are'
            const plural = isSingular ? '' : 's'

            return `There ${verb} ${count} candidate${plural} in stage '${title}'`
        },
    },
}
</script>

<style module src="./PipelineStatsSection.css" />
