import Vue from 'vue'

const storeOrg = (state, data) => {
    Vue.set(state, 'org', data)
}

const storeMicrositeData = (state, data) => {
    Vue.set(state, 'orgMicrositeData', data)
}

const storeFeedback = (state, data) => {
    Vue.set(state, 'feedback', data)
}

const storeStats = (state, data) => {
    Vue.set(state, 'stats', data)
}

const storeTemplates = (state, data) => {
    Vue.set(state, 'templates', data)
}

const storeOrgUsers = (state, data) => {
    Vue.set(state, 'orgUsers', data)
}

const storeOrgSignupData = (state, data) => {
    state.orgSignupData = { ...state.orgSignupData, ...data }
}
const storeFreeTrial = (state, data) => {
    Vue.set(state, 'freeTrial', data && data.Tier && data.Tier.code === 'free-trial')
}

const storeSubBrands = (state, data) => {
    Vue.set(state, 'subBrands', data)
}

const storeLibrarySkills = (state, data) => {
    Vue.set(state, 'librarySkills', data)
}

export default {
    storeOrgUsers,
    storeOrg,
    storeFeedback,
    storeStats,
    storeTemplates,
    storeOrgSignupData,
    storeFreeTrial,
    storeMicrositeData,
    storeSubBrands,
    storeLibrarySkills,
}
