<template>
    <div>
        <div v-if="showUserPhoto">
            <img
                alt="User profile image"
                :class="{
                    [$style.image]: true,
                    [$style.smallImage]: size === 'small',
                    [$style.largeImage]: size !== 'small',
                }"
                v-bind:src="imageUrl"
                @error="showUserPhoto = false"
            />
        </div>
        <div
            v-else-if="userInitials"
            :class="{
                [$style.userImage]: true,
                [$style.small]: size === 'small',
                [$style.medium]: size === 'medium',
                [$style.large]: size !== 'small' && size !== 'medium',
            }"
        >
            {{ userInitials }}
        </div>
        <div
            v-else
            :class="{
                [$style.userImage]: true,
                [$style.fa]: true,
                [$style.small]: size === 'small',
                [$style.large]: size !== 'small',
            }"
        >
            <FontAwesomeIcon :icon="userIcon" />
        </div>
    </div>
</template>

<script>
import endpointUtils from '@/utils/endpoint'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'

export default {
    name: 'UserImage',
    props: {
        userInitials: String,
        size: String,
        userPhoto: String,
        prefixBaseUrl: {
            type: Boolean,
            default: true,
        },
    },
    components: {
        FontAwesomeIcon,
    },
    data() {
        return {
            num: 1,
            showUserPhoto: true,
        }
    },
    created() {
        this.showUserPhoto = !!this.userPhoto
    },
    computed: {
        imageUrl() {
            return `${this.prefixBaseUrl ? endpointUtils.baseUrl() : ''}${this.userPhoto}`
        },
        userIcon() {
            return faUser
        },
    },
}
</script>

<style module src="./UserImage.css" />
