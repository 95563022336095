<template>
    <div :class="$style.wrapper">
        <div :class="$style.header">
            <h4>Learn the new best practices</h4>
            <LoadingChecklistItem v-if="pending" />
            <ProgressBar :percentage="progress" v-else />
        </div>
        <div :class="$style.content" v-if="pending">
            <ul :class="$style.checklist">
                <LoadingChecklistItem v-for="(item, index) in checklistSteps" :key="index" />
            </ul>
        </div>
        <div :class="$style.content" v-else>
            <ul :class="$style.checklist">
                <CheckListItem
                    :complete="checklistSteps.createAccount.completed"
                    text="Create your account"
                />
                <CheckListItem
                    :disabled="!reviewToken"
                    :link="reviewLink"
                    :targetBlank="true"
                    :complete="checklistSteps.siftCandidates.completed"
                    text="Blind review example candidates"
                />
                <CheckListItem
                    :link="inviteLink"
                    :complete="checklistSteps.inviteColleagues.completed"
                    text="Create your hiring team"
                />
                <CheckListItem
                    @clicked="handleCreateRole"
                    :complete="checklistSteps.createRole.completed"
                    text="Create your first role"
                />
            </ul>
        </div>
    </div>
</template>

<script>
import CheckListItem from './CheckListItem/CheckListItem'
import LoadingChecklistItem from './LoadingChecklistItem/LoadingChecklistItem'
import ProgressBar from './ProgressBar/ProgressBar'

export default {
    name: 'OnboardingChecklist',
    components: {
        CheckListItem,
        ProgressBar,
        LoadingChecklistItem,
    },
    computed: {
        completed() {
            return Object.values(this.checklistSteps).filter((entry) => entry.completed === true)
                .length
        },
        progress() {
            return Math.round((this.completed / Object.keys(this.checklistSteps).length) * 100)
        },
        inviteLink() {
            return `/builder/${this.sampleRoleId}/team`
        },
        reviewLink() {
            return `/review-app/#/${this.reviewToken}/overview?appcue=265f5239-f115-4790-afe0-67077bb1c6ff`
        },
        hasOrgUsers() {
            return this.$store.state.orgUsers.length > 1
        },
        hasCreatedRole() {
            return this.$store.state.orgJobs.length > 1
        },
        reviewToken() {
            return this.$store.state.reviewToken
        },
        noIncompleteReviews() {
            return this.$store.state.incompleteReviews.length === 0
        },
        sampleRoleId() {
            return this.$store.state.sampleRoleId
        },
        pending() {
            return (
                this.$store.state.pending.fetchJobsByOrg ||
                this.$store.state.pending.fetchOrgAdmins ||
                this.$store.state.pending.getReviewerData
            )
        },
        checklistSteps() {
            return {
                createAccount: {
                    completed: true,
                },
                siftCandidates: {
                    completed: this.noIncompleteReviews,
                },
                inviteColleagues: {
                    completed: this.hasOrgUsers,
                },
                createRole: {
                    completed: this.hasCreatedRole,
                },
            }
        },
    },
    methods: {
        handleCreateRole() {
            this.$store.commit('toggleModalVisibility', {
                modalName: 'createRoleModal',
            })
        },
    },
}
</script>

<style module src="./OnboardingChecklist.css" />
