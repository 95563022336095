<template>
    <transition name="modal">
        <div>
            <div :class="$style.popOver">
                <div :class="$style.popOverInner">
                    <router-link to="/dashboard" :class="$style.noUnderline">
                        <div
                            :class="{
                                [$style.menuItemWrap]: true,
                                [$style.menuItemWrapActive]: this.$route.path === '/dashboard',
                            }"
                        >
                            <div
                                :class="{
                                    [$style.menuItem]: true,
                                    [$style.menuItemActive]: this.$route.path === '/dashboard',
                                }"
                            >
                                Dashboard
                            </div>
                        </div>
                    </router-link>
                    <router-link to="/user-dashboard" :class="$style.noUnderline">
                        <div
                            :class="{
                                [$style.menuItemWrap]: true,
                                [$style.menuItemWrapActive]: this.$route.path === '/user-dashboard',
                            }"
                        >
                            <div
                                :class="{
                                    [$style.menuItem]: true,
                                    [$style.menuItemActive]: this.$route.path === '/user-dashboard',
                                }"
                            >
                                My Tasks
                            </div>
                        </div>
                    </router-link>
                    <router-link
                        to="/email-templates"
                        v-if="isAdmin || isJobAdmin"
                        :class="$style.noUnderline"
                    >
                        <div
                            :class="{
                                [$style.menuItemWrap]: true,
                                [$style.menuItemWrapActive]:
                                    this.$route.path === '/email-templates',
                            }"
                        >
                            <div
                                :class="{
                                    [$style.menuItem]: true,
                                    [$style.menuItemActive]:
                                        this.$route.path === '/email-templates',
                                }"
                            >
                                Email templates
                            </div>
                        </div>
                    </router-link>
                    <router-link
                        to="/question-library"
                        :event="hasLibraryAccess ? 'click' : ''"
                        :class="{
                            [$style.noUnderline]: true,
                            [$style.disabled]: !hasLibraryAccess,
                        }"
                    >
                        <div
                            :class="{
                                [$style.menuItemWrap]: true,
                                [$style.menuItemWrapActive]:
                                    this.$route.path === '/question-library',
                            }"
                        >
                            <div
                                :class="{
                                    [$style.menuItem]: true,
                                    [$style.menuItemActive]:
                                        this.$route.path === '/question-library',
                                }"
                            >
                                Question library
                            </div>
                        </div>
                    </router-link>
                    <div
                        :class="$style.menuItemWrap"
                        @click="orgStatsRedirect"
                        @keyup.enter="orgStatsRedirect"
                        v-if="isAdmin"
                    >
                        <div :class="$style.menuItem">Global reporting</div>
                    </div>
                    <router-link 
                        :to="showSettingsNotification ? '/settings/account' : '/settings/profile'"
                        :class="$style.noUnderline"
                    >
                        <div
                            :class="{
                                [$style.menuItemWrap]: true,
                                [$style.menuItemWrapActive]:
                                    this.$route.path === '/settings/profile',
                            }"
                        >
                            <div
                                :class="{
                                    [$style.menuItem]: true,
                                    [$style.settingsItem]: true,
                                    [$style.menuItemActive]:
                                        this.$route.path === '/settings/profile',
                                }"
                            >
                                Settings
                                <NotificationBlob v-if="showSettingsNotification" :notificationCount="1" />
                            </div>
                        </div>
                    </router-link>
                    <div
                        v-if="inImpersonatedAccount"
                        :class="$style.menuItemWrap"
                        @click="unimpersonate"
                        @keyup.enter="unimpersonate"
                    >
                        <div :class="$style.menuItem">Back to my account</div>
                    </div>
                    <div
                        :class="[$style.menuItemWrap, $style.last]"
                        @click="logout"
                        @keyup.enter="logout"
                    >
                        <div :class="$style.menuItem">Logout</div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import NotificationBlob from './NotificationBlob/NotificationBlob.vue'

export default {
    name: 'HeaderMenu',
    components: {
        NotificationBlob,
    },
    data() {
        return {}
    },
    computed: {
        inImpersonatedAccount() {
            return this.$store.state.user.inImpersonatedAccount
        },
        hasLibraryAccess() {
            if (!this.$store.state.org.limitedLibraryEnabled) return true
            return this.$store.state.user.role === 'ADMIN'
        },
        isAdmin() {
            return this.$store.state.user.role === 'ADMIN'
        },
        isJobAdmin() {
            return this.$store.state.user.JobUsers
        },
        isMissingOrgData() {
            return !this.$store.state.org.name ||
            !this.$store.state.org.contactEmail ||
            !this.$store.state.org.industry ||
            !this.$store.state.org.size ||
            !this.$store.state.org.locale ||
            !this.$store.state.org.tz
        },
        showSettingsNotification() {
            return this.isAdmin && this.isMissingOrgData
        }
    },
    methods: {
        logout() {
            this.$store.dispatch('logout')
        },
        unimpersonate() {
            this.$store.dispatch('unimpersonate').then(() => {
                this.$router.go('/dashboard')
            })
        },
        orgStatsRedirect() {
            this.$router.push('org-stats')
        },
    },
}
</script>

<style module src="./HeaderMenu.css" />
