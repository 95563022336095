const listItems = [
    'Candidate submissions: Are you happy with the diversity of candidates you are attracting?',
    `Bottlenecks: Do you see dramatic changes in representation from one stage to the other?
    If yes, consider auditing your hiring process for potential sources of bias and adverse impact.`,
]

const dataWorkshopLink = `https://www.beapplied.com/customer-events#workshop-registration`

export default [
    {
        name: 'Gender',
        key: 'gender',

        content: {
            heading: 'Does my process indicate gender bias?',
            description: `This chart represents how candidates who applied in your chosen period
            moved through your hiring process, broken down by gender. Check the following:`,
            listItems,
            supportCTA: `Need more support? <a href="${dataWorkshopLink}" target="_blank">
                    Join Applied’s data workshop</a> to understand your data better and learn how to take action.`,
        },
    },
    {
        name: 'Age',
        key: 'ageRange',
        content: {
            heading: 'Does my process indicate age bias?',
            description: `This chart represents how candidates who applied in your chosen period
            moved through your hiring process, broken down by age. Check the following:`,
            listItems,
            supportCTA: `Need more support? <a href="${dataWorkshopLink}" target="_blank">
                    Join Applied’s data workshop</a> to understand your data better and learn how to take action.`,
        },
    },
    {
        name: 'Ethnicity',
        key: 'ethnicity',
        content: {
            heading: 'Does my process indicate ethnic bias?',
            description: `This chart represents how candidates who applied in your chosen period
            moved through your hiring process, broken down by ethnicity. Check the following:`,
            listItems,
            supportCTA: `Need more support? <a href="${dataWorkshopLink}" target="_blank">
                    Join Applied’s data workshop</a> to understand your data better and learn how to take action.`,
        },
    },
    {
        name: 'Sexuality',
        key: 'sexuality',
        content: {
            heading: 'Does my process indicate sexuality bias?',
            description: `This chart represents how candidates who applied in your chosen period
            moved through your hiring process, broken down by sexuality. Check the following:`,
            listItems,
            supportCTA: `Need more support? <a href="${dataWorkshopLink}" target="_blank">
                    Join Applied’s data workshop</a> to understand your data better and learn how to take action.`,
        },
    },
    {
        name: 'Disability',
        key: 'disability',
        content: {
            heading: 'Does my process indicate disability bias?',
            description: `This chart represents how candidates who applied in your chosen period
            moved through your hiring process, broken down by disability. Check the following:`,
            listItems,
            supportCTA: `Need more support? <a href="${dataWorkshopLink}" target="_blank">
                    Join Applied’s data workshop</a> to understand your data better and learn how to take action.`,
        },
    },
    {
        name: 'Family Education',
        key: 'familyEducation',
        content: {
            heading: 'Does my process indicate bias due to family educational background?',
            description: `This chart represents how candidates who applied in your chosen period
            moved through your hiring process, based on whether none or at least one their parents went to university. Check the following:`,
            listItems,
            supportCTA: `Need more support? <a href="${dataWorkshopLink}" target="_blank">
                    Join Applied’s data workshop</a> to understand your data better and learn how to take action.`,
        },
    },
    {
        name: 'Family Wealth',
        key: 'familyWealth',
        content: {
            heading: 'Does my process indicate bias due to family wealth?',
            description: `This chart represents how candidates who applied in your chosen period
            moved through your hiring process, based on whether candidates were eligible for free school meals. Check the following:`,
            listItems,
            supportCTA: `Need more support? <a href="${dataWorkshopLink}" target="_blank">
                    Join Applied’s data workshop</a> to understand your data better and learn how to take action.`,
        },
    },
    {
        name: 'Religion',
        key: 'religion',
        content: {
            heading: 'Does my process indicate religious background bias?',
            description: `This chart represents how candidates who applied in your chosen period
            moved through your hiring process, broken down by religion. Check the following:`,
            listItems,
            supportCTA: `Need more support? <a href="${dataWorkshopLink}" target="_blank">
                    Join Applied’s data workshop</a> to understand your data better and learn how to take action.`,
        },
    },
    {
        name: 'Caste',
        key: 'caste',
        content: {
            heading: 'Does my process indicate bias due to caste?',
            description: `This chart represents how candidates who applied in your chosen period
            moved through your hiring process, broken down by caste. Check the following:`,
            listItems,
            supportCTA: `Need more support? <a href="${dataWorkshopLink}" target="_blank">
                    Join Applied’s data workshop</a> to understand your data better and learn how to take action.`,
        },
    },
    {
        name: 'Veteran Status',
        key: 'veteranStatus',
        content: {
            heading: 'Does my process indicate bias due to veteran status?',
            description: `This chart represents how candidates who applied in your chosen period
            moved through your hiring process, broken down by veteran status. Check the following:`,
            listItems,
            supportCTA: `Need more support? <a href="${dataWorkshopLink}" target="_blank">
                    Join Applied’s data workshop</a> to understand your data better and learn how to take action.`,
        },
    },
    {
        name: 'Community Background',
        key: 'communityBackground',
        content: {
            heading: 'Does my process indicate bias due to community background?',
            description: `This chart represents how candidates who applied in your chosen period
            moved through your hiring process, broken down by their community background. Check the following:`,
            listItems,
            supportCTA: `Need more support? <a href="${dataWorkshopLink}" target="_blank">
                    Join Applied’s data workshop</a> to understand your data better and learn how to take action.`,
        },
    },
]
