/* eslint-disable vue-a11y/click-events-have-key-events */
<template>
    <div :class="$style.wrapper">
        <p :class="$style.jobCount">{{ jobCount || 0 }} Jobs</p>
        <div :class="$style.chipList">
            <div v-for="item in items" :key="item.value" :class="$style.chip">
                <p :class="$style.chipText">{{ item.name }}</p>
                <button
                    name="remove filter"
                    @click="removeClicked(item)"
                    @keydown="buttonKeydown($event, () => removeClicked(item))"
                    :class="$style.chipRemove"
                >
                    <FontAwesomeIcon :icon="closeIcon" />
                </button>
            </div>
        </div>
        <button
            name="remove all filters"
            @click="clearAllClicked"
            @keydown="buttonKeydown($event, clearAllClicked)"
            :class="$style.clearChips"
        >
            Clear
        </button>
    </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

export default {
    name: 'ActiveFilterList',
    props: {
        jobCount: Number,
        items: Array,
    },
    components: {
        FontAwesomeIcon,
    },
    methods: {
        buttonKeydown(event, callback) {
            // Space key or enter key
            if (event.which in [13, 32]) {
                callback()
            }
        },
        removeClicked(item) {
            this.$emit('remove-filter', item)
        },
        clearAllClicked() {
            this.$emit('remove-all', this.items)
        },
    },
    computed: {
        closeIcon() {
            return faTimes
        },
    },
}
</script>

<style module src="./ActiveFilterList.css" />
