<template>
    <div :class="{ [$style.wrapper]: true, [$style.small]: small }">
        <Spinner v-if="pending" color="#6d778a" size="32px" />
        <div v-else :class="$style.layerWrap">
            <div :class="[$style.tooltip, $style.layer1]" v-if="showslot('tooltip')">
                <Tooltip placement="right">
                    <template slot="default">
                        <div><FontAwesomeIcon :icon="questionIcon" /></div>
                    </template>
                    <template slot="inner">
                        <p><slot name="tooltip"></slot></p>
                    </template>
                </Tooltip>
            </div>
            <div :class="$style.layer2">
                <div
                    v-for="section in sections"
                    :class="[
                        $style.textWrapper,
                        section === 'title' ? $style.title : '',
                        section === 'metric' ? $style.metric : '',
                        section === 'description' ? $style.description : '',
                        section === 'link' ? $style.link : '',
                    ]"
                    :key="sections.indexOf(section)"
                >
                    <slot v-if="showslot(section)" :name="section"></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Tooltip } from '@applied/marmot'
import Spinner from '@/components/Spinner/Spinner'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'

export default {
    name: 'KeyNumbersCard',
    components: {
        Tooltip,
        Spinner,
        FontAwesomeIcon,
    },
    data() {
        return {
            sections: ['title', 'metric', 'description', 'link'],
        }
    },
    props: {
        pending: {
            type: Boolean,
            default: false,
        },
        small: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        showslot(slotName) {
            return this.$slots[slotName]
        },
    },
    computed: {
        questionIcon() {
            return faQuestionCircle
        },
    },
}
</script>

<style module src="./KeyNumbersCard.css" />
