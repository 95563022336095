import axios from '@/axios'
import utils from '@/utils/index'

const encodeValue = (item) => {
    if (['boolean', 'string', 'number'].includes(typeof item)) {
        return item.toString()
    }

    return ''
}

const objectToQueryParamPairs = (obj) => {
    if (!(obj instanceof Object)) {
        return []
    }
    const pairs = []
    Object.entries(obj).forEach(([key, value]) => {
        if (value instanceof Array) {
            value.map(encodeValue).forEach((arrayItem) => {
                pairs.push([key, arrayItem])
            })
            return
        }

        // Don't encode nested objects - too much complexity
        if (value instanceof Object) {
            return
        }

        pairs.push([key, encodeValue(value)])
    })
    return pairs
}

const fetchJobsByOrg = (context, data) => {
    context.commit('error', { type: 'fetchJobsByOrg', status: false })
    context.commit('pending', { type: 'fetchJobsByOrg', status: true })

    const { query, filters, search } = data

    const searchParams = new URLSearchParams()

    objectToQueryParamPairs(query).forEach((pair) => {
        searchParams.append(...pair)
    })

    objectToQueryParamPairs(filters).forEach((pair) => {
        searchParams.append(...pair)
    })

    if (search) {
        searchParams.append('search', search)
    }

    const url = `job/list-es?${searchParams}`

    return axios
        .get(url)
        .then((responseData) => {
            const { jobs, total, pages } = responseData.data
            context.commit('storeJobPagination', { total, pages })
            context.commit('storeJobs', jobs)

            const sampleRoleId = jobs.find((job) => job.sampleRole === true).id

            if (sampleRoleId) {
                context.commit('storeSampleRoleId', {
                    id: sampleRoleId,
                })
                context.dispatch('getReviewerToken', {
                    jobId: sampleRoleId,
                })
            }
            context.commit('pending', {
                type: 'fetchJobsByOrg',
                status: false,
            })
        })
        .catch(() => {
            context.commit('pending', {
                type: 'fetchJobsByOrg',
                status: false,
            })
            context.commit('error', {
                type: 'fetchJobsByOrg',
                status: true,
            })
        })
}

const fetchJobsAwaitingHire = (context) => {
    context.commit('error', { type: 'fetchJobsAwaitingHire', status: false })
    context.commit('pending', { type: 'fetchJobsAwaitingHire', status: true })
    const url = './hired-notifications'
    return axios
        .get(url, {})
        .then((response) => {
            context.commit('storeJobsAwaitingHire', response.data)
            context.commit('pending', {
                type: 'fetchJobsAwaitingHire',
                status: false,
            })
        })
        .catch(() => {
            context.commit('pending', {
                type: 'fetchJobsAwaitingHire',
                status: false,
            })
            context.commit('error', {
                type: 'fetchJobsAwaitingHire',
                status: true,
            })
        })
}

const fetchJobCountsByOrg = (context) => {
    context.commit('error', { type: 'fetchJobCountsByOrg', status: false })
    context.commit('pending', { type: 'fetchJobCountsByOrg', status: true })
    return axios
        .get('/job-counts')
        .then((responseData) => {
            context.commit('storeJobCounts', responseData.data)
            context.commit('pending', {
                type: 'fetchJobCountsByOrg',
                status: false,
            })
        })
        .catch(() => {
            context.commit('pending', {
                type: 'fetchJobCountsByOrg',
                status: false,
            })
            context.commit('error', {
                type: 'fetchJobCountsByOrg',
                status: true,
            })
        })
}

const fetchJobFilters = (context, data) => {
    context.commit('error', { type: 'fetchJobFilters', status: false })
    context.commit('pending', { type: 'fetchJobFilters', status: true })

    const { from, until } = data || {}

    return axios
        .get(`/job/filters`, {
            params: {
                from: from && from.toISOString(),
                until: until && until.toISOString(),
            },
        })
        .then((responseData) => {
            context.commit('storeJobFilters', responseData.data)
            context.commit('pending', {
                type: 'fetchJobFilters',
                status: false,
            })
        })
        .catch(() => {
            context.commit('pending', {
                type: 'fetchJobFilters',
                status: false,
            })
            context.commit('error', {
                type: 'fetchJobFilters',
                status: true,
            })
        })
}

const copyRoleById = (context, data) => {
    context.commit('error', { type: 'copyRoleById', status: false })
    context.commit('pending', { type: 'copyRoleById', status: true })
    const url = `/dashboard-old/role/${data.jobId}/duplicate${
        data.fromTemplate ? `?template=true` : ''
    }`

    return axios
        .post(url)
        .then((responseData) =>
            utils.delay(800).then(() => {
                context.commit('addJob', responseData.data)
                context.commit('pending', {
                    type: 'copyRoleById',
                    status: false,
                })
                context.dispatch('fetchJobCountsByOrg')
                return Promise.resolve(responseData.data.id)
            }),
        )
        .catch(() => {
            context.commit('pending', {
                type: 'copyRoleById',
                status: false,
            })
            context.commit('error', {
                type: 'copyRoleById',
                status: true,
            })

            return Promise.reject()
        })
}

const copyTemplateRoleById = (context, data) => {
    context.commit('error', { type: 'copyTemplateRoleById', status: false })
    context.commit('pending', { type: 'copyTemplateRoleById', status: true })
    const url = `/dashboard-old/role/${data.jobId}/duplicate-template${
        data.fromTemplate ? `?template=true` : ''
    }`

    return axios
        .post(url)
        .then((responseData) =>
            utils.delay(800).then(() => {
                context.commit('addJob', responseData.data)
                context.commit('pending', {
                    type: 'copyTemplateRoleById',
                    status: false,
                })
                context.dispatch('fetchJobCountsByOrg')
                return Promise.resolve(responseData.data.id)
            }),
        )
        .catch(() => {
            context.commit('pending', {
                type: 'copyTemplateRoleById',
                status: false,
            })
            context.commit('error', {
                type: 'copyTemplateRoleById',
                status: true,
            })

            return Promise.reject()
        })
}

const saveJobStatus = (context, data) => {
    context.commit('error', { type: 'saveJobStatus', status: false })
    context.commit('pending', { type: 'saveJobStatus', status: true })
    const url = `/api/rest/job/${data.id}`

    return axios
        .put(url, {
            status: data.status,
            statusWaitingAt: data.status === 'waiting' ? new Date(Date.now()) : null,
        })
        .then(() => {
            context.commit('pending', { type: 'saveJobStatus', status: false })
            context.commit('updateJobStatus', data)
        })
        .catch(() => {
            context.commit('pending', { type: 'saveJobStatus', status: false })
            context.commit('error', { type: 'saveJobStatus', status: true })
        })
}

const archiveRole = (context, data) => {
    context.commit('error', { type: 'archiveRole', status: false })
    context.commit('pending', { type: 'archiveRole', status: true })
    const url = `/api/rest/job/${data}`
    return axios
        .put(url, {
            archived: true,
        })
        .then(() => {
            context.commit('removeJob', data)
            context.commit('pending', {
                type: 'archiveRole',
                status: false,
            })
            context.dispatch('fetchJobCountsByOrg')
        })
        .catch(() => {
            context.commit('pending', {
                type: 'archiveRole',
                status: false,
            })
            context.commit('error', {
                type: 'archiveRole',
                status: true,
            })
        })
}

const closeAndArchiveRole = (context, data) => {
    context.commit('error', { type: 'archiveRole', status: false })
    context.commit('pending', { type: 'archiveRole', status: true })
    const url = `/dashboard-old/role/${data}/archive-open`
    return axios
        .post(url, {})
        .then(() => {
            context.commit('removeJob', data)
            context.commit('pending', {
                type: 'archiveRole',
                status: false,
            })
            context.dispatch('fetchJobCountsByOrg')
        })
        .catch(() => {
            context.commit('pending', {
                type: 'archiveRole',
                status: false,
            })
            context.commit('error', {
                type: 'archiveRole',
                status: true,
            })
        })
}

const unarchiveRole = (context, data) => {
    context.commit('error', { type: 'archiveRole', status: false })
    context.commit('pending', { type: 'archiveRole', status: true })
    const url = `/api/rest/job/${data}`
    return axios
        .put(url, {
            archived: false,
        })
        .then(() => {
            context.commit('removeJob', data)
            context.commit('pending', {
                type: 'archiveRole',
                status: false,
            })
            context.dispatch('fetchJobCountsByOrg')
        })
        .catch(() => {
            context.commit('pending', {
                type: 'archiveRole',
                status: false,
            })
            context.commit('error', {
                type: 'archiveRole',
                status: true,
            })
        })
}

const createRole = (context, data) => {
    context.commit('error', { type: 'createRole', status: false })
    context.commit('pending', { type: 'createRole', status: true })
    const url = `/dashboard-old/role/new`
    return axios
        .post(url, { title: data.title })
        .then((responseData) => {
            context.commit('pending', {
                type: 'createRole',
                status: false,
            })
            context.dispatch('fetchJobCountsByOrg')
            return responseData.data.id
        })
        .catch(() => {
            context.commit('pending', {
                type: 'createRole',
                status: false,
            })
            context.commit('error', {
                type: 'createRole',
                status: true,
            })
        })
}

const saveNPS = (context, data) =>
    axios({
        method: context.state.feedbackId ? 'put' : 'post',
        url: `/api/rest/feedback${context.state.feedbackId ? `/${context.state.feedbackId}` : ''}`,
        data: {
            type: 'admin-regular',
            text: data.text,
            rating: data.rating,
        },
    })
        .then((response) => {
            if (!context.state.feedbackId) {
                context.dispatch('trackCSEvent', {
                    eventId: 'adminNPS',
                    value: data.rating,
                })
            }
            context.commit('storeNPS', response.data.id)
        })
        .catch(() => {
            context.commit('error', {
                type: 'storeNPS',
                status: true,
            })
        })

const importRoleFromJobvite = (context, data) => {
    context.commit('error', { type: 'importRoleFromJobvite', status: false })
    context.commit('pending', { type: 'importRoleFromJobvite', status: true })
    const url = `/dashboard-old/role/import`
    return axios
        .post(url, { params: { reqId: data.id }, integration: 'jobvite' })
        .then((responseData) => {
            context.commit('pending', {
                type: 'importRoleFromJobvite',
                status: false,
            })
            context.dispatch('fetchJobCountsByOrg')
            return responseData.data.newRole
        })
        .catch(() => {
            context.commit('pending', {
                type: 'importRoleFromJobvite',
                status: false,
            })
            context.commit('error', {
                type: 'importRoleFromJobvite',
                status: true,
            })
        })
}

const getReviewerToken = async (context, data) => {
    context.commit('error', { type: 'getReviewerData', status: false })
    context.commit('pending', { type: 'getReviewerData', status: true })
    return axios
        .get(`/freview/get-token/${data.jobId}`)
        .then((response) => {
            context.commit('saveReviewToken', response.data)
            context.dispatch('getReviewerData', { token: response.data.token })

            context.commit('error', { type: 'getReviewerData', status: false })
            context.commit('pending', {
                type: 'getReviewerData',
                status: false,
            })
        })
        .catch((error) => {
            console.log(error)
        })
}

const getReviewerData = (context, data) => {
    context.commit('error', {
        type: 'getReviewerData',
        status: false,
    })
    context.commit('pending', {
        type: 'getReviewerData',
        status: true,
    })

    const fetchTokenDetails = axios.get(`/freview/${data.token.hash}/token-details`)
    const fetchReviews = axios.get(`/freview/${data.token.hash}/reviews`)

    return Promise.all([fetchTokenDetails, fetchReviews])
        .then(([tokenDetails, reviews]) =>
            Promise.all([
                context.commit('saveReviewTokenDetails', tokenDetails.data),
                context.commit('saveReviews', reviews.data),
                context.commit('pending', {
                    type: 'getReviewerData',
                    status: false,
                }),
            ]),
        )
        .catch(() => {
            context.commit('error', {
                type: 'getReviewerData',
                status: true,
            })
            context.commit('pending', {
                type: 'getReviewerData',
                status: false,
            })
        })
}

export default {
    fetchJobsByOrg,
    fetchJobFilters,
    copyRoleById,
    archiveRole,
    closeAndArchiveRole,
    createRole,
    importRoleFromJobvite,
    fetchJobCountsByOrg,
    saveNPS,
    unarchiveRole,
    saveJobStatus,
    fetchJobsAwaitingHire,
    copyTemplateRoleById,
    getReviewerData,
    getReviewerToken,
    objectToQueryParamPairs,
}
