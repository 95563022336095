<!-- eslint-disable vue/require-component-is -->
<template>
    <li :class="$style.wrapper">
        <component
            :class="{
                [$style.link]: true,
                [$style.disabled]: disabled === true,
                [$style.linkhover]: !complete,
                [$style.noClick]: complete,
            }"
            :href="link"
            :target="target"
            :is="elementType"
            @click="handleClickEvent"
            @keypress.enter="handleClickEvent"
            tabindex="0"
        >
            <span>
                <div :class="$style.checkBox">
                    <div :class="$style.checkBoxComplete" v-if="complete">
                        <FontAwesomeIcon :icon="checkIcon" />
                    </div>
                </div>
                <span
                    :class="{
                        [$style.listText]: true,
                        [$style.strikeThrough]: complete,
                    }"
                    >{{ text }}</span
                ></span
            >
            <FontAwesomeIcon :icon="chevronIcon" :class="$style.faIcon" />
        </component>
    </li>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faCheck, faChevronRight } from '@fortawesome/fontawesome-free-solid'

export default {
    name: 'CheckListItem',
    components: {
        FontAwesomeIcon,
    },
    props: {
        complete: {
            type: Boolean,
            default: false,
        },
        targetBlank: {
            type: Boolean,
            default: false,
        },
        text: String,
        link: String,
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        elementType() {
            return this.complete ? 'div' : 'a'
        },
        checkIcon() {
            return faCheck
        },
        chevronIcon() {
            return faChevronRight
        },
        target() {
            return this.targetBlank ? '_blank' : '_self'
        },
    },
    methods: {
        handleClickEvent() {
            if (this.link) {
                return
            }
            this.$emit('clicked')
        },
    },
}
</script>

<style module src="./CheckListItem.css" />
