<template>
    <Modal @close="toggleModal" :large="true">
        <span slot="header" :class="$style.modalHeader"> Who do you want to hire? </span>
        <div slot="message">
            <div :class="$style.roleTitleRow">
                <TextInput
                    :class="$style.roleNameInput"
                    v-model="roleTitle"
                    ariaLabel="role title"
                    id="createRoleTitle"
                />
                <Button
                    @clicked="buildRole"
                    :class="$style.startFromScratch"
                    :disabled="!roleTitle"
                    data-test-id="startFromScratchButton"
                >
                    <span slot="buttonText"> Start from scratch </span>
                </Button>
            </div>
            <div :class="$style.roles">
                <div :class="$style.templateRole">
                    <p :class="$style.existingRoleHeader">Use a template</p>
                    <div :class="$style.rolesList" id="templates">
                        <div
                            v-for="(template, index) in getTemplates"
                            :key="index"
                            :value="template.id"
                            :class="$style.roleRow"
                        >
                            <p>{{ template.title }}</p>
                            <Button
                                @clicked="buildFromTemplate(template.id)"
                                :class="$style.goButton"
                                color="primaryText"
                                :pending="createPending"
                            >
                                <span slot="buttonText">Go</span>
                            </Button>
                        </div>
                    </div>
                </div>
                <div :class="$style.previousRole">
                    <p :class="$style.existingRoleHeader">Re-use a previous role</p>
                    <div v-if="previousRolesCount > 0" :class="$style.rolesList" id="previous">
                        <div
                            v-for="previousRole in getPreviousRoles"
                            :key="previousRole.id"
                            :value="previousRole.id"
                            :class="$style.roleRow"
                        >
                            <span :class="$style.previousRoleItem">
                                {{ getPreviousRoleFormattedTitle(previousRole.title) }}
                            </span>
                            <span
                                :class="{
                                    [$style.previousRoleItem]: true,
                                    [$style.roleCreationTime]: true,
                                }"
                            >
                                {{ getRoleCreationTime(previousRole.createdAt) }}
                                ago
                            </span>
                            <Button
                                @clicked="buildFromPreviousRole(previousRole.id)"
                                :class="$style.goButton"
                                color="primaryText"
                                :pending="createPending"
                            >
                                <span slot="buttonText">Go</span>
                            </Button>
                        </div>
                    </div>
                    <p :class="$style.noReusableRoles" v-else>
                        (Once you’re up and running you can save time by re-using and adapting past
                        roles)
                    </p>
                </div>
            </div>
        </div>
    </Modal>
</template>

<script>
import { Button, TextInput, Modal } from '@applied/marmot'
import { formatDistance } from 'date-fns'

export default {
    name: 'CreateRoleModal',
    components: {
        Modal,
        TextInput,
        Button,
    },
    data() {
        return {
            roleTitle: '',
        }
    },
    methods: {
        toggleModal() {
            this.$store.commit('toggleModalVisibility', {
                modalName: 'createRoleModal',
            })
        },
        buildRole() {
            this.$store.dispatch('createRole', { title: this.roleTitle }).then((data) => {
                if (process.env.NODE_ENV !== 'test') {
                    // so jsdom can't deal with nav which is fine, we're probably doing this bit of routing wrong
                    window.location.href = `/builder/${data}/new/high-volume`
                }
            })
        },
        buildFromTemplate(templateId) {
            this.$store
                .dispatch('copyTemplateRoleById', {
                    jobId: templateId,
                    fromTemplate: true,
                })
                .then((data) => {
                    if (process.env.NODE_ENV !== 'test') {
                        window.location.href = `/builder/${data}`
                    }
                })
        },
        buildFromPreviousRole(jobId) {
            this.$store
                .dispatch('copyRoleById', {
                    jobId,
                })
                .then((data) => {
                    if (process.env.NODE_ENV !== 'test') {
                        window.location.href = `/builder/${data}`
                    }
                })
        },
        getRoleCreationTime(jobCreatedAt) {
            const subdate = new Date(jobCreatedAt)
            return formatDistance(subdate, new Date())
        },
        getPreviousRoleFormattedTitle(title) {
            return title.length > 15 ? `${title.substring(0, 15)}...` : title
        },
    },
    computed: {
        email() {
            return this.$store.state.user.email
        },
        getTemplates() {
            return this.$store.state.templates
        },
        getPreviousRoles() {
            const jobs = this.$store.state.orgJobs
            return jobs.length < 10 ? jobs : jobs.slice(0, 10)
        },
        previousRolesCount() {
            return this.$store.state.orgJobs.length
        },
        createPending() {
            return this.$store.state.pending.copyRoleById
        },
        isFreeTrial() {
            return this.$store.state.freeTrial
        },
    },
}
</script>

<style module src="./CreateRoleModal.css" />
