/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
const clickOutside = {
    priority: 700,
    bind() {
        const self = this
        this.event = (event) => {
            self.vm.$emit(self.expression, event)
        }
        this.el.addEventListener('click', this.stopProp)
        document.body.addEventListener('click', this.event)
    },
    unbind() {
        this.el.removeEventListener('click', this.stopProp)
        document.body.removeEventListener('click', this.event)
    },
    stopProp(event) {
        event.stopPropagation()
    },
}

const notificationMenuClickOutside = {
    bind(element, binding) {
        const ourClickEventHandler = (event) => {
            if (!element.contains(event.target) && element !== event.target) {
                binding.value(event)
            }
        }

        element.__vueClickEventHandler__ = ourClickEventHandler
        document.addEventListener('click', ourClickEventHandler)
    },
    unbind(element) {
        document.removeEventListener('click', element.__vueClickEventHandler__)
    },
}

const clickOutsideSplitButton = {
    bind(el, binding, vnode) {
        window.event = function clickOutside2(event) {
            if (!(el === event.target || el.contains(event.target))) {
                vnode.context[binding.expression](event)
            }
        }
        document.body.addEventListener('click', window.event)
    },
    unbind() {
        document.body.removeEventListener('click', window.event)
    },
}

export default {
    clickOutside,
    notificationMenuClickOutside,
    clickOutsideSplitButton,
}
