import axios from '@/axios'

const logout = () =>
    axios.get('/logout-no-redirect').then(() => {
        window.location.href = 'http://www.beapplied.com'
    })

const unimpersonate = () => axios.get('/admin/auth-as/reset')

const resetErrorsPending = (context, action) => {
    context.commit('resetErrorsPending', action)
}

const searchOrg = (context, data) => {
    context.commit('error', { type: 'searchOrg', status: false })
    context.commit('pending', { type: 'searchOrg', status: true })
    const url = `/api/org/candidates?query=${encodeURIComponent(data.searchQuery)}`
    return axios
        .get(url)
        .then((results) => {
            context.commit('storeSearchResults', results.data.applications)
            context.commit('pending', { type: 'searchOrg', status: false })
        })
        .catch(() => {
            context.commit('pending', { type: 'searchOrg', status: false })
            context.commit('error', { type: 'searchOrg', status: true })
        })
}

const getGoogleAuthURL = (context) =>
    axios.get('/google/auth-url').then((response) => {
        context.commit('storeGoogleAuthURL', response)
    })

const fetchBasicData = (context) =>
    Promise.all([
        context.dispatch('fetchOrg'),
        context.dispatch('fetchJobCountsByOrg'),
        context.dispatch('fetchFeedback'),
        context.dispatch('fetchTemplates'),
        context.dispatch('fetchUser').then(() => {
            const {
                state: { user },
            } = context

            context.dispatch('fetchOrgAdmins', user.OrgId)
            context.dispatch('fetchOrgEmailTemplates', user.OrgId)
        }),
    ])

const checkEmailAddress = (context, data) =>
    axios.get(`/api/user/check-email-address?email=${data.email}&tatSignUp=${data.tatSignUp}`)

const storeTATSignupData = (context, data) => axios.post('/register/new-textziki', data)

const trackCSEvent = (context, data) => axios.post('/integrations/cstrack', data)

export default {
    logout,
    unimpersonate,
    resetErrorsPending,
    searchOrg,
    getGoogleAuthURL,
    fetchBasicData,
    checkEmailAddress,
    storeTATSignupData,
    trackCSEvent,
}
