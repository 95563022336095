<template>
    <div :class="$style.wrapper">
        <div :class="$style.jobInner">
            <div :class="[$style.row, $style.underline]">
                <div :class="$style.column">
                    <SkeletonBlock width="300px" />
                    <SkeletonBlock width="150px" />
                </div>
                <div :class="[$style.column, $style.flexEnd]">
                    <div :class="$style.flexWrap">
                        <SkeletonBlock width="100px" />
                    </div>
                    <div :class="$style.closingDate">
                        <SkeletonBlock width="100px" />
                    </div>
                </div>
            </div>
            <div :class="[$style.row, $style.noPadBottom]">
                <div :class="$style.applicantCountSection">
                    <SkeletonBlock width="200px" />
                </div>
            </div>
            <div :class="[$style.row, $style.buttonRow]">
                <div :class="$style.noPadRow">
                    <SkeletonBlock width="30px" />
                    <SkeletonBlock width="30px" />
                </div>
                <div>
                    <SkeletonBlock width="100px" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SkeletonBlock from '@/components/SkeletonBlock/SkeletonBlock'

export default {
    name: 'SkeletonJobCard',
    components: {
        SkeletonBlock,
    },
}
</script>

<style module src="./SkeletonJobCard.css" />
