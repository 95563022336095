<template>
    <div :class="$style.jobsTableWrapper">
        <EmptyStateCard v-if="!jobs.length" message="No jobs open during this period" />
        <table v-else :class="$style.jobsTable">
            <thead>
                <tr>
                    <th>Job title</th>
                    <th>Applications</th>
                    <th>Time to hire</th>
                    <th>Hires</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <!-- eslint-disable vue/require-v-for-key -->
                <tr v-for="job in jobs">
                    <td>
                        {{ job.title }}
                    </td>
                    <td>
                        {{ job.candidates }}
                    </td>
                    <td>{{ job.tth ? `${job.tth} days` : '' }}</td>
                    <td>
                        {{ job.hired || '' }}
                    </td>
                    <td>
                        <a :href="jobLink(job)" :class="$style.jobLink" target="_blank">
                            <FontAwesomeIcon :icon="faExternalLinkAlt" :class="$style.linkIcon" />
                            Role report
                        </a>
                    </td>
                </tr>
            </tbody>
        </table>
        <div v-if="jobs.length" :class="$style.blurOverlay"></div>
    </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import mapIcons from '@/utils/mapIcons'
import { faExternalLinkAlt } from '@fortawesome/pro-light-svg-icons'
import EmptyStateCard from '../EmptyStateCard/EmptyStateCard'

export default {
    name: 'JobsTable',
    props: {
        jobs: { type: Array, default: () => [] },
    },
    components: {
        EmptyStateCard,
        FontAwesomeIcon,
    },
    computed: {
        ...mapIcons({ faExternalLinkAlt }),
    },
    methods: {
        jobLink(job) {
            return `/manage/${job.id}/reports`
        },
    },
}
</script>

<style module src="./JobsTable.css" />
