<template>
    <div id="app" :class="$style.app">
        <div id="appTop">
            <Header v-if="!hideHeaderFooter" />
            <main>
                <router-view />
            </main>
        </div>
        <Footer v-if="!hideHeaderFooter" />
    </div>
</template>

<script>
import Header from './components/Header/Header'
import Footer from './components/Footer/Footer'

export default {
    name: 'App',
    components: {
        Header,
        Footer,
    },
    computed: {
        hideHeaderFooter() {
            // this is fine but doesn't feel RIGHT
            return (
                this.$route.path === '/login' ||
                this.$route.path === '/welcome' ||
                this.$route.path === '/text-analysis-signup' ||
                this.$route.path.includes('/org-signup-new') ||
                this.$route.path === '/org-signup/template-list' ||
                this.$route.path.includes('/org-signup/view-template') ||
                this.$route.path.includes('/education') ||
                this.$route.path.includes('/question-library') ||
                this.$route.path.includes('reset-password') ||
                this.$route.path.includes('create-password')
            )
        },
    },
}
</script>

<style module src="./App.css" />
