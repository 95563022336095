<template>
    <div :class="$style.wrapper">
        <CandidateFeedbackCard
            :averageRating="averageRating"
            :feedbackCount="allFeedbackCount"
            :textFeedbackCount="textFeedbackCount"
        />
        <div :class="$style.userFeedbackList">
            <UserFeedbackItem
                v-for="feedbackItem in feedback.feedback"
                :feedback="feedbackItem"
                :key="feedbackItem.id"
            />
        </div>
    </div>
</template>

<script>
import { CandidateFeedbackCard } from '@applied/marmot'
import UserFeedbackItem from './UserFeedbackItem/UserFeedbackItem'

export default {
    name: 'ActivityFeed',
    components: {
        CandidateFeedbackCard,
        UserFeedbackItem,
    },
    computed: {
        feedback() {
            return this.$store.state.feedback
        },
        averageRating() {
            return this.feedback.averageRating ? Math.round(this.feedback.averageRating) : 0
        },
        allFeedbackCount() {
            return this.feedback.allFeedbackCount || 0
        },
        textFeedbackCount() {
            return this.feedback.textFeedbackCount || 0
        },
    },
}
</script>

<style module src="./ActivityFeed.css" />
