<template>
    <div :class="$style.filterDropdown">
        <div :class="$style.dropdownHeaderRow">
            <div :class="$style.dropdownHeaderTitle">
                <FontAwesomeIcon :icon="faFilter" :class="$style.faTools" />
                <p>Filter by</p>
            </div>
            <FontAwesomeIcon
                @click="toggleFilterMenu"
                :icon="faTimesCircle"
                :class="$style.closeIcon"
            />
        </div>

        <div :class="$style.dropdownBody">
            <FilterSection
                v-for="filter in filtersToUse"
                :key="filter.id"
                :template="filter"
                :state="state[filter.id]"
                :emptyStateText="filter.emptyStateText"
                @filter-changed="updateFilterState"
            ></FilterSection>
            <div v-if="filtersToUse.length == 0" :class="$style.emptyState">
                <p>There are no categories available to filter by.</p>
                <p v-if="emptyStateHint">
                    {{ emptyStateHint }}
                </p>
            </div>
        </div>
        <div :class="$style.filterFooter">
            <!-- eslint-disable vue-a11y/click-events-have-key-events,vuejs-accessibility/click-events-have-key-events-->
            <!-- eslint-disable vuejs-accessibility/interactive-supports-focus -->
            <p
                v-if="showClearFilters"
                role="button"
                @click="removeAllActiveFilters"
                :class="[$style.textOnlyCTA]"
            >
                Clear filters
            </p>
        </div>
    </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { pickBy } from 'ramda'
import mapIcons from '@/utils/mapIcons'
import { faFilter, faTimesCircle } from '@fortawesome/pro-light-svg-icons'
import FilterSection from './FilterSection/FilterSection'

export default {
    name: 'FilterDropdown',
    components: {
        FilterSection,
        FontAwesomeIcon,
    },
    props: {
        filters: Array,
        state: Object,
        emptyStateHint: String,
    },
    computed: {
        ...mapIcons({ faFilter, faTimesCircle }),
        filtersToUse() {
            if (
                this.filters &&
                this.filters.some(
                    (filter) => (filter.items && filter.items.length > 0) || filter.emptyStateText,
                )
            ) {
                return this.filters
            }
            return []
        },
        hasFilters() {
            return this.filters && Object.keys(this.filters).length > 0
        },
        showClearFilters() {
            return Object.keys(this.state).length !== 0
        },
    },
    methods: {
        updateFilterState(filterState) {
            const newState =
                filterState.active.length === 0
                    ? pickBy((_, key) => key !== filterState.id, this.state)
                    : { ...this.state, [filterState.id]: filterState.active }

            this.$emit('filters-changed', newState)
        },
        toggleFilterMenu() {
            this.$emit('filters-menu-closed')
        },
        removeAllActiveFilters() {
            this.$emit('all-filters-removed')
        },
    },
}
</script>

<style module src="./FilterDropdown.css" />
