import Vue from 'vue'
import Vuex from 'vuex'
import mutations from './mutations'
import actions from './actions'

Vue.use(Vuex)

const state = {
    user: {},
    orgUsers: [],
    org: {},
    orgJobs: [],
    pending: {
        fetchOrgStats: true,
    },
    error: {},
    feedback: {},
    templates: [],
    features: [],
    jobsAwaitingHire: [],
    jobCounts: {
        allActive: null,
        draft: null,
        open: null,
        closed: null,
        archived: null,
    },
    orgSignupData: {},
    stats: {
        overall: null,
        hired: null,
        rejected: null,
        sourcing: null,
        postReview: null,
        postFeedback: null,
        postApply: null,
        demographicsByStep: null,
    },
    googleAuthURL: '',
    sampleRole: {},
    incompleteReviews: [],
    modalVisibility: {},
    emailTemplates: [],
}

export default new Vuex.Store({
    state: Object.assign(state),
    actions: Object.assign(actions),
    mutations: Object.assign(mutations),
})
