<template>
    <div>
        <Modal :class="$style.requestAccessModal" :blurBackground="true">
            <div slot="message">
                <h1 :class="$style.modalContentHeader">
                    <span role="img" aria-label="money icon">💸</span> This feature is a paid add-on
                </h1>
                <p>
                    If you wish to proceed, click below to notify your Customer Success Manager and
                    they will be in touch to discuss next steps.
                </p>
                <Alert
                    color="danger"
                    v-if="sendRequestEmailError"
                    message="There was an error sending your request; please contact us if this problem persists"
                />
            </div>
            <div slot="content" :class="$style.buttonRow">
                <Button color="secondary" @clicked="goBack">
                    <div slot="buttonText">Back</div>
                </Button>
                <Button
                    color="primary"
                    :class="$style.requestAccessButton"
                    @clicked="requestAccess"
                    :disabled="requestEmailSent"
                    :pending="pendingSendRequestEmail"
                >
                    <div slot="buttonText" v-if="!requestEmailSent">Request a call-back</div>
                    <div slot="buttonText" v-else>Call-back requested</div>
                </Button>
            </div>
        </Modal>
    </div>
</template>

<script>
import { Alert, Button, Modal } from '@applied/marmot'

export default {
    name: 'RequestAccessModal',
    components: {
        Alert,
        Button,
        Modal,
    },
    data() {
        return { requestEmailSent: false }
    },
    computed: {
        pendingSendRequestEmail() {
            return (
                this.$store.state.pending &&
                this.$store.state.pending.sendGlobalReportingAccessRequestEmail
            )
        },
        sendRequestEmailError() {
            return (
                this.$store.state.error &&
                this.$store.state.error.sendGlobalReportingAccessRequestEmail
            )
        },
    },
    methods: {
        goBack() {
            this.$emit('close')
        },
        requestAccess() {
            return this.$store.dispatch('sendGlobalReportingAccessRequestEmail').then(() => {
                this.requestEmailSent = true
            })
        },
    },
}
</script>

<style module src="./RequestAccessModal.css" />
