<template>
    <div :class="$style.container">
        <div :class="$style.wrapper">
            <canvas ref="canvas" :id="chartId" width="500px"></canvas>
        </div>
    </div>
</template>

<script>
import Chart from 'chart.js/auto'
import { Tooltip } from 'chart.js'
import { createTooltip, generateRightPositioner, updateTooltip } from '../../tooltipConfig'

const tooltipHeight = 57

export default {
    name: 'RatingsChart',
    props: {
        chartId: String,
        chartData: Array,
        labels: {
            type: Array,
            default: () => [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        },
    },
    data() {
        return {
            chartColors: ['#2267C3', '#5593E8', '#02E6A5'],
            chart: null,
        }
    },
    mounted() {
        Tooltip.positioners.ratingsRight = generateRightPositioner(tooltipHeight)
        createTooltip('ratingsChartTooltip')
        this.createBarChart(this.chartId, this.chartData)
        this.chart.resize()
    },
    methods: {
        colorChartData(chartData) {
            return chartData.map((dataSet, index) => ({
                ...dataSet,
                backgroundColor: this.chartColors[index],
            }))
        },
        createBarChart(chartId, chartData) {
            const data = {
                labels: this.labels,
                datasets: this.colorChartData(chartData),
            }

            this.createChart(chartId, data)
        },
        createChart(chartId, chartData) {
            const ctx = this.$refs.canvas.getContext('2d')

            if (this.chart) {
                this.chart.destroy()
            }

            this.chart = new Chart(ctx, {
                type: 'bar',
                data: chartData,
                options: {
                    plugins: {
                        tooltip: {
                            enabled: false,
                            position: 'ratingsRight',
                            external: (context) => updateTooltip(context, 'ratingsChartTooltip'),
                            bodyFont: {
                                size: 15,
                                family: "'Poppins', sans-serif",
                            },
                        },
                        legend: {
                            display: true,
                            labels: {
                                font: {
                                    size: 15,
                                },
                            },
                        },
                    },
                    title: {
                        display: false,
                    },
                    scales: {
                        y: {
                            grid: {
                                drawOnChartArea: false,
                            },
                            ticks: {
                                beginAtZero: true,
                                stepSize: 10,
                                font: { size: 16 },
                            },
                            title: {
                                display: true,
                                text: 'people',
                                font: {
                                    size: 15,
                                },
                            },
                        },
                        x: {
                            grid: {
                                drawOnChartArea: false,
                            },
                            ticks: {
                                beginAtZero: true,
                                font: { size: 16 },
                            },
                            title: {
                                display: true,
                                text: 'star rating given',
                                font: {
                                    size: 16,
                                },
                            },
                        },
                    },
                    maintainAspectRatio: false,
                    responsive: false,
                },
            })

            return this.chart
        },
    },
}
</script>

<style module src="./BarChart.css" />
