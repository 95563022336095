/* eslint-disable no-use-before-define */
const dateFns = require('date-fns')

const getCookie = (cookieName) => {
    const name = `${cookieName}=`
    const ca = document.cookie.split(';')
    for (let i = 0; i < ca.length; i += 1) {
        let c = ca[i]
        while (c.charAt(0) === ' ') {
            c = c.substring(1)
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length)
        }
    }
    return ''
}

const checkShowNPS = (currentDate, userCreatedDate) => {
    const today = new Date()
    const isUserThreeMonthsOld = dateFns.isAfter(today, dateFns.addMonths(userCreatedDate, 3))

    let result = false

    let counter = parseInt(exported.getCookie('npsCount'), 10)
    if (counter < 5 && new Date(exported.getCookie('npsTime')) <= currentDate) {
        document.cookie = `npsCount=${(counter += 1)}`
        result = true
    }
    document.cookie = `npsCount=${0}`
    if (document.cookie.indexOf('npsTime=') < 0) {
        document.cookie = `npsTime=${new Date()}`
    }
    return result && isUserThreeMonthsOld
}

const exported = {
    checkShowNPS,
    getCookie,
}

export default exported
