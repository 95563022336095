<template>
    <div :class="$style.filterTextSummaryContainer">
        <span v-if="showTextSummary">
            Only including roles
            <!-- eslint-disable vue/require-v-for-key -->
            <span v-for="filter in filterTextSummary">
                {{ filter.prefix }} <b>{{ filter.selectedFilters }}</b>
                {{ filter.title }}
            </span>
        </span>
    </div>
</template>

<script>
import { pipe } from 'ramda'

export default {
    name: 'FilterTextSummary',
    props: {
        filterGroups: { type: Object, default: () => {} },
    },
    computed: {
        delimiters() {
            return {
                default: '',
                comma: ',',
                and: 'and ',
            }
        },
        showTextSummary() {
            return Object.keys(this.filterGroups).length !== 0
        },
        filterTextSummary() {
            const filterSummaries = []
            Object.entries(this.filterGroups).forEach((filterGroup) =>
                filterSummaries.push(this.getFilterDescription(filterGroup)),
            )
            return filterSummaries
        },
    },
    methods: {
        pluralise(title) {
            return `${title}s`
        },
        getFilterTitle(filterName, pluralise) {
            let displayName = filterName

            if (filterName === 'grade') {
                displayName = 'level'
            }

            if (pluralise) {
                displayName = this.pluralise(displayName)
            }

            return displayName
        },
        getFilterDescription(filterGroup) {
            return pipe(
                this.parseFilterValues,
                this.setFilterTitle,
                this.setFilterDelimiters,
                this.setFilterSummaryString,
                this.setPrefix,
            )(filterGroup)
        },

        parseFilterValues([filterCategory, selectedFilters]) {
            return [filterCategory, selectedFilters.map((x) => x.name)]
        },

        setFilterTitle([filterCategory, selectedFilters]) {
            return {
                title: this.getFilterTitle(filterCategory, selectedFilters.length > 1),
                values: selectedFilters,
                filterCategory,
            }
        },
        getFilterDelimiter(filterPosition, filterCount) {
            if (filterPosition === filterCount - 1 && filterCount > 1) {
                return this.delimiters.and
            }

            if (filterPosition < filterCount - 2) {
                return this.delimiters.comma
            }

            return this.delimiters.default
        },
        setFilterDelimiters({ values, title, filterCategory }) {
            const separatedValues = values.map((filterValue, index, iterable) => {
                const separator = this.getFilterDelimiter(index, iterable.length)

                const returnValue =
                    separator === this.delimiters.and
                        ? separator + filterValue
                        : filterValue + separator

                return returnValue
            })

            return {
                title,
                values: separatedValues,
                filterCategory,
            }
        },

        setFilterSummaryString({ values, title, filterCategory }) {
            return {
                selectedFilters: values.join(' '),
                title,
                filterCategory,
            }
        },

        setPrefix(filterObject) {
            const prefix = filterObject.filterCategory === 'grade' ? ' at the' : ' in the '

            return { ...filterObject, prefix }
        },
    },
}
</script>

<style module src="./FilterTextSummary.css" />
