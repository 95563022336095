<template>
    <PageLayout>
        <div :class="$style.content">
            <h1 :class="$style.smallMarginBottom">Global Reporting</h1>
            <p :class="[$style.standardMarginBottom, $style.body]">
                Understand the health of your hiring process and gain insights to help you target
                areas for improvement.
            </p>
            <div :class="$style.filterContainer">
                <TimePeriodSelectionBar
                    @datesChanged="onDatesChanged"
                    :disabled="!!isLoading || !canAccessGlobalReporting"
                />
                <Button
                    :class="$style.filterButton"
                    @clicked="toggleFilterMenu"
                    :disabled="isLoading || areFiltersLoading || !canAccessGlobalReporting"
                >
                    <span slot="buttonText"> Filters </span>
                    <span slot="icon">
                        <FontAwesomeIcon :icon="filterButtonChevron" />
                    </span>
                </Button>
                <!-- eslint-disable vue-a11y/click-events-have-key-events -->
                <!-- eslint-disable-next-line -->
                <p
                    v-if="showClearFilters"
                    role="button"
                    @click="removeAllActiveFilters"
                    :class="[$style.textOnlyCTA]"
                >
                    Clear filters
                </p>
                <FilterDropdown
                    v-if="isFilterMenuVisible"
                    :filters="filters"
                    :state="activeFilters"
                    @filters-changed="filtersChanged"
                    @filters-menu-closed="toggleFilterMenu"
                    @all-filters-removed="removeAllActiveFilters"
                    style="top: 100%; left: 28%"
                    ref="filterDropdown"
                />
            </div>

            <FilterTextSummary :filterGroups="activeFilters" />

            <KeyNumbersSection :isLoading="isLoading" :keyNumbers="keyNumbers" />

            <NoAccessModal
                v-if="!isLoading && !canAccessGlobalReporting && !showRequestAccessModal"
                @close="showRequestAccessModal = true"
            />
            <RequestAccessModal
                v-if="showRequestAccessModal"
                @close="showRequestAccessModal = false"
            />

            <SkeletonLoader v-if="isLoading || !canAccessGlobalReporting" />
            <div v-else>
                <PipelineDiversitySection v-if="!isLoading" />
                <ExperienceSection
                    v-if="!isLoading"
                    :postApplyFeedback="postApply"
                    :postFeedbackFeedback="postFeedback"
                    :postReviewFeedback="postReview"
                    :class="[$style.card, $style.leftAlign, $style.standardMarginTop]"
                />
                <div
                    v-if="!isLoading"
                    :class="[$style.card, $style.leftAlign, $style.standardMarginTop]"
                >
                    <div :class="[$style.smallMarginBottom, $style.sourcingHeaderContainer]">
                        <h4 :class="$style.cardTitle">Sourcing insights</h4>
                        <Tooltip :class="$style.sourcingTooltip" placement="right">
                            <template slot="default">
                                <FontAwesomeIcon :icon="faQuestionCircle" />
                            </template>
                            <template slot="inner">
                                <p>
                                    Check the quality and diversity of candidates, job board by job
                                    board – this may help you focus your efforts when sourcing!
                                </p></template
                            >
                        </Tooltip>
                        <div v-if="showSourcingStats" :class="$style.downloadSourcingCSVContainer">
                            <Button
                                color="primaryText"
                                :class="$style.buttonTopPaddingOverride"
                                @clicked="downloadSourceData"
                            >
                                <span slot="iconLeft" :class="$style.smallerIcon">
                                    <FontAwesomeIcon :icon="faArrowToBottom" />
                                </span>
                                <span slot="buttonText">Download CSV</span>
                            </Button>
                        </div>
                    </div>
                    <div v-if="showSourcingStats" :class="$style.cardContentFullWidth">
                        <HeatmapSection ref="sourcingSection" :diversityData="sourcingData" />
                    </div>
                    <EmptyStateCard v-else />
                </div>
                <div
                    :class="[$style.card, $style.standardMarginTop, $style.relative]"
                    v-if="!isLoading"
                >
                    <h4 :class="[$style.cardTitle, $style.smallMarginBottom]">
                        Jobs open during this period
                    </h4>
                    <p :class="[$style.alignLeft, $style.smallMarginBottom]">
                        This is a summary of the jobs that were open during this period and received
                        applications – including those that were opened before the initial date.
                    </p>
                    <JobsTable :jobs="jobs"></JobsTable>
                </div>

                <div
                    v-if="!isLoading && isAdmin"
                    :class="[$style.card, $style.leftAlign, $style.standardMarginTop]"
                >
                    <DownloadsSection :dateRange="formattedDateRange" :filters="activeFilters" />
                </div>
            </div>
        </div>
    </PageLayout>
</template>

<script>
import { find, propEq, findIndex, pipe, values, sum, map, prop, filter, flatten, uniq } from 'ramda'
import { isEqual, parseISO } from 'date-fns'
import {
    faChevronDown,
    faChevronUp,
    faQuestionCircle,
    faArrowToBottom,
} from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import mapIcons from '@/utils/mapIcons'
import debounce from 'debounce'
import sharedConfig from 'sharedConfig'
import { Button, Tooltip } from '@applied/marmot'
import PageLayout from '@/components/PageLayout/PageLayout'
import FilterDropdown from '@/components/FilterDropdown/FilterDropdown'
import DownloadsSection from './DownloadsSection/DownloadsSection'
import EmptyStateCard from './EmptyStateCard/EmptyStateCard'
import HeatmapSection from './HeatmapSection/HeatmapSection'
import JobsTable from './JobsTable/JobsTable'
import TimePeriodSelectionBar from './TimePeriodSelectionBar/TimePeriodSelectionBar'
import SkeletonLoader from './SkeletonLoader/SkeletonLoader'
import KeyNumbersSection from './KeyNumbersSection/KeyNumbersSection'
import NoAccessModal from './NoAccessModal/NoAccessModal'
import RequestAccessModal from './RequestAccessModal/RequestAccessModal'
import FilterTextSummary from './FilterTextSummary/FilterTextSummary'
import ExperienceSection from './ExperienceSection/ExperienceSection'
import PipelineDiversitySection from './PipelineDiversitySection/PipelineDiversitySection'
import { getAvailableActiveFilters } from './utils'

const localeEqualOpsConfig = sharedConfig.default.localeEqualOps

export default {
    name: 'OrgStats',
    components: {
        DownloadsSection,
        NoAccessModal,
        RequestAccessModal,
        KeyNumbersSection,
        Button,
        FilterDropdown,
        EmptyStateCard,
        HeatmapSection,
        JobsTable,
        PageLayout,
        TimePeriodSelectionBar,
        SkeletonLoader,
        FontAwesomeIcon,
        FilterTextSummary,
        Tooltip,
        ExperienceSection,
        PipelineDiversitySection,
    },
    data() {
        return {
            grade: 'all',
            selectedDropoffCategory: 'Gender',
            startDate: null,
            endDate: null,
            isFilterMenuVisible: false,
            showRequestAccessModal: false,
            activeFilters: {},
            excludeQuickApplyInDropoff: false,
            sourcingCategoriesMapping: {
                gender: 'gender',
                'age range': 'ageRange',
                ethnicity: 'ethnicity',
                sexuality: 'sexuality',
                disability: 'disability',
                'free school meals': 'familyWealth',
                'parents university': 'familyEducation',
                religion: 'religion',
                caste: 'caste',
                'veteran status': 'veteranStatus',
            },
        }
    },
    mounted() {
        this.reloadFilters()
    },
    computed: {
        ...mapIcons({ faChevronDown, faChevronUp, faQuestionCircle, faArrowToBottom }),
        org() {
            return this.$store.state.org
        },
        sourcingCategories() {
            const locale = this.org.locale || 'en-GB'
            const categories = [
                ...Object.keys(
                    filter((demographic) => !!demographic, localeEqualOpsConfig[locale]),
                ),
            ]

            const filteredCategories = filter(
                (val) => categories.includes(val),
                this.sourcingCategoriesMapping,
            )
            return {
                ...filteredCategories,
                performance: 'performance',
            }
        },
        isLoading() {
            return (
                this.$store.state.pending &&
                (this.$store.state.pending.fetchOrgStats ||
                    this.$store.state.pending.fetchUser ||
                    this.$store.state.pending.fetchOrg)
            )
        },
        areFiltersLoading() {
            return this.$store.state.pending && this.$store.state.pending.fetchJobFilters
        },
        filterCategoryEmptyStateText() {
            return {
                team: "You haven't specified Teams for any role in the selected period.",
                grade: "You haven't specified Internal grades for any role in the selected period.",
            }
        },
        filters() {
            return (
                (this.$store.state.jobFilters &&
                    this.$store.state.jobFilters.filter(
                        (jobFilter) => jobFilter.id === 'team' || jobFilter.id === 'grade',
                    )) ||
                []
            ).map((f) => ({
                ...f,
                emptyStateText: this.filterCategoryEmptyStateText[f.id],
            }))
        },
        overallStats() {
            return this.$store.state.stats.overall
        },
        canAccessGlobalReporting() {
            return (
                this.org.hasGlobalReporting ||
                (this.$store.state.user && this.$store.state.user.inImpersonatedAccount)
            )
        },
        filterButtonChevron() {
            return this.isFilterMenuVisible ? faChevronUp : faChevronDown
        },
        jobs() {
            return this.overallStats && this.overallStats.jobs
        },
        hiredStats() {
            return this.$store.state.stats.hired
        },
        rejectedStats() {
            return this.$store.state.stats.rejected
        },
        isAdmin() {
            return this.$store.state.user && this.$store.state.user.role === 'ADMIN'
        },
        dummyNumbers() {
            return {
                counts: {
                    candidates: 497,
                    jobs: 12,
                    hired: 9,
                    rejected: 480,
                },
                timeToHire: 20.5,
            }
        },
        keyNumbers() {
            if (!this.canAccessGlobalReporting && !this.isLoading) {
                return this.dummyNumbers
            }

            const timeToHireHours = (this.hiredStats && this.hiredStats.timeToHire) || 0
            const timeToHireDays = timeToHireHours / 24

            return {
                counts: {
                    candidates: this.overallStats && this.overallStats.candidates,
                    jobs: this.overallStats && this.overallStats.jobs.length,
                    hired: this.hiredStats && this.hiredStats.hiredCount,
                    rejected: this.rejectedStats && this.rejectedStats.rejectedCount,
                },
                timeToHire: timeToHireDays, // Unlike overallStats.tth, this includes applicants who submitted outside the time period
            }
        },
        postReview() {
            return this.$store.state.stats.postReview
        },
        postReviewStats() {
            return this.postReview && this.postReview.stats
        },
        postReviewFeedback() {
            return this.postReview && this.postReview.data
        },
        postApply() {
            return this.$store.state.stats.postApply
        },
        postApplyStats() {
            return this.postApply && this.postApply.stats
        },
        postApplyFeedback() {
            return this.postApply && this.postApply.data
        },
        postFeedback() {
            return this.$store.state.stats.postFeedback
        },
        postFeedbackStats() {
            return this.postFeedback && this.postFeedback.stats
        },
        postFeedbackFeedback() {
            return this.postFeedback && this.postFeedback.data
        },
        hasFeedback() {
            return (
                (this.postReviewStats && this.postReviewStats.totalFeedbackCount > 0) ||
                (this.postApplyStats && this.postApplyStats.totalFeedbackCount > 0) ||
                (this.postFeedbackStats && this.postFeedbackStats.totalFeedbackCount > 0)
            )
        },
        postReviewScores() {
            return this.postReviewStats && this.postReviewStats.scores
        },
        postReviewFeedbackData() {
            return [
                {
                    label: 'overall',
                    data: this.postReviewScores && this.postReviewScores.rating,
                },
            ]
        },
        postReviewLatestFeedback() {
            return (
                this.postReviewFeedback &&
                this.postReviewFeedback.map((entry) => ({
                    name: `${entry.Application ? entry.Application.User.firstName : ''} ${
                        entry.Application ? entry.Application.User.lastName : ''
                    }`,
                    time: parseISO(entry.updatedAt),
                    text: entry.text,
                    ratings: [{ type: 'overall', score: entry.rating }],
                }))
            )
        },
        postApplyScores() {
            return this.postApplyStats && this.postApplyStats.scores
        },
        postApplyFeedbackData() {
            return [
                {
                    label: 'overall',
                    data: this.postApplyScores && this.postApplyScores.rating,
                },
                {
                    label: 'fair',
                    data: this.postApplyScores && this.postApplyScores.fairness,
                },
                {
                    label: 'enjoyable',
                    data: this.postApplyScores && this.postApplyScores.satisfaction,
                },
            ]
        },
        postApplyLatestFeedback() {
            return (
                this.postApplyFeedback &&
                this.postApplyFeedback.map((entry) => ({
                    name: `${entry.Application.User.firstName} ${entry.Application.User.lastName}`,
                    time: parseISO(entry.updatedAt),
                    text: entry.text,
                    ratings: [
                        { type: 'fair', score: entry.fairness },
                        { type: 'enjoyable', score: entry.satisfaction },
                        { type: 'overall', score: entry.rating },
                    ],
                }))
            )
        },
        postFeedbackScores() {
            return this.postFeedbackStats && this.postFeedbackStats.scores
        },
        postFeedbackFeedbackData() {
            return [
                {
                    label: 'overall',
                    data: this.postFeedbackScores && this.postFeedbackScores.rating,
                },
            ]
        },
        postFeedbackLatestFeedback() {
            return (
                this.postFeedbackFeedback &&
                this.postFeedbackFeedback.map((entry) => ({
                    name: `${entry.Application.User.firstName} ${entry.Application.User.lastName}`,
                    time: parseISO(entry.updatedAt),
                    text: entry.text,
                    ratings: [{ type: 'overall', score: entry.rating }],
                }))
            )
        },
        referrers() {
            return (this.$store.state.stats.sourcing || {}).referrers || []
        },
        sourcingData() {
            const finalData = {}

            Object.entries(this.sourcingCategories).forEach(([sourceKey, categoryName]) => {
                const categoryData = []
                if (sourceKey === 'performance') {
                    this.referrers.forEach((referrer) => {
                        categoryData.push({
                            name: referrer.name,
                            total: referrer.count,
                            'avg review score': referrer.review,
                            'avg interview score': referrer.interview
                                ? parseFloat(referrer.interview / 100)
                                : null,
                            'avg test score': referrer.filter,
                        })
                    })
                } else {
                    this.referrers.forEach((referrer) => {
                        const referrerCategoryData = referrer[sourceKey] || {}
                        categoryData.push({
                            name: referrer.name,
                            total: referrer.count,
                            ...referrerCategoryData,
                        })
                    })
                }
                finalData[categoryName] = categoryData
            })

            return finalData
        },
        demographicsByStep() {
            const datasetName = this.excludeQuickApplyInDropoff
                ? 'demographicsByStepExcludingQuickApply'
                : 'demographicsByStep'

            return this.$store.state.stats && this.$store.state.stats[datasetName]
                ? this.cleanDemographicData(this.$store.state.stats[datasetName])
                : []
        },
        gradeNames() {
            // Not all stages will have the gradeNames so we have to check all of them
            const names = pipe(
                map(prop('gradeNames')),
                filter(Boolean),
                flatten,
                uniq,
            )(this.demographicsByStep)
            return names.length > 0 ? ['all', ...names] : []
        },
        hasDropoffData() {
            return this.demographicsByStep.find((step) => step.runningTotal > 0)
        },
        formattedDateRange() {
            if (!this.startDate || !this.endDate) {
                return {
                    from: '0001-01-01',
                    until: new Date(),
                }
            }
            return {
                from: this.startDate,
                until: this.endDate,
            }
        },
        showClearFilters() {
            return (
                !this.isLoading &&
                !this.isFilterMenuVisible &&
                Object.keys(this.activeFilters).length !== 0
            )
        },
        enableQuickApplyToggle() {
            return (
                this.overallStats &&
                this.overallStats.quickApplyCandidates &&
                this.overallStats.quickApplyCandidates >= 3
            )
        },
        showSourcingStats() {
            return this.referrers.length > 0
        },
    },
    methods: {
        cleanDemographicData(demographicData) {
            const startedStep = find(propEq('name', 'started'))(demographicData)
            const indexOfCompletion = findIndex(propEq('name', 'completion'))(demographicData)
            let completionStep = find(propEq('name', 'completion'))(demographicData)
            completionStep = completionStep
                ? { ...completionStep, name: 'submit' }
                : { name: 'submit' }

            const dataWithoutStarted = demographicData.filter((value) => value !== startedStep)
            if (indexOfCompletion !== -1) {
                dataWithoutStarted[indexOfCompletion] = completionStep
            }
            return dataWithoutStarted
        },
        stackedDemographicData(demographicName) {
            const sortedSteps = [...this.demographicsByStep].sort((a, b) => a.step - b.step)

            const characteristics = Array.from(
                new Set(
                    flatten(
                        sortedSteps.map((step) => {
                            const stepData = step.grades[this.grade]
                            return Object.keys((stepData && stepData[demographicName]) || {})
                        }),
                    ),
                ),
            )

            let decline = null
            const orderedCharacteristics = characteristics
                .filter((characteristic) => {
                    if (characteristic.toLowerCase().trim() !== 'decline') {
                        return true
                    }
                    // This saves the exact string of 'decline', in case the
                    // casing is different.
                    decline = characteristic
                    return false
                })
                .sort()

            if (decline) {
                orderedCharacteristics.push(decline)
            }

            const datasets = {}
            orderedCharacteristics.forEach((characteristic) => {
                datasets[characteristic] = {
                    label: characteristic,
                    data: [],
                }
            })

            sortedSteps.forEach((step) => {
                const grade = step.grades[this.grade]
                const demographicData = (grade && grade[demographicName]) || {}

                const characteristicTotal = pipe(values, sum)(demographicData)

                characteristics.forEach((characteristic) => {
                    datasets[characteristic].data.push(
                        ((demographicData[characteristic] || 0) / characteristicTotal || 0) * 100,
                    )
                })
            })

            const hiringSteps = sortedSteps.map((step) => step.name)

            const chartData = {
                labels: hiringSteps,
                datasets: orderedCharacteristics.map((characteristic) => datasets[characteristic]),
            }

            return chartData
        },
        onDatesChanged({ startDate, endDate }) {
            if (isEqual(startDate, this.startDate) && isEqual(endDate, this.endDate)) {
                return
            }

            this.startDate = startDate
            this.endDate = endDate
            this.reloadFilters()
        },
        reloadFilters: debounce(function reloadFilters() {
            this.$store
                .dispatch('fetchJobFilters', { from: this.startDate, until: this.endDate })
                .then(() => {
                    this.activeFilters = getAvailableActiveFilters(this.filters, this.activeFilters)
                    this.reloadData()
                })
        }, 500),
        reloadData: debounce(function reloadData() {
            this.$store.dispatch('fetchOrgStats', {
                from: this.startDate,
                until: this.endDate,
                filters: this.activeFilters,
            })
        }, 500),
        toggleQuickApplyData() {
            this.excludeQuickApplyInDropoff = !this.excludeQuickApplyInDropoff
        },
        toggleFilterMenu() {
            this.isFilterMenuVisible = !this.isFilterMenuVisible
        },
        filtersChanged(filters) {
            this.activeFilters = filters
            this.reloadData()
        },
        removeAllActiveFilters() {
            this.filtersChanged({})
        },
        downloadSourceData() {
            this.$refs.sourcingSection.exportTableData()
        },
    },
}
</script>

<style module src="./OrgStats.css" />
