module.exports = {
    'en-AU': {
        gender: true,
        ageRange: true,
        ethnicity: true,
        sexuality: true,
        disability: true,
        familyEducation: true,
        familyWealth: false,
        religion: false,
        caste: false,
        sex: false,
        genderIdentityComparedToGenderAssignedAtBirth: false,
        caringResponsibilities: false,
        married: false,
        communityBackground: false,
        veteranStatus: false,
    },
    'en-CA': {
        gender: true,
        ageRange: true,
        ethnicity: true,
        sexuality: true,
        disability: true,
        familyEducation: true,
        familyWealth: false,
        religion: false,
        caste: false,
        sex: false,
        genderIdentityComparedToGenderAssignedAtBirth: false,
        caringResponsibilities: false,
        married: false,
        communityBackground: false,
        veteranStatus: false,
    },
    'en-FR': {
        gender: true,
        ageRange: true,
        ethnicity: false,
        sexuality: false,
        disability: true,
        familyEducation: true,
        familyWealth: false,
        religion: false,
        caste: false,
        sex: false,
        genderIdentityComparedToGenderAssignedAtBirth: false,
        caringResponsibilities: false,
        married: false,
        communityBackground: false,
        veteranStatus: false,
    },
    'en-DE': {
        gender: true,
        ageRange: true,
        ethnicity: true,
        sexuality: true,
        disability: true,
        familyEducation: true,
        familyWealth: false,
        religion: false,
        caste: false,
        sex: false,
        genderIdentityComparedToGenderAssignedAtBirth: false,
        caringResponsibilities: false,
        married: false,
        communityBackground: false,
        veteranStatus: false,
    },
    'en-GB': {
        gender: true,
        ageRange: true,
        ethnicity: true,
        sexuality: true,
        disability: true,
        familyEducation: true,
        familyWealth: true,
        religion: false,
        caste: false,
        sex: false,
        genderIdentityComparedToGenderAssignedAtBirth: false,
        caringResponsibilities: false,
        married: false,
        communityBackground: false,
        veteranStatus: false,
    },
    'en-NI': {
        gender: true,
        ageRange: true,
        ethnicity: true,
        sexuality: true,
        disability: true,
        familyEducation: true,
        familyWealth: true,
        religion: false,
        caste: false,
        sex: false,
        genderIdentityComparedToGenderAssignedAtBirth: false,
        caringResponsibilities: false,
        married: false,
        communityBackground: true,
        veteranStatus: false,
    },
    'en-HK': {
        gender: true,
        ageRange: true,
        ethnicity: true,
        sexuality: true,
        disability: true,
        familyEducation: true,
        familyWealth: false,
        religion: false,
        caste: false,
        sex: false,
        genderIdentityComparedToGenderAssignedAtBirth: false,
        caringResponsibilities: false,
        married: false,
        communityBackground: false,
        veteranStatus: false,
    },
    'en-IN': {
        gender: true,
        ageRange: true,
        ethnicity: false,
        sexuality: true,
        disability: true,
        familyEducation: false,
        familyWealth: false,
        religion: true,
        caste: true,
        sex: false,
        genderIdentityComparedToGenderAssignedAtBirth: false,
        caringResponsibilities: false,
        married: false,
        communityBackground: false,
        veteranStatus: false,
    },
    'en-NL': {
        gender: true,
        ageRange: true,
        ethnicity: true,
        sexuality: true,
        disability: true,
        familyEducation: true,
        familyWealth: false,
        religion: false,
        caste: false,
        sex: false,
        genderIdentityComparedToGenderAssignedAtBirth: false,
        caringResponsibilities: false,
        married: false,
        communityBackground: false,
        veteranStatus: false,
    },
    'en-NZ': {
        gender: true,
        ageRange: true,
        ethnicity: true,
        sexuality: true,
        disability: true,
        familyEducation: true,
        familyWealth: false,
        religion: false,
        caste: false,
        sex: false,
        genderIdentityComparedToGenderAssignedAtBirth: false,
        caringResponsibilities: false,
        married: false,
        communityBackground: false,
        veteranStatus: false,
    },
    'en-SG': {
        gender: true,
        ageRange: true,
        ethnicity: true,
        sexuality: false,
        disability: true,
        familyEducation: true,
        familyWealth: false,
        religion: false,
        caste: false,
        sex: false,
        genderIdentityComparedToGenderAssignedAtBirth: false,
        caringResponsibilities: false,
        married: false,
        communityBackground: false,
        veteranStatus: false,
    },
    'en-CH': {
        gender: true,
        ageRange: true,
        ethnicity: true,
        sexuality: true,
        disability: true,
        familyEducation: true,
        familyWealth: false,
        religion: false,
        caste: false,
        sex: false,
        genderIdentityComparedToGenderAssignedAtBirth: false,
        caringResponsibilities: false,
        married: false,
        communityBackground: false,
        veteranStatus: false,
    },
    'en-US': {
        gender: true,
        ageRange: true,
        ethnicity: true,
        sexuality: true,
        disability: false,
        familyEducation: true,
        familyWealth: false,
        religion: false,
        caste: false,
        sex: false,
        genderIdentityComparedToGenderAssignedAtBirth: false,
        caringResponsibilities: false,
        married: false,
        communityBackground: false,
        veteranStatus: true,
    },
    'en-EHRC': {
        gender: false,
        ageRange: true,
        ethnicity: true,
        sexuality: true,
        disability: true,
        familyEducation: true,
        familyWealth: true,
        religion: true,
        caste: false,
        sex: true,
        genderIdentityComparedToGenderAssignedAtBirth: true,
        caringResponsibilities: true,
        married: true,
        communityBackground: false,
        veteranStatus: false,
    },
    'other': {
        gender: true,
        ageRange: true,
        ethnicity: false,
        sexuality: false,
        disability: false,
        familyEducation: false,
        familyWealth: false,
        religion: false,
        caste: false,
        sex: false,
        genderIdentityComparedToGenderAssignedAtBirth: false,
        caringResponsibilities: false,
        married: false, 
        communityBackground: false,
        veteranStatus: false,
    },
}