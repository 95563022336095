var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.showUserPhoto)?_c('div',[_c('img',{class:{
                [_vm.$style.image]: true,
                [_vm.$style.smallImage]: _vm.size === 'small',
                [_vm.$style.largeImage]: _vm.size !== 'small',
            },attrs:{"alt":"User profile image","src":_vm.imageUrl},on:{"error":function($event){_vm.showUserPhoto = false}}})]):(_vm.userInitials)?_c('div',{class:{
            [_vm.$style.userImage]: true,
            [_vm.$style.small]: _vm.size === 'small',
            [_vm.$style.medium]: _vm.size === 'medium',
            [_vm.$style.large]: _vm.size !== 'small' && _vm.size !== 'medium',
        }},[_vm._v(" "+_vm._s(_vm.userInitials)+" ")]):_c('div',{class:{
            [_vm.$style.userImage]: true,
            [_vm.$style.fa]: true,
            [_vm.$style.small]: _vm.size === 'small',
            [_vm.$style.large]: _vm.size !== 'small',
        }},[_c('FontAwesomeIcon',{attrs:{"icon":_vm.userIcon}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }