<template>
    <div :class="$style.wrapper">
        <p :class="$style.feedbackText">"{{ censoredText }}"</p>
        <div :class="$style.extraDetailsSection">
            <div :class="$style.supportText">
                <p>
                    <strong>{{ feedback.Application.Job.title }}</strong>
                </p>
                <p>{{ readableDateDiff }} ago</p>
            </div>
            <div :class="$style.starWrapper" v-if="feedback.rating">
                <SimpleStarRating :total="10" :value="feedback.rating" />
            </div>
        </div>
    </div>
</template>

<script>
import { profanity } from '@2toad/profanity'
import { formatDistanceToNow, parseISO } from 'date-fns'
import SimpleStarRating from './SimpleStarRating/SimpleStarRating'

export default {
    name: 'UserFeedbackItem',
    props: {
        feedback: Object,
    },
    components: {
        SimpleStarRating,
    },
    computed: {
        readableDateDiff() {
            return formatDistanceToNow(parseISO(this.feedback.createdAt))
        },
        censoredText() {
            return profanity.censor(this.feedback.text)
        },
    },
}
</script>

<style module src="./UserFeedbackItem.css" />
