import { render, staticRenderFns } from "./CommentField.vue?vue&type=template&id=5d7121b9&scoped=true&"
import script from "./CommentField.vue?vue&type=script&lang=js&"
export * from "./CommentField.vue?vue&type=script&lang=js&"
import style0 from "./CommentField.vue?vue&type=style&index=0&id=5d7121b9&prod&scoped=true&lang=css&"
import style1 from "./CommentField.css?vue&type=style&index=1&prod&module=true&lang=css&"




function injectStyles (context) {
  
  this["$style"] = (style1.locals || style1)

}

/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "5d7121b9",
  null
  
)

export default component.exports