<template>
    <div :class="$style.pagination">
        <Button
            :disabled="prevDisabled"
            :class="$style.paginationButton"
            v-if="totalPages !== 1"
            @clicked="pagination({ prev: true })"
            color="primaryText"
        >
            <span slot="buttonText">
                <FontAwesomeIcon :icon="chevronLeft" />
            </span>
        </Button>
        <Button
            v-for="page in buttons"
            :key="page.key || page.label"
            :class="{
                [$style.paginationButton]: true,
                [$style.noEvents]: !page.clicked,
                [$style.isCurrentPage]: page.label === currentPage,
            }"
            @clicked="pagination({ page: page.clicked })"
            color="primaryText"
        >
            <span slot="buttonText">
                {{ page.label }}
            </span>
        </Button>
        <Button
            :disabled="nextDisabled"
            :class="$style.paginationButton"
            v-if="totalPages !== 1"
            @clicked="pagination({ next: true })"
            color="primaryText"
        >
            <span slot="buttonText">
                <FontAwesomeIcon :icon="chevronRight" />
            </span>
        </Button>
    </div>
</template>

<script>
import { Button } from '@applied/marmot'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-light-svg-icons'

export default {
    name: 'Pagination',
    props: {
        currentPage: {
            type: Number,
            default: 1,
        },
    },
    data() {
        return {
            buttons: [],
        }
    },
    watch: {
        currentPage(current) {
            this.buttons = this.buildButtons(current)
        },
        $route(to, from) {
            if (to.page !== from.page) {
                this.buttons = this.buildButtons(parseInt(to.page, 10))
            }
        },
        totalPages() {
            this.buttons = this.buildButtons(this.currentPage)
        },
    },
    mounted() {
        this.buttons = this.buildButtons()
    },
    components: {
        Button,
        FontAwesomeIcon,
    },
    methods: {
        pagination(options) {
            this.$emit('pagination', options)
        },
        buildButtons(current = null) {
            const currentPage = current || this.currentPage || 1

            const buttonsConfig = []

            // When totalPages > 5, the logic is to show 5 numbers in total plus the first and/or last page numbers IFF they are not
            // already within those 5 numbers. These 5 number will be the currentPage and the four numbers around the currentPage (if possible).
            // All the literal 5s and 4s come from this logic specification.

            const currentPageIsOneOfTheFirstFourPages = currentPage < 5
            const currentPageIsOneOfTheLastFourPages = this.totalPages - currentPage < 4

            const addFirstPage = () => {
                buttonsConfig.push({
                    label: 1,
                    clicked: 1,
                })
            }

            const addLastPage = () => {
                buttonsConfig.push({
                    label: this.totalPages,
                    clicked: this.totalPages,
                })
            }

            const addBackwardGapButton = () => {
                buttonsConfig.push({
                    label: '...',
                    key: 'backwardGapButton',
                    clicked: Math.max(1, currentPage - 5),
                })
            }

            const addForwardGapButton = () => {
                buttonsConfig.push({
                    label: '...',
                    key: 'forwardGapButton',
                    clicked: Math.min(this.totalPages, currentPage + 5),
                })
            }

            const addPagesXToY = (initialPage, finalPage) => {
                // eslint-disable-next-line no-plusplus
                for (let page = initialPage; page <= finalPage; page++) {
                    buttonsConfig.push({
                        label: page,
                        clicked: page,
                    })
                }
            }

            if (this.totalPages < 7) {
                addPagesXToY(1, this.totalPages)
                return buttonsConfig
            }

            if (currentPageIsOneOfTheFirstFourPages && currentPageIsOneOfTheLastFourPages) {
                addPagesXToY(1, this.totalPages)
            }
            if (currentPageIsOneOfTheFirstFourPages && !currentPageIsOneOfTheLastFourPages) {
                addPagesXToY(1, 5)
                addForwardGapButton()
                addLastPage()
            }
            if (!currentPageIsOneOfTheFirstFourPages && currentPageIsOneOfTheLastFourPages) {
                addFirstPage()
                addBackwardGapButton()
                addPagesXToY(this.totalPages - 4, this.totalPages)
            }
            if (!currentPageIsOneOfTheFirstFourPages && !currentPageIsOneOfTheLastFourPages) {
                addFirstPage()
                addBackwardGapButton()
                addPagesXToY(currentPage - 2, currentPage + 2)
                addForwardGapButton()
                addLastPage()
            }

            return buttonsConfig
        },
        buttonsListLeftHandSide(current) {
            if (current === 1 || current === 2) {
                return [1, 2]
            }

            if (current === this.totalPages || current === this.totalPages - 1) {
                return [this.totalPages - 4, this.totalPages - 3]
            }

            return [current - 2, current - 1]
        },
    },
    computed: {
        chevronRight() {
            return faChevronRight
        },
        chevronLeft() {
            return faChevronLeft
        },
        nextDisabled() {
            return this.currentPage === this.totalPages
        },
        prevDisabled() {
            return this.currentPage === 1
        },
        totalPages() {
            return this.$store.state.orgJobsPagination && this.$store.state.orgJobsPagination.pages
        },
    },
}
</script>

<style module src="./Pagination.css" />
