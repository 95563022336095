<template>
    <Modal @close="$emit('close')" :height="'auto'">
        <span slot="header" :class="$style.title">{{ modalTitle }}</span>
        <div slot="content" style="width: 100%">
            <p>
                Your organisation takes security seriously: you'll need to use an app to generate a
                one-time login code.
            </p>
            <div id="MFA-SetupStep" v-if="showSetup">
                <div :class="$style.content">
                    <ol>
                        <li>Download an authenticator app to your mobile</li>
                        <p :class="$style.mutedText">
                            You can download one from the App Store or Google Play. <br />
                            Try
                            <a href="https://authy.com/download/" target="_blank">Twillio Authy</a>,
                            <a
                                href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
                                target="_blank"
                                >Google</a
                            >
                            or
                            <a
                                href="https://www.microsoft.com/en-us/security/mobile-authenticator-app"
                                target="_blank"
                                >Microsoft Authenticator</a
                            >.
                        </p>
                        <li>Open the app and select 'add account'</li>
                        <li>Scan the QR code below</li>
                    </ol>
                    <div v-html="svg" :class="$style.svgWrap"></div>
                </div>
                <div :class="$style.buttonRow">
                    <a
                        href="https://appliedhelp.zendesk.com/hc/en-us/articles/6189558457105"
                        target="_blank"
                    >
                        Step-by-step tutorial
                        <FontAwesomeIcon :icon="faExternalLinkAlt" />
                    </a>
                    <div :class="$style.buttonRowButtons">
                        <Button color="secondary" @clicked="$emit('close')">
                            <span slot="buttonText">Cancel</span>
                        </Button>
                        <Button color="primary" @clicked="goToEnterCodeStep">
                            <span slot="buttonText">Next</span>
                        </Button>
                    </div>
                </div>
            </div>
            <div :class="$style.enterCodeStep" v-else>
                <div :class="$style.content">
                    <p :class="$style.prompt">
                        Enter the 6-digit code you see in the authentication app
                    </p>
                    <TextInput
                        v-model="totpCode"
                        ariaLabel="totpcode"
                        @entered="setupTotp"
                        :maxLength="6"
                    />
                    <p :class="$style.mutedText">
                        You are logging in as
                        <strong>{{ emailAddress }}</strong>
                    </p>
                </div>

                <Alert
                    v-if="totpError"
                    :class="$style.alert"
                    message="Invalid one time code - please try again."
                    color="danger"
                />
                <div :class="$style.buttonRow">
                    <a href="https://appliedhelp.zendesk.com/hc/en-us/requests/new" target="_blank">
                        Need help?
                        <FontAwesomeIcon :icon="faExternalLinkAlt" />
                    </a>
                    <div :class="$style.buttonRowButtons">
                        <Button color="secondary" @clicked="$emit('close')">
                            <span slot="buttonText">Cancel</span>
                        </Button>
                        <Button color="primary" @clicked="setupTotp" :disabled="!totpCode">
                            <span slot="buttonText">Done</span>
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    </Modal>
</template>

<script>
import { Alert, Button, Modal, TextInput } from '@applied/marmot'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'

export default {
    name: 'TOTPModal',
    components: {
        Modal,
        TextInput,
        Alert,
        Button,
        FontAwesomeIcon,
    },
    data() {
        return {
            totpCode: null,
            totpError: false,
            currentStep: 0,
        }
    },
    props: {
        svg: String,
        uri: String,
        emailAddress: String,
        password: String,
        userSecret: String,
    },
    computed: {
        faExternalLinkAlt() {
            return faExternalLinkAlt
        },
        setupHasBeenCompleted() {
            return !this.svg
        },
        showSetup() {
            return !this.setupHasBeenCompleted && this.currentStep === 0
        },
        modalTitle() {
            return '🔒 Log in securely'
        },
    },
    methods: {
        goToDashboard() {
            this.$router.push({ path: '/dashboard' }).catch((err) => {
                console.log(err)
            })
        },
        setupTotp() {
            return this.$store
                .dispatch('setupTOTP', {
                    totpToken: this.totpCode,
                    emailAddress: this.emailAddress,
                    password: this.password,
                    userSecret: this.userSecret,
                })
                .then(() => {
                    this.goToDashboard()
                })
                .catch(() => {
                    this.totpError = true
                })
        },
        goToEnterCodeStep() {
            this.currentStep = 1
        },
    },
}
</script>

<style module src="./TOTPModal.css" />
