/* eslint consistent-return: 0 */

import Vue from 'vue'
import Router from 'vue-router'
import Dashboard from '@/pages/Dashboard/Dashboard'
import Login from '@/pages/Login/Login'
import OrgStats from '@/pages/OrgStats/OrgStats'
import auth from '../utils/auth'
import store from '../store'

Vue.use(Router)

const getRedirectDestination = (to) => (to.query.path ? to.query.path : `/dashboard/#${to.path}`)

const notLoggedInRedirect = (to, from, next) => {
    auth.isUserLoggedIn()
        .then((state) => {
            if (state) {
                next()
            } else {
                next(`/login?path=${encodeURIComponent(getRedirectDestination(to))}`)
            }
        })
        .catch(() => {
            next(`/login?path=${encodeURIComponent(getRedirectDestination(to))}`)
        })
}

const routes = [
    {
        path: '/',
        component: Login,
        beforeEnter: (to, from, next) => {
            auth.isUserLoggedIn()
                .then((state) => {
                    if (state) {
                        next('/dashboard')
                    } else {
                        next(`/login?path=${encodeURIComponent(getRedirectDestination(to))}`)
                    }
                })
                .catch(() => {
                    next(`/login?path=${encodeURIComponent(getRedirectDestination(to))}`)
                })
        },
    },
    {
        path: '/text-analysis-signup',
        name: 'text-analysis-signup',
        component: () => import('@/pages/NewTATSignup/NewTATSignup'),
        meta: {
            title: 'Applied - Welcome to Applied',
        },
    },
    {
        path: '/org-signup/template-list',
        name: 'template-list',
        component: () => import('@/pages/TemplateList/TemplateList'),
        meta: {
            title: 'Applied - Welcome to Applied',
        },
    },
    {
        path: '/org-signup/view-template/:templateId',
        name: 'view-template',
        component: () => import('@/pages/TemplateView/TemplateView'),
        meta: {
            title: 'Applied - Welcome to Applied',
        },
    },
    {
        path: '/org-signup-new/:stepNumber',
        name: 'org-signup-new',
        component: () => import('@/pages/OrgSignup/OrgSignup'),
        meta: {
            title: 'Applied - Welcome to Applied',
        },
    },
    {
        path: '/welcome',
        name: 'welcome-flow',
        component: () => import('@/pages/OnboardingWizard/OnboardingWizard'),
        meta: {
            title: 'Applied - Welcome to Applied',
        },
    },
    {
        path: '/login',
        component: () => import('@/pages/Login/Login'),
        beforeEnter: (to, from, next) => {
            store.commit('storeRedirect', to.query.path)

            auth.isUserLoggedIn()
                .then((status) => {
                    if (status.orgDeactivated) return next('/expired')
                    if (status) return next('/dashboard')
                    return next()
                })
                .catch(() => {
                    next()
                })
        },
    },
    {
        path: '/:token/reset-password/',
        name: 'Password Reset',
        meta: {
            title: 'Applied - reset your password',
        },
        component: () => import('@/pages/PasswordReset/PasswordReset'),
    },
    {
        path: '/:token/create-password/',
        name: 'Password Creation',
        meta: {
            title: 'Applied - create your password',
        },
        component: () => import('@/pages/PasswordReset/PasswordReset'),
    },
    {
        path: '/settings',
        name: 'Settings',
        component: () => import('@/pages/Settings/Settings'),
        meta: {
            title: 'Settings',
        },
        children: [
            {
                path: 'account',
                name: 'account',
                component: () => import('@/pages/Account/Account'),
                meta: {
                    title: 'Account',
                },
            },
            {
                path: 'profile',
                name: 'profile',
                component: () => import('@/pages/UserProfile/UserProfile'),
                meta: {
                    title: 'Profile',
                },
            },
            {
                path: 'integrations',
                name: 'integrations',
                component: () => import('@/pages/APIManagement/APIManagement'),
                meta: {
                    title: 'Integrations',
                },
            },
            {
                path: 'users-and-permissions',
                name: 'users-and-permissions',
                component: () => import('@/pages/Account/UsersAndPermissions/UsersAndPermissions'),
            },
        ],
        beforeEnter: (to, from, next) => notLoggedInRedirect(to, from, next),
    },
    {
        path: '/email-templates',
        name: 'Email Templates',
        component: () => import('@/pages/EmailTemplateManagement/EmailTemplateManagement'),
        meta: {
            title: 'Applied - Organisation Email Templates',
        },
        beforeEnter: (to, from, next) => notLoggedInRedirect(to, from, next),
    },
    {
        path: '/api-management',
        name: 'API Management',
        component: () => import('@/pages/APIManagement/APIManagement'),
        meta: {
            title: 'Applied - Jobs API Management',
        },
        beforeEnter: (to, from, next) => notLoggedInRedirect(to, from, next),
    },
    {
        path: '/careers-page',
        name: 'Careers page',
        component: () => import('@/pages/CareersPageManagement/CareersPageManagement'),
        meta: {
            title: 'Applied - Manage your company careers page',
        },
        beforeEnter: (to, from, next) => notLoggedInRedirect(to, from, next),
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: Dashboard,
        meta: {
            title: 'Applied  Your Dashboard',
        },
        beforeEnter: (to, from, next) => notLoggedInRedirect(to, from, next),
    },
    {
        path: '/org-stats',
        name: 'org stats',
        component: OrgStats,
        meta: {
            title: 'Applied - Global Reporting',
        },
        beforeEnter: (to, from, next) => notLoggedInRedirect(to, from, next),
    },
    {
        path: '/user-dashboard',
        name: 'personal dashboard',
        component: () => import('@/pages/PersonalDashboard/PersonalDashboard'),
        meta: {
            title: 'Applied  Your Dashboard',
        },
        beforeEnter: (to, from, next) => notLoggedInRedirect(to, from, next),
    },
    {
        path: '/question-library',
        name: 'question library',
        component: () => import('@/pages/QuestionLibrary/QuestionLibrary'),
        meta: {
            title: 'Applied  Your Question Library',
        },
        beforeEnter: (to, from, next) => notLoggedInRedirect(to, from, next),
    },
    {
        path: '/search',
        name: 'Search results',
        component: () => import('@/pages/SearchResults/SearchResults'),
        meta: {
            title: 'Applied  Your Dashboard',
        },
        beforeEnter: (to, from, next) => notLoggedInRedirect(to, from, next),
    },
    {
        path: '*',
        component: Login,
        beforeEnter: (to, from, next) => notLoggedInRedirect(to, from, next),
        meta: {
            title: 'Sign in - Applied ',
        },
    },
]

const router = new Router({
    routes,
})

router.beforeEach((to, from, next) => {
    const nearestWithTitle = to.matched
        .slice()
        .reverse()
        .find((r) => r.meta && r.meta.title)

    const nearestWithMeta = to.matched
        .slice()
        .reverse()
        .find((r) => r.meta && r.meta.metaTags)

    if (nearestWithTitle) document.title = nearestWithTitle.meta.title

    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map((el) =>
        el.parentNode.removeChild(el),
    )

    if (!nearestWithMeta) {
        return next()
    }

    nearestWithMeta.meta.metaTags
        .map((tagDef) => {
            const tag = document.createElement('meta')

            Object.keys(tagDef).forEach((key) => {
                tag.setAttribute(key, tagDef[key])
            })

            tag.setAttribute('data-vue-router-controlled', '')

            return tag
        })
        .forEach((tag) => document.head.appendChild(tag))

    next()
})

export default router
